<template>
  <!-- Modal Détails dispo -->
  <div
    v-if="openModalRemisesVH"
    :class="`modal ${
      !openModalRemisesVH && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalRemisesVH = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 50rem"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="openModalRemisesVH = false"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Remises fournisseurs</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalRemisesVH = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <table class="min-w-full mt-4">
          <thead class="tableHEADTR">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté limite inférieure
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté limite supérieure
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Prix
              </th>
            </tr>
          </thead>

          <tbody class="bg-white w-fit">
            <tr v-for="(r, index) in PrixLot" :key="index" class="tableHEADTR">
              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex space-x-2 items-center">
                  <p>{{ r.$.QtyLowerLimit }} pcs</p>
                  <button
                    @click="changeQuantity(r.$.QtyLowerLimit)"
                    class="material-icons-round text-bluevk hover:text-sky-300"
                  >
                    shopping_cart_checkout
                  </button>
                </div>
              </td>

              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex space-x-2 items-center">
                  <p>{{ r.$.QtyUpperLimit }} pcs</p>
                  <button
                    @click="changeQuantity(r.$.QtyUpperLimit)"
                    class="material-icons-round text-bluevk hover:text-sky-300"
                  >
                    shopping_cart_checkout
                  </button>
                </div>
              </td>

              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="text-center">{{ financial(r.$.Price) }} €</div>
              </td>
            </tr>
          </tbody>
        </table>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="openModalRemisesVH = false"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div>
    <div
      v-if="open"
      :class="`modal ${
        !open && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="open = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 40rem; max-height: 45rem"
        class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Quantité à réceptionner</p>
            <div class="z-50 cursor-pointer modal-close" @click="open = false">
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex mt-2 space-x-2 sm:justify-center">
            <!-- PARTIE GAUCHE -->
            <div class="flex flex-col">
              <div class="flex flex-col items-center">
                <span class="text-blue-800">+</span>
                <label class="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    v-model="stateNumber"
                    class="sr-only peer"
                  />
                  <div
                    class="h-7 w-4 bg-blue-700 peer-focus:outline-none text-left dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-blue-800 peer-checked:after:translate-y-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-3 after:w-3 after:transition-all dark:border-blue-800 peer-checked:bg-orange-500"
                  ></div>
                </label>
                <span class="text-orange-500">=</span>
              </div>
              <div
                class="flex flex-col space-y-1 overflow-y-auto border border-gray-200 p-0.5 pr-1.5 rounded-md shadow-lg"
                style="max-height: 200px"
              >
                <div v-for="(num, index) in numbers" :key="index">
                  <button>
                    <p
                      class="w-8 border rounded-md shadow-lg font-medium text-white bg-gray-500 hover:bg-gray-800 border border-gray-800"
                      @click="setNumberStock(num)"
                    >
                      {{ num }}
                    </p>
                    <p v-if="index < numbers.length - 1" class="text-center">
                      --
                    </p>
                  </button>
                </div>
              </div>
            </div>
            <!-- PARTIE DROITE -->
            <div class="flex my-auto">
              <div class="flex flex-col space-y-2 align-center">
                <p class="font-medium"></p>
                <div
                  class="flex bg-green-500 w-full rounded shadow cursor-pointer"
                  @click="increaseValue"
                >
                  <span
                    class="material-symbols-rounded text-7xl text-white mx-auto"
                  >
                    keyboard_arrow_up
                  </span>
                </div>
                <div class="w-full relative rounded-md shadow-sm">
                  <input
                    type="text"
                    class="w-full text-center focus:outline-2 outline-sky-300 py-4 pl-2 pr-12 text-white text-5xl bg-bluevk shadow rounded"
                    v-model="
                      document.order_articles[indexScanned].quantity_received
                    "
                    @change="saveQuantity()"
                  />
                </div>
                <div
                  class="flex bg-red-500 w-full rounded shadow cursor-pointer"
                  @click="decreaseValue"
                >
                  <span
                    class="material-symbols-rounded text-7xl text-white mx-auto"
                  >
                    keyboard_arrow_down
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!--Footer-->
          <div class="flex space-x-2 justify-end pt-2">
            <button
              @click="open = false"
              class="p-3 px-6 py-3 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
            >
              Fermer
            </button>
            <button
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
              @click="addByScanFull()"
            >
              Full
            </button>
            <!-- <button
              @click="eqByScan()"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Remplacer
            </button> -->
            <!-- <button
              @click="addByScan(inputValue)"
              class="px-6 py-3 font-medium tracking-wide text-white bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none"
            >
              Valider
            </button> -->
          </div>
        </div>
      </div>
    </div>

    <div class="flex justify-between">
      <button
        @click="$router.back()"
        class="flex flex-row space-x-2 items-center bg-blue-400 text-white px-2 py-1 rounded-md"
      >
        <span class="material-icons-outlined text-sm"> arrow_back_ios </span>
        <span>retour</span>
      </button>

      <div class="xs:hidden lg:block w-full">
        <button
          v-if="!loadingExport"
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="exportExcel()"
        >
          <span class="material-icons-outlined text-xl mr-2"> download </span>
          <span>Exporter en Excel</span>
        </button>
        <button
          v-else
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          <PulseLoader color="#ffff" class="mr-2" />
          <span>Export en cours </span>
        </button>
      </div>
    </div>

    <!-- BOUTONS ACTION -->
    <div class="mt-4 flex justify-end">
      <div
        v-if="document.percentage_status !== 100"
        class="mr-auto my-auto flex space-x-4"
      >
        <button
          v-if="document.archived"
          @click="
            document.archived = false;
            saveData();
          "
          class="w-44 h-fit bg-green-600 text-white hover:bg-green-500 border border-green-700 shadow-md font-semibold py-2 px-3 rounded flex justify-center items-center"
        >
          <span class="material-icons-round mr-2">lock_open</span>
          Activer
        </button>

        <button
          v-else
          @click="
            document.archived = true;
            saveData();
          "
          class="w-44 h-fit bg-red-600 text-white hover:bg-red-500 border border-red-700 shadow-md font-semibold py-2 px-3 rounded flex justify-center items-center"
        >
          <span class="material-icons-round mr-2">lock</span>
          Archiver
        </button>

        <button
          v-if="[33, 66].includes(document.percentage_status)"
          @click="togglePause()"
          class="w-47 h-fit bg-yellow-500 text-white hover:bg-yellow-400 border border-yellow-600 shadow-md font-semibold py-2 px-3 rounded flex justify-center items-center"
        >
          <span class="material-icons-round mr-2">{{
            document.status === "PENDING" ? "play_arrow" : "pause"
          }}</span>
          {{
            document.status === "PENDING" ? "Reprendre" : "Mettre en attente"
          }}
        </button>
      </div>

      <div
        v-if="
          document.percentage_status == 10 &&
          tabDepot.length > 0 &&
          !document.archived
        "
        class="flex space-x-4"
      >
        <div class="flex space-x-2 my-auto">
          <div class="relative my-auto">
            <input
              type="text"
              id="ReferenceCmd"
              v-model="document.order_reference"
              @change="saveSupplierCart()"
              class="px-2.5 pb-1 pt-2 w-96 text-blue-900 rounded-lg border border-blue-300 focus:outline-none focus:ring-0 focus:border-orange-600 peer shadow-lg"
            />
            <label
              for="ReferenceCmd"
              class="absolute text-blue-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Référence de commande</label
            >
          </div>
          <div class="relative my-auto">
            <input
              type="text"
              id="Commentaire"
              v-model="document.comment"
              @change="saveSupplierCart()"
              :class="document.comment ? '' : 'border-2 border-red-500'"
              class="px-2.5 pb-1 pt-2 w-96 text-blue-900 rounded-lg border border-blue-300 focus:outline-none focus:ring-0 focus:border-orange-600 peer shadow-lg"
            />
            <label
              for="Commentaire"
              :class="document.comment ? '' : 'text-red-500'"
              class="absolute text-blue-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >Commentaire</label
            >
          </div>

          <div
            class="my-auto"
            v-for="(depot, indexDepot) in tabDepot"
            :key="indexDepot"
          >
            <button
              v-if="loadingCmd"
              disabled
              class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
            >
              <PulseLoader color="#ffffff" class="my-auto mr-2" />
              <span>Commande {{ depot.nom_depot }}</span>
            </button>
            <button
              v-else-if="!loadingCmd && !loadingGlobal"
              @click="sendOrder()"
              class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
            >
              Commander {{ depot.nom_depot }}
            </button>
          </div>

          <button
            v-if="loadingGlobal"
            class="my-auto bg-orange-500 text-white hover:bg-orange-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit rounded mr-1 flex justify-center items-center"
          >
            <PulseLoader color="#ffffff" class="my-auto mr-2" />
            <span>Disponibilités</span>
          </button>
          <button
            v-else
            class="my-auto bg-orange-500 text-white hover:bg-orange-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit rounded mr-1 flex justify-center items-center"
            @click="displayFournisseur(false, null)"
          >
            <!-- <span class="material-icons-outlined text-xl mr-2">
                find_replace
              </span> -->
            <span>Disponibilités</span>
          </button>
        </div>
      </div>

      <div
        v-if="document.percentage_status == 33 && !document.archived"
        class="flex flex-row justify-end items-center space-x-2"
      >
        <span v-show="errorRecep" class="text-red-500"
          >Les articles n'ont pas tous été récéptionné !</span
        >
        <button
          class="w-44 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          v-if="document.percentage_status == 33 && !loadingReception"
          @click="saveCommand()"
        >
          Valider
        </button>
        <button
          class="w-44 bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          v-else-if="loadingReception"
          disabled
        >
          <span>Valider</span>
          <span class="material-icons-round animate-spin"> cloud_sync </span>
        </button>
      </div>
    </div>

    <div
      v-if="document.percentage_status == 33 && !document.archived"
      class="flex space-x-2 mt-2"
    >
      <input
        v-model="scanEAN"
        type="text"
        placeholder="Scanner"
        @input="errorEAN = false"
        class="p-2 sm:w-1/5 lg:w-1/4 h-fit focus:outline-2 outline-sky-300 rounded shadow border border-blue-600 placeholder:text-blue-400"
        v-on:keyup.enter="getScanQuantity"
      />
      <p v-if="errorEAN" class="my-auto text-red-600">Article non trouvé !</p>
    </div>

    <div
      class="mt-2 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Code EAN
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Description
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                v-if="user.accountType == 'Administrateur'"
              >
                Prix d'achat
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Disponibilité
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité réceptionnée
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Quantité commandée
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                <div
                  v-if="document.percentage_status == 33 && !document.archived"
                  class="flex flex-row space-x-2 items-center"
                >
                  <button
                    class="p-1 text-xs bg-green-500 text-white rounded shadow"
                    @click="fillAllQty()"
                  >
                    Tout valider
                  </button>
                  <button
                    class="p-1 text-xs bg-red-500 text-white rounded shadow"
                    @click="unfillAllQty()"
                  >
                    Tout annuler
                  </button>
                </div>
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(rec, index) in document.order_articles"
              :key="index"
              class="cursor-pointer hover:bg-gray-100"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div
                  class="flex flex-row space-x-2 items-center text-sm leading-5 text-gray-900"
                >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ rec.Ref_fournisseur }}</span
                  >
                  <span
                    v-if="rec.Prefixe_tarif == 'Vhi'"
                    class="material-icons-outlined animate-pulse text-orange-500 cursor-pointer ml-1"
                    style="font-size: 16px"
                    @click="openModalRefSimil(rec.Ref_fournisseur, indexItem)"
                  >
                    auto_graph
                  </span>
                </div>
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ rec.Code_EAN }}</span
                  >
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ rec.Code_marque }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="text-sm leading-5 text-gray-900">
                  {{ rec.Description }}
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                v-if="user.accountType == 'Administrateur'"
              >
                <div class="text-sm leading-5 text-gray-900">
                  <div class="relative flex">
                    <input
                      v-if="rec.Prefixe_tarif == 'Vhi'"
                      class="p-1"
                      type="number"
                      @change="saveData()"
                      v-model="rec.prix_net"
                    />
                    <input
                      v-else
                      class="p-1"
                      type="number"
                      @change="saveData()"
                      v-model="rec.Prix_achat"
                    />
                    <span
                      v-if="loadingPA[indexItem]"
                      class="absolute right-1 my-1.5 transform -translate-y-1/2 animate-spin border-t-2 border-red-500 rounded-full h-4 w-4"
                    ></span>
                  </div>
                  <span> €</span>
                </div>
              </td>

              <!-- DISPONIBLITE -->
              <td class="px-2 py-4 border-b border-gray-200 whitespace-nowrap">
                <div v-if="document.supplier_prefix_tariff == 'Vhi'">
                  <table
                    v-if="rec.availability && rec.availability.length > 0"
                    class="min-w-full border-2"
                  >
                    <thead class="">
                      <tr>
                        <th
                          class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                        >
                          Type
                        </th>
                        <th
                          class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                        >
                          Quantité
                        </th>
                        <th
                          class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                        >
                          Date
                        </th>
                      </tr>
                    </thead>

                    <tbody class="bg-white">
                      <tr
                        v-for="(data, indexAvailability) of rec.availability"
                        :key="indexAvailability"
                        v-show="data.value !== 0"
                      >
                        <td
                          class="px-2 border-r border-gray-200 whitespace-nowrap"
                        >
                          <span
                            v-if="isDayDelivery(data.date)"
                            class="material-icons-round text-yellow-300 mt-2"
                          >
                            light_mode
                          </span>
                          <span
                            v-else
                            class="material-icons-round text-blue-800 mt-2"
                          >
                            dark_mode
                          </span>
                        </td>

                        <td
                          class="px-2 border-r border-gray-200 whitespace-nowrap"
                        >
                          <span>{{ data.value }}</span>
                        </td>

                        <td
                          class="px-2 border-r border-gray-200 whitespace-nowrap"
                        >
                          <span class="text-xs">{{
                            formatDate(data.date)
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div v-else>
                  <div v-if="rec?.loadingDispo">...</div>
                  <div
                    v-else-if="
                      rec?.availability &&
                      (rec.availability?.qtyJour || rec.availability?.qtySoir)
                    "
                  >
                    <div
                      v-if="
                        rec.availability?.qtyJour + rec.availability?.qtySoir >=
                        rec.quantity_asked
                      "
                      class="relative flex flex-row items-center space-x-2 text-blue-500"
                    >
                      <div>
                        <div
                          @mouseover="handleMouseOver()"
                          @mouseleave="handleMouseLeave()"
                        >
                          <span class="material-icons-round">check_circle</span>
                        </div>

                        <div
                          v-if="hoverText[indexItem]"
                          class="-ml-12 absolute bg-gray-200 text-black p-1.5 rounded-md text-xs mt-1 shadow-lg"
                        >
                          <table class="min-w-full border-2">
                            <thead class="">
                              <tr>
                                <th
                                  class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                >
                                  Type
                                </th>
                                <th
                                  class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                >
                                  Quantité
                                </th>
                                <th
                                  class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                >
                                  Date
                                </th>
                              </tr>
                            </thead>

                            <tbody class="bg-white">
                              <tr v-if="rec.availability?.qtyJour">
                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <!-- Icône Soleil -->
                                  <span
                                    class="material-icons-round text-yellow-300 mt-2"
                                  >
                                    light_mode
                                  </span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span>{{ rec.availability?.qtyJour }}</span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span class="text-xs">{{
                                    rec.availability?.dateJour
                                  }}</span>
                                </td>
                              </tr>
                              <tr v-if="rec.availability?.qtySoir">
                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <!-- Icône Lune -->
                                  <span
                                    class="material-icons-round text-blue-800 mt-2"
                                  >
                                    dark_mode
                                  </span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span>{{ rec.availability?.qtySoir }}</span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span class="text-xs">{{
                                    rec.availability?.dateSoir
                                  }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        {{
                          rec.availability?.qtyJour + rec.availability?.qtySoir
                        }}
                      </div>
                    </div>
                    <div
                      v-else
                      class="relative flex flex-row items-center space-x-2 text-orange-500"
                    >
                      <div>
                        <div
                          @mouseover="handleMouseOver()"
                          @mouseleave="handleMouseLeave()"
                        >
                          <span class="material-icons-round">help</span>
                        </div>

                        <div
                          class="-ml-12 absolute bg-gray-200 text-black p-1.5 rounded-md text-xs mt-1 shadow-lg"
                        >
                          <!-- <div v-if="rec.availability?.qtyJour">
                                  <p>Livraison jour :</p>
                                  <p>
                                    Quantité :
                                    {{ rec.availability?.qtyJour }} <br />
                                    Date : {{ rec.availability?.dateJour }}
                                  </p>
                                </div>
                                <div v-if="rec.availability?.qtySoir" class="mt-2">
                                  <p>Livraison nuit :</p>
                                  <p>
                                    Quantité : {{ rec.availability?.qtySoir }}<br />
                                    Date : {{ rec.availability?.dateSoir }}
                                  </p>
                                </div> -->

                          <table class="min-w-full border-2">
                            <thead class="">
                              <tr>
                                <th
                                  class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                >
                                  Type
                                </th>
                                <th
                                  class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                >
                                  Quantité
                                </th>
                                <th
                                  class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                >
                                  Date
                                </th>
                              </tr>
                            </thead>

                            <tbody class="bg-white">
                              <tr v-if="rec.availability?.qtyJour">
                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <!-- Icône Soleil -->
                                  <span
                                    class="material-icons-round text-yellow-300 mt-2"
                                  >
                                    light_mode
                                  </span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span>{{ rec.availability?.qtyJour }}</span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span class="text-xs">{{
                                    rec.availability?.dateJour
                                  }}</span>
                                </td>
                              </tr>
                              <tr v-if="rec.availability?.qtySoir">
                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <!-- Icône Lune -->
                                  <span
                                    class="material-icons-round text-blue-800 mt-2"
                                  >
                                    dark_mode
                                  </span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span>{{ rec.availability?.qtySoir }}</span>
                                </td>

                                <td
                                  class="px-2 border-r border-gray-200 whitespace-nowrap"
                                >
                                  <span class="text-xs">{{
                                    rec.availability?.dateSoir
                                  }}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div>
                        {{
                          rec.availability?.qtyJour + rec.availability?.qtySoir
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                {{ rec.quantity_received }}
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <div
                  v-if="document.percentage_status == 10"
                  class="flex space-x-2 text-sm leading-5 text-gray-900"
                >
                  <input
                    class="p-1 w-16 border rounded-md shadow outline-2 focus:outline-sky-300"
                    type="number"
                    v-model="rec.quantity_asked"
                    @change="displayFournisseur(true, indexItem)"
                  />
                  <div>
                    <span
                      class="material-icons-round bg-red-500 rounded-sm text-white text-xs cursor-pointer"
                      @click="
                        loadingDispo
                          ? dispoInProgress()
                          : deleteOneArticle(rec, indexItem)
                      "
                      :disabled="loadingDispo"
                    >
                      clear
                    </span>
                  </div>
                </div>
                <span v-else>{{ rec.quantity_asked }}</span>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
              >
                <div class="flex flex-row items-center">
                  <span
                    v-if="rec.quantity_asked == rec.quantity_received"
                    class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-green-400 border border-green-400"
                    >Complet</span
                  >
                  <span
                    class="bg-yellow-100 text-yellow-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-yellow-300 border border-yellow-300"
                    v-else-if="rec.quantity_asked < rec.quantity_received"
                  >
                    Surplus
                  </span>
                  <span
                    class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-400"
                    v-else
                  >
                    Incomplet
                  </span>
                  <div
                    v-if="
                      document.percentage_status == 33 && !document.archived
                    "
                  >
                    <span
                      class="inline-flex material-icons-round rounded-full hover:bg-red-400 hover:text-white"
                      @click="removeOne(index)"
                    >
                      remove
                    </span>
                    <span
                      class="inline-flex material-icons-round rounded-full hover:bg-green-500 hover:text-white"
                      @click="addOne(index)"
                    >
                      add
                    </span>
                    <span
                      class="inline-flex material-icons-round rounded hover:bg-blue-500 hover:text-white"
                      @click="fillQty(index)"
                    >
                      task_alt
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import * as XLSX from "xlsx";
import { validRecep } from "@/hooks/stock/reception.ts";
import {
  getStockFournisseur,
  backorderStock,
  unbackorderStock,
} from "@/hooks/stock/stock.ts";
// import { createReception } from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import sonTest1 from "../assets/sontest1.mp3";
import sonTest2 from "../assets/sontest2.mp3";

import {
  createSupplierOrder,
  getById,
  getAll,
  updateById,
  addOrRemoveArticle,
} from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import { getAllBySupplier } from "@/hooks/fournisseur/depotFournisseur";
import {
  saveSupplierOrder,
  sendA01,
  sendA05,
} from "@/hooks/partner/vanheck.ts";
import { success, warning, error } from "@/hooks/tools/toast";
import { sendCommandDSV } from "@/hooks/partner/dasilva.ts";
import { getFournisseurList } from "@/hooks/articles/articles.ts";

export default {
  data: () => ({
    document: {},
    tabDepot: [],
    loadingGlobal: false,
    loadingCmd: false,
    loadingPA: [],
    loadingDispo: false,
    // comCommande: null,
    // refCommande: null,

    scanEAN: "",
    open: false,
    errorRecep: false,
    loadingExport: false,
    loadingReception: false,
    numbers: [2, 5, 10, 20, 50, 100],
    stateNumber: false,
    indexScanned: null,
    errorEAN: false,

    openModalRemisesVH: false,
    PrixLot: [],
    indexArtSim: null,

    fournisseurTab: [],
    hoverText: [],
  }),
  computed: {
    ...mapGetters(["user", "plateform"]),
  },
  components: { PulseLoader },
  methods: {
    async getSupplierOrderByID() {
      const decodedId = atob(this.$route?.query?.id);
      this.orderId = decodedId;

      const data = await getById({ _id: decodedId });

      if (data) {
        this.document = data.supplier_order;
      }
    },

    async getScanQuantity() {
      this.errorEAN = false;
      var index = this.document.order_articles.findIndex(
        (article) => article.Code_EAN === this.scanEAN
      );

      if (index !== -1) {
        this.indexScanned = index;
        const son1 = new Audio(sonTest1);
        son1.play();
        this.document.order_articles[index].quantity_received += 1;
        this.saveQuantity();
        this.scanEAN = "";
        this.open = true;
      } else {
        this.indexScanned = null;
        const son2 = new Audio(sonTest2);
        son2.play();
        this.errorEAN = true;
        this.open = false;
        console.log("L'élément n'a pas été trouvé.");
      }
    },
    addByScanFull() {
      if (this.indexScanned !== -1) {
        this.fillQty(this.indexScanned);
        this.open = false;
        this.scanEAN = "";
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    isDayDelivery(dateString) {
      const date = new Date(dateString);
      return date.getHours() === 13 && date.getMinutes() === 59;
    },

    // addByScan() {
    //   var index = this.document.order_articles.findIndex(
    //     (article) => article.Code_EAN === this.scanEAN
    //   );

    //   if (index !== -1) {
    //     this.document.order_articles[index].quantity_received = parseInt(
    //       this.inputValue
    //     );
    //     this.open = false;
    //     this.saveQuantity();
    //     this.scanEAN = "";
    //   }
    // },
    // eqByScan() {
    //   var ean = this.document.order_articles.find(
    //     (element) => element.Code_EAN == this.scanEAN
    //   );
    //   if (ean != []) {
    //     var index = this.document.order_articles.indexOf(ean);
    //     this.document.order_articles[index].quantity_received ==
    //       parseInt(this.scanquantity);
    //     this.open = false;
    //     this.saveQuantity();
    //     this.scanEAN = "";
    //   }
    // },
    removeOne(index) {
      if (this.document.order_articles[index].quantity_received > 0) {
        this.document.order_articles[index].quantity_received =
          this.document.order_articles[index].quantity_received - 1;
        this.saveQuantity();
      }
    },
    addOne(index) {
      if (
        this.document.order_articles[index].quantity_received <
        this.document.order_articles[index].quantity_asked
      ) {
        this.document.order_articles[index].quantity_received =
          this.document.order_articles[index].quantity_received + 1;
        this.saveQuantity();
      }
    },
    fillQty(index) {
      this.document.order_articles[index].quantity_received =
        this.document.order_articles[index].quantity_asked;
      this.saveQuantity();
    },
    fillAllQty() {
      for (const art of this.document.order_articles) {
        if (art.quantity_received != art.quantity_asked) {
          art.quantity_received = art.quantity_asked;
        }
      }
      this.saveQuantity();
    },
    unfillAllQty() {
      for (const art of this.document.order_articles) {
        if (art.quantity_received == art.quantity_asked) {
          art.quantity_received = 0;
        }
      }
      this.saveQuantity();
    },
    saveQuantity() {
      axios.put(
        `${process.env.VUE_APP_API}/commandeFournisseur/${this.document._id}`,
        this.document
      );
    },
    async saveData() {
      await axios.put(
        `${process.env.VUE_APP_API}/commandeFournisseur/${this.document._id}`,
        this.document
      );
    },
    async exportExcel() {
      this.loadingExport = true;
      // await this.getAllClients();

      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.document.order_articles);
      XLSX.utils.book_append_sheet(wb, ws, "Reception");

      XLSX.writeFile(wb, "Liste_Reception" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      // this.success("Le fichier a été téléchargé");
    },
    async saveCommand() {
      this.loadingReception = true;
      let recepState = false;
      let RelicatArticles = [];
      for (const art of this.document.order_articles) {
        if (art.quantity_received < art.quantity_asked) {
          recepState = true;
          RelicatArticles.push({
            _id: art._id,
            Ref_fournisseur: art.Ref_fournisseur,
            Code_EAN: art.Code_EAN,
            Code_marque: art.Code_marque,
            Description: art.Description,
            Prefixe_tarif: art.Prefixe_tarif,
            Ref_similaire: art.Ref_similaire,
            checked: art.checked,
            fournisseur: art.fournisseur,
            quantity_asked: art.quantity_asked - art.quantity_received,
            quantity_received: 0,
            prix_net: art.prix_net,
            prix_brut: art.prix_brut,
            remise: art.remise,
          });
        }
        if (art.quantity_received >= 0) {
          recepState = false;
        }
      }
      if (!recepState) {
        // await validRecep(
        //   this.user.proprietaire,
        //   this.document._id,
        //   this.user.username,
        //   this.document.order_articles
        // );
        if (RelicatArticles.length > 0) {
          // await createReception(
          //   this.user.username,
          //   this.user.proprietaire,
          //   this.document.supplier,
          //   RelicatArticles,
          //   "R" + this.commandeReception.Name,
          //   "Relicat",
          //   this.commandeReception.Document,
          //   this.commandeReception.Regels
          // );

          const supplier_order = {
            order_type: "MANUAL",
            platform: this.user.proprietaire,
            order_articles: RelicatArticles,
            status: "CONFIRMED",
            percentage_status: 33,
            archived: false,
            order_reference: this.document.order_reference,
            comment: this.document.comment,
            supplier: this.document.supplier,
            supplier_prefix_tariff: this.document.supplier_prefix_tariff,
            creation_type: "Reliquat",
            created_by: this.user.username,
            created_at: new Date(),
            remainder: {
              principal_id: this.document._id,
              principal_num: this.document.order_number,
            },
          };
          const supplierDoc = await createSupplierOrder(supplier_order);
          this.document.remaining_id = supplierDoc._id;
          this.document.remaining_num = supplierDoc.order_number;
        }
        this.errorRecep = false;
        for (const art of this.document.order_articles) {
          if (art.quantity_received > 0) {
            await unbackorderStock(
              this.user._id,
              this.user.proprietaire,
              art._id,
              art.Ref_fournisseur,
              art.Code_marque,
              art.Code_EAN,
              0,
              0,
              10,
              "",
              "Réception de stock : " + art.FournisseurSelected,
              art.Prefixe_tarif,
              this.user.proprietaire,
              this.document.comment,
              "NA",
              "réception de stock",
              parseInt(art.quantity_received)
            );
          }

          this.document.status = "RECEIVED";
          this.document.percentage_status = 100;
          this.document.archived = true;
          this.document.received_by = this.user.username;
          this.document.received_at = new Date();
          await updateById(this.document);
          // await incrementStock(
          //   this.user.proprietaire,
          //   art.Ref_fournisseur,
          //   art.Code_EAN,
          //   art.Prefixe_tarif,
          //   art.Code_marque,
          //   parseInt(art.quantity_received),
          //   this.user.username,
          //   "Reception de stock : " + this.commandeReception.fournisseur,
          //   "NA",
          //   "0"
          // );
        }
        this.$router.push("/hubReceptionFournisseur");
      } else {
        this.errorRecep = true;
      }
      this.loadingReception = false;
    },
    setNumberStock(num) {
      if (!this.stateNumber) {
        this.document.order_articles[this.indexScanned].quantity_received +=
          num;
      } else {
        this.document.order_articles[this.indexScanned].quantity_received = num;
      }
      this.saveQuantity();
    },
    delNumberStock() {
      this.document.order_articles[this.indexScanned].quantity_received = "";
      this.saveQuantity();
    },
    increaseValue() {
      this.document.order_articles[this.indexScanned].quantity_received++;
      this.saveQuantity();
    },
    decreaseValue() {
      this.document.order_articles[this.indexScanned].quantity_received--;
      this.saveQuantity();
    },

    async getFournisseursDepot() {
      if (this.document.status == "CREATED") {
        this.tabDepot = await getAllBySupplier(
          this.user.proprietaire,
          this.document.supplier_prefix_tariff
        );
      }
    },
    async displayFournisseur(oneOrAll, indexArt = null) {
      this.fournisseurTab = [];
      this.loadingDispo = true;

      try {
        if (this.document?.order_articles.length > 0) {
          if (oneOrAll && indexArt !== null) {
            // Recherche du fournisseur pour un seul article
            let article = this.document.order_articles[indexArt];

            article.loadingDispo = true;

            article.quantity = article.quantity_asked;

            if (this.document.supplier_prefix_tariff === "Vhi") {
              await this.verifyDispoA01(oneOrAll, indexArt);
            } else {
              await this.fetchFournisseurForArticle(indexArt);
            }

            article.loadingDispo = false;
          } else {
            // Recherche du fournisseur pour tous les articles
            this.loadingGlobal = true;

            if (this.document.supplier_prefix_tariff === "Vhi") {
              await this.verifyDispoA01(oneOrAll, indexArt);
            } else {
              const promises = this.document.order_articles.map(
                (article, index) => {
                  article.quantity = article.quantity_asked;
                  return this.fetchFournisseurForArticle(index);
                }
              );
              await Promise.all(promises);
            }

            this.loadingGlobal = false;
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des fournisseurs :",
          error
        );
      }

      this.loadingDispo = false;
    },

    async fetchFournisseurForArticle(indexArt) {
      const article = this.document.order_articles[indexArt];
      if (!article) return;

      // this.document.order_articles[indexArt].availability = null;
      this.loadingPA[indexArt] = true;

      try {
        // Récupération du fournisseur
        const article_index = await getFournisseurList(
          this.user.proprietaire,
          article,
          this.document.session_client_name || "DIVERS",
          "T10", // TODO enregistrer le groupe de remise du client et l'utiliser ici
          article.quantity_asked
        );

        // this.fournisseurTab[indexArt] = article_index[0];
        this.document.order_articles[indexArt].availability = {};

        if (this.document.order_articles[indexArt]) {
          // Récupération de la disponibilité
          this.document.order_articles[indexArt].availability =
            await getStockFournisseur(
              article,
              article.Ref_fournisseur,
              article.Code_marque,
              article.Prefixe_tarif,
              this.plateform.grossierID,
              article.quantity_asked,
              this.plateform.grossierID,
              this.user.proprietaire
            );
        }
      } catch (error) {
        console.error(
          `Erreur lors de la récupération du fournisseur pour l'article ${indexArt} :`,
          error
        );
      }

      this.loadingPA[indexArt] = false;
    },
    async saveSupplierCart() {
      // this.document.order_reference = this.refCommande;
      // this.document.comment = this.comCommande;

      await saveSupplierOrder(this.document, true);
    },
    // VAN HECK
    async verifyDispoA01(oneOrAll, indexArt) {
      this.loadingDispo = true;
      if (this.document.supplier_prefix_tariff == "Vhi") {
        try {
          if (this.document?.order_articles.length > 0) {
            if (oneOrAll) {
              this.loadingPA[indexArt] = true;

              const data = await sendA01(
                this.document.order_articles[indexArt],
                this.plateform.grossierID,
                this.plateform.grossierID
              );

              if (data && this.document.order_articles[indexArt]) {
                this.document.order_articles[indexArt].prix_net = data.prix_net;
                this.document.order_articles[indexArt].availability =
                  data.availability;
              }

              this.loadingPA[indexArt] = false;
            } else {
              this.loadingGlobal = true;

              for (const [
                index,
                article,
              ] of await this.document.order_articles.entries()) {
                this.loadingPA[index] = true;

                const data = await sendA01(
                  article,
                  this.plateform.grossierID,
                  this.plateform.grossierID
                );

                if (data && this.document.order_articles[index]) {
                  this.document.order_articles[index].prix_net = data.prix_net;
                  this.document.order_articles[index].availability =
                    data.availability;
                }

                this.loadingPA[index] = false;
              }

              this.loadingGlobal = false;
            }

            await this.saveSupplierCart();
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      } else {
        warning("Impossible de récupérer !");
      }
      this.loadingDispo = false;
    },
    async sendOrder() {
      try {
        if (!this.document.comment) {
          warning("Veuillez renseigner les champs obligatoires !");
          return;
        }

        if (
          this.document.supplier_prefix_tariff == "Vhi" ||
          this.document.supplier_prefix_tariff == "Dsv"
        ) {
          this.loadingCmd = true;
          let data = {};
          if (this.document.supplier_prefix_tariff == "Vhi") {
            data = await sendA05(
              this.document.order_articles,
              this.user.proprietaire,
              this.user.username,
              this.plateform.grossierID,
              this.document.order_number,
              this.document.comment,
              this.plateform.grossierID
            );
          } else if (this.document.supplier_prefix_tariff == "Dsv") {
            data = await sendCommandDSV(
              this.user.proprietaire,
              this.document.order_articles,
              this.user.username
            );
          }

          if (data) {
            for (const [
              index,
              article,
            ] of this.document.order_articles.entries()) {
              article.availability =
                data.response_order_articles[index].availability;
              article.numero_commande =
                data.response_order_articles[index].numero_commande;

              // BACKORDER
              await backorderStock(
                this.user._id,
                this.user.proprietaire,
                article._id,
                article.Ref_fournisseur,
                article.Code_marque,
                article.Code_EAN,
                0,
                0,
                10,
                "",
                "Commande chez " + article?.FournisseurSelected,
                article.Prefixe_tarif,
                this.user.proprietaire,
                this.document.comment,
                "Commande Fournisseur",
                "Commande Fournisseur",
                article.quantity_asked
              );
            }
          }

          this.document.supplier_order_reference = data.supplier_order_number;
          this.document.status = "CONFIRMED";
          this.document.percentage_status = 33;
          this.document.approved_by = this.user.username;
          this.document.approved_at = new Date();

          await this.saveSupplierCart();

          this.loadingCmd = false;

          success("Commandé !");
        } else {
          warning("Impossible de commander !");
        }
      } catch (error) {
        console.log(error);
        this.loadingCmd = false;

        warning("Impossible de commander !");
      }
    },
    togglePause() {
      this.document.status =
        this.document.status === "PENDING" ? "CONFIRMED" : "PENDING";
      this.saveData();
    },

    // Remises fournisseurs VH
    openModalRefSimil(ref, index) {
      this.getPrixLot(ref);
      this.indexArtSim = index;
      this.openModalRemisesVH = true;
    },
    getPrixLot(ref) {
      axios
        .get(`${process.env.VUE_APP_API}/vanheck/getPrixLot/${ref}`)
        .then((response) => {
          this.PrixLot = response.data;
        })
        .catch(() => {
          this.PrixLot = [];
        });
    },
    changeQuantity(quantity) {
      this.document.order_articles[this.indexArtSim].quantity = quantity;
      this.document.order_articles[this.indexArtSim].quantity_asked = quantity;

      this.displayFournisseur(true, this.indexArtSim);
      this.saveSupplierCart();

      this.openModalRemisesVH = false;
      this.indexArtSim = null;
    },
    handleMouseOver(index) {
      this.hoverText[index] = true;
    },
    handleMouseLeave(index) {
      this.hoverText[index] = false;
    },
    dispoInProgress() {
      this.warning("Recherche de disponiblité en cours !");
    },
    async deleteOneArticle(article, indexArt) {
      this.document.order_articles.splice(indexArt, 1);

      await this.saveSupplierCart();

      this.success("Supprimé !");
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },

  async mounted() {
    await this.getSupplierOrderByID();
    this.getFournisseursDepot();
  },
};
</script>
<style></style>
