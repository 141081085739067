<template>
  <!-- Modal Détails dispo -->
  <div
    v-if="openModalRemisesVH"
    :class="`modal ${
      !openModalRemisesVH && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalRemisesVH = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 50rem"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="openModalRemisesVH = false"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Remises fournisseurs</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalRemisesVH = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <table class="min-w-full mt-4">
          <thead class="tableHEADTR">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté limite inférieure
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté limite supérieure
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                Prix
              </th>
            </tr>
          </thead>

          <tbody class="bg-white w-fit">
            <tr v-for="(r, index) in PrixLot" :key="index" class="tableHEADTR">
              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex space-x-2 items-center">
                  <p>{{ r.$.QtyLowerLimit }} pcs</p>
                  <button
                    @click="changeQuantity(r.$.QtyLowerLimit)"
                    class="material-icons-round text-bluevk hover:text-sky-300"
                  >
                    shopping_cart_checkout
                  </button>
                </div>
              </td>

              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="flex space-x-2 items-center">
                  <p>{{ r.$.QtyUpperLimit }} pcs</p>
                  <button
                    @click="changeQuantity(r.$.QtyUpperLimit)"
                    class="material-icons-round text-bluevk hover:text-sky-300"
                  >
                    shopping_cart_checkout
                  </button>
                </div>
              </td>

              <td
                class="bg-white px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
              >
                <div class="text-center">{{ financial(r.$.Price) }} €</div>
              </td>
            </tr>
          </tbody>
        </table>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="openModalRemisesVH = false"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- Modal recap cmd vanheck -->
  <div
    v-if="openRecapVH"
    :class="`modal ${
      !openRecapVH && 'opacity-0 pointer-events-none'
    } z-40 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 100rem; max-height: 60rem"
      class="z-40 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-40 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Panier instantané {{ fournisseur }}</p>
          <div
            class="z-40 cursor-pointer modal-close"
            @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
          >
            <svg
              class="text-black fill-current hover:scale-125 hover:border-gray-700 transition duration-150"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div
          v-if="loadingPreparation"
          class="flex justify-center animate-pulse m-2"
        >
          Chargement en cours...
        </div>
        <div v-else class="flex flex-col m-2">
          <div
            v-if="panier.status == 'CONFIRMED'"
            class="max-w-md mx-auto p-4 bg-green-50 border border-green-200 rounded-lg shadow-md"
          >
            <div class="flex items-center space-x-2 mb-2">
              <span class="material-icons-round text-green-600 text-xl"
                >done</span
              >
              <p class="text-green-700 font-semibold">Commande validée !</p>
            </div>

            <div class="text-sm text-gray-700 space-y-1">
              <p>
                <span class="font-medium">Référence fournisseur :</span>
                {{ panier.supplier_order_reference }}
              </p>
              <progressBar
                :status="panier.status"
                :progress="panier.percentage_status"
                class="my-2"
              />
              <p>
                <span class="font-medium">Par :</span> {{ panier.approved_by }}
              </p>
              <p>
                <span class="font-medium">Le :</span>
                {{ getFRDate(panier.approved_at) }}
              </p>
            </div>
          </div>

          <div v-else class="flex flex-col space-y-2">
            <div class="flex justify-between">
              <div class="flex space-x-2">
                <div class="relative">
                  <input
                    type="text"
                    id="ReferenceCmd"
                    v-model="panier.order_reference"
                    @change="saveSupplierCart()"
                    class="px-2.5 pb-1 pt-2 w-96 text-blue-900 rounded-lg border border-blue-300 focus:outline-none focus:ring-0 focus:border-orange-600 peer shadow-lg"
                  />
                  <label
                    for="ReferenceCmd"
                    class="absolute text-blue-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >Référence de commande</label
                  >
                </div>
                <div class="relative">
                  <input
                    type="text"
                    id="Commentaire"
                    v-model="panier.comment"
                    @change="saveSupplierCart()"
                    :class="panier.comment ? '' : 'border-2 border-red-500'"
                    class="px-2.5 pb-1 pt-2 w-96 text-blue-900 rounded-lg border border-blue-300 focus:outline-none focus:ring-0 focus:border-orange-600 peer shadow-lg"
                  />
                  <label
                    for="Commentaire"
                    :class="panier.comment ? '' : 'text-red-500'"
                    class="absolute text-blue-500 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white rounded-md px-1 peer-focus:px-2 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                    >Commentaire</label
                  >
                </div>
              </div>

              <div class="flex my-auto ml-auto">
                <div v-for="(depot, indexDepot) in tabDepot" :key="indexDepot">
                  <button
                    v-if="loadingCmd"
                    disabled
                    class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
                  >
                    <PulseLoader color="#ffffff" class="my-auto mr-2" />
                    <span>Commande {{ depot.nom_depot }}</span>
                  </button>
                  <button
                    v-else-if="!loadingCmd && !loadingGlobal"
                    @click="sendOrder(indexDepot, depot)"
                    class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
                  >
                    Commander {{ depot.nom_depot }}
                  </button>
                </div>

                <button
                  v-if="!loadingGlobal"
                  class="bg-orange-500 text-white hover:bg-orange-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit rounded mr-1 flex justify-center items-center"
                  @click="displayFournisseur(false, null)"
                >
                  <span>Disponibilités</span>
                </button>
                <button
                  v-else-if="loadingGlobal"
                  class="bg-orange-500 text-white hover:bg-orange-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit rounded mr-1 flex justify-center items-center"
                >
                  <PulseLoader color="#ffffff" class="my-auto mr-2" />
                  <span>Disponibilités</span>
                </button>
              </div>
            </div>

            <table class="min-w-full border-2">
              <div class="overflow-y-auto h-[40rem]">
                <thead class="tableHEADTR">
                  <tr>
                    <th
                      class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Référence
                    </th>
                    <th
                      class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Description
                    </th>
                    <th
                      class="w-48 px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Prix net HT
                    </th>
                    <th
                      class="w-48 px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Quantité
                    </th>
                    <th
                      class="px-2 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Disponibilité
                    </th>
                    <!-- <th
                      v-for="(depot, indexDepot) in tabDepot"
                      :key="indexDepot"
                      class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      <div class="flex space-x-2 m-auto">
                        <div class="my-auto uppercase font-medium text-orange-500">
                          {{ depot.nom_depot }}
                        </div>
                        <button
                          v-if="loadingRefreshAll[indexDepot]"
                          @click="executionByDepot = false"
                          class="material-icons-outlined text-md my-auto text-orange-500 shadow rounded-full bg-orange-100 animate-spin"
                        >
                          sync
                        </button>
                        <button
                          v-else
                          @click="refreshDispoByDepot(indexDepot, depot)"
                          class="material-icons-outlined text-md my-auto text-blue-500 shadow rounded-full bg-blue-100"
                        >
                          sync
                        </button>
                      </div>
                    </th> -->
                  </tr>
                </thead>

                <tbody class="tableHEADTR bg-white w-fit">
                  <tr
                    class="hover:shadow hover:bg-blue-50"
                    v-for="(item, indexItem) in panier.order_articles"
                    :key="indexItem"
                  >
                    <td
                      class="px-2 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="flex flex-row space-x-2 items-center">
                        <div class="text-sm leading-5 text-gray-900">
                          {{ item.Ref_fournisseur }}
                        </div>
                        <span
                          v-if="item.Prefixe_tarif == 'Vhi'"
                          class="material-icons-outlined animate-pulse text-orange-500 cursor-pointer ml-1"
                          style="font-size: 16px"
                          @click="
                            openModalRefSimil(item.Ref_fournisseur, indexItem)
                          "
                        >
                          auto_graph
                        </span>
                      </div>
                    </td>

                    <td
                      class="px-2 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        {{ item.Description }}
                      </div>
                    </td>

                    <td
                      class="w-48 px-2 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="leading-5 text-gray-900 flex flex-col">
                        <div class="text-sm">{{ item.Prix_euro }} €</div>
                        <div
                          class="text-sm flex flex-row items-center space-x-1"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          <span>PA: </span>
                          <div class="relative flex w-16">
                            <input
                              v-if="item.Prefixe_tarif == 'Vhi'"
                              disabled
                              type="number"
                              v-model="item.prix_net"
                              class="p-1 w-16 border rounded-md shadow outline-2 focus:outline-sky-300"
                            />
                            <input
                              v-else
                              disabled
                              type="number"
                              v-model="item.Prix_achat"
                              class="p-1 w-16 border rounded-md shadow outline-2 focus:outline-sky-300"
                            />
                            <span
                              v-if="loadingPA[indexItem]"
                              class="absolute right-1 my-1.5 transform -translate-y-1/2 animate-spin border-t-2 border-red-500 rounded-full h-4 w-4"
                            ></span>
                          </div>
                          <span>€</span>
                        </div>

                        <div
                          class="text-xs"
                          v-if="user.accountType == 'Administrateur'"
                        >
                          {{ item.remiseFournisseur }} %
                        </div>
                      </div>
                    </td>

                    <!-- QUANTITE -->
                    <td
                      class="w-48 px-2 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div
                        class="flex space-x-2 text-sm leading-5 text-gray-900"
                      >
                        <input
                          class="p-1 w-16 border rounded-md shadow outline-2 focus:outline-sky-300"
                          type="number"
                          v-model="item.quantity_asked"
                          @change="displayFournisseur(true, indexItem)"
                        />
                        <div>
                          <span
                            class="material-icons-round bg-red-500 rounded-sm text-white text-xs cursor-pointer"
                            @click="
                              loadingDispo
                                ? dispoInProgress()
                                : deleteOneArticle(item, indexItem)
                            "
                            :disabled="loadingDispo"
                          >
                            clear
                          </span>
                        </div>
                      </div>
                    </td>

                    <!-- DISPONIBLITE -->
                    <td
                      class="px-2 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div v-if="panier.supplier_prefix_tariff == 'Vhi'">
                        <table
                          v-if="item.availability"
                          class="min-w-full border-2"
                        >
                          <thead class="">
                            <tr>
                              <th
                                class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                              >
                                Type
                              </th>
                              <th
                                class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                              >
                                Quantité
                              </th>
                              <th
                                class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                              >
                                Date
                              </th>
                            </tr>
                          </thead>

                          <tbody class="bg-white">
                            <tr
                              v-for="(
                                data, indexAvailability
                              ) of item.availability"
                              :key="indexAvailability"
                              v-show="data.value !== 0"
                            >
                              <td
                                class="px-2 border-r border-gray-200 whitespace-nowrap"
                              >
                                <span
                                  v-if="isDayDelivery(data.date)"
                                  class="material-icons-round text-yellow-300 mt-2"
                                >
                                  light_mode
                                </span>
                                <span
                                  v-else
                                  class="material-icons-round text-blue-800 mt-2"
                                >
                                  dark_mode
                                </span>
                              </td>

                              <td
                                class="px-2 border-r border-gray-200 whitespace-nowrap"
                              >
                                <span>{{ data.value }}</span>
                              </td>

                              <td
                                class="px-2 border-r border-gray-200 whitespace-nowrap"
                              >
                                <span class="text-xs">{{
                                  formatDate(data.date)
                                }}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div v-else>
                        <div v-if="item?.loadingDispo">...</div>
                        <div
                          v-else-if="
                            item?.availability &&
                            (item.availability?.qtyJour ||
                              item.availability?.qtySoir)
                          "
                        >
                          <div
                            v-if="
                              item.availability?.qtyJour +
                                item.availability?.qtySoir >=
                              item.quantity_asked
                            "
                            class="relative flex flex-row items-center space-x-2 text-blue-500"
                          >
                            <div>
                              <div
                                @mouseover="handleMouseOver()"
                                @mouseleave="handleMouseLeave()"
                              >
                                <span class="material-icons-round"
                                  >check_circle</span
                                >
                              </div>

                              <div
                                v-if="hoverText[indexItem]"
                                class="-ml-12 absolute bg-gray-200 text-black p-1.5 rounded-md text-xs mt-1 shadow-lg"
                              >
                                <table class="min-w-full border-2">
                                  <thead class="">
                                    <tr>
                                      <th
                                        class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                      >
                                        Type
                                      </th>
                                      <th
                                        class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                      >
                                        Quantité
                                      </th>
                                      <th
                                        class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                      >
                                        Date
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody class="bg-white">
                                    <tr v-if="item.availability?.qtyJour">
                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <!-- Icône Soleil -->
                                        <span
                                          class="material-icons-round text-yellow-300 mt-2"
                                        >
                                          light_mode
                                        </span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span>{{
                                          item.availability?.qtyJour
                                        }}</span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span class="text-xs">{{
                                          item.availability?.dateJour
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr v-if="item.availability?.qtySoir">
                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <!-- Icône Lune -->
                                        <span
                                          class="material-icons-round text-blue-800 mt-2"
                                        >
                                          dark_mode
                                        </span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span>{{
                                          item.availability?.qtySoir
                                        }}</span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span class="text-xs">{{
                                          item.availability?.dateSoir
                                        }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div>
                              {{
                                item.availability?.qtyJour +
                                item.availability?.qtySoir
                              }}
                            </div>
                          </div>
                          <div
                            v-else
                            class="relative flex flex-row items-center space-x-2 text-orange-500"
                          >
                            <div>
                              <div
                                @mouseover="handleMouseOver()"
                                @mouseleave="handleMouseLeave()"
                              >
                                <span class="material-icons-round">help</span>
                              </div>

                              <div
                                class="-ml-12 absolute bg-gray-200 text-black p-1.5 rounded-md text-xs mt-1 shadow-lg"
                              >
                                <!-- <div v-if="item.availability?.qtyJour">
                                  <p>Livraison jour :</p>
                                  <p>
                                    Quantité :
                                    {{ item.availability?.qtyJour }} <br />
                                    Date : {{ item.availability?.dateJour }}
                                  </p>
                                </div>
                                <div v-if="item.availability?.qtySoir" class="mt-2">
                                  <p>Livraison nuit :</p>
                                  <p>
                                    Quantité : {{ item.availability?.qtySoir }}<br />
                                    Date : {{ item.availability?.dateSoir }}
                                  </p>
                                </div> -->

                                <table class="min-w-full border-2">
                                  <thead class="">
                                    <tr>
                                      <th
                                        class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                      >
                                        Type
                                      </th>
                                      <th
                                        class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                      >
                                        Quantité
                                      </th>
                                      <th
                                        class="px-2 py-1 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 border-r border-gray-200 bg-gray-50"
                                      >
                                        Date
                                      </th>
                                    </tr>
                                  </thead>

                                  <tbody class="bg-white">
                                    <tr v-if="item.availability?.qtyJour">
                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <!-- Icône Soleil -->
                                        <span
                                          class="material-icons-round text-yellow-300 mt-2"
                                        >
                                          light_mode
                                        </span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span>{{
                                          item.availability?.qtyJour
                                        }}</span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span class="text-xs">{{
                                          item.availability?.dateJour
                                        }}</span>
                                      </td>
                                    </tr>
                                    <tr v-if="item.availability?.qtySoir">
                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <!-- Icône Lune -->
                                        <span
                                          class="material-icons-round text-blue-800 mt-2"
                                        >
                                          dark_mode
                                        </span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span>{{
                                          item.availability?.qtySoir
                                        }}</span>
                                      </td>

                                      <td
                                        class="px-2 border-r border-gray-200 whitespace-nowrap"
                                      >
                                        <span class="text-xs">{{
                                          item.availability?.dateSoir
                                        }}</span>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                            <div>
                              {{
                                item.availability?.qtyJour +
                                item.availability?.qtySoir
                              }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>

                    <!-- DISPO -->
                    <!-- <td
                      v-for="(depot, indexDepot) in tabDepot"
                      :key="indexDepot"
                      class="py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="space-y-1">
                        <div
                          class=""
                          v-for="(alter, indexAlter) in depot.tabAlternatif"
                          :key="indexAlter"
                        >
                          <div
                            v-if="indexDepot > 0 && indexAlter == indexItem"
                            class="text-xs"
                          >
                            Qté demandé : {{ alter.quantity }}
                          </div>
                        </div>

                        <div
                          class="flex flex-row items-center space-x-2"
                          v-for="(dispo, indexDispo) in depot.tabDispo"
                          :key="indexDispo"
                        >
                          <div
                            v-if="indexDispo == indexItem && dispo.data"
                            class="space-y-1"
                          >
                            Nuit
                            <div v-if="parseInt(dispo.data.night) > 0" class="flex">
                              <div
                                :title="dispo.data.night_date"
                                class="flex border border-yellow-500 p-0.5 rounded shadow items-center"
                              >
                                <span
                                  class="material-icons-outlined text-yellow-500"
                                  style="font-size: 15px"
                                  >light_mode</span
                                >

                                <span
                                  class="text-xs text-black"
                                  v-if="parseInt(dispo.data.night)"
                                >
                                  {{ parseInt(dispo.data.night) }}
                                </span>
                              </div>
                            </div>

                            Jour
                            <div v-if="parseInt(dispo.data.day) > 0" class="flex">
                              <div
                                :title="dispo.data.day_date"
                                class="flex border border-blue-700 p-0.5 rounded shadow"
                              >
                                <span
                                  class="material-icons-outlined text-blue-700"
                                  style="font-size: 15px"
                                  >nightlight</span
                                >

                                <span
                                  class="text-xs text-black"
                                  v-if="parseInt(dispo.data.day)"
                                >
                                  {{ parseInt(dispo.data.day) }}
                                </span>
                              </div>
                            </div>
                          </div>

                          <div v-if="indexDispo == indexItem && dispo.data">
                            <span
                              class="material-icons-round text-sm text-blue-500 cursor-pointer"
                              @click="
                                refreshOneDispo(
                                  depot,
                                  indexItem,
                                  item.Ref_fournisseur,
                                  item.quantity
                                )
                              "
                              v-show="!depot.loading[indexItem]"
                            >
                              replay
                            </span>
                            <span
                              class="material-icons-round text-orange-500 animate-spin text-sm"
                              v-show="depot.loading[indexItem]"
                            >
                              replay
                            </span>
                          </div>
                        </div>
                      </div>
                    </td> -->
                  </tr>
                </tbody>
              </div>
            </table>

            <button
              v-if="panier.status == 'CREATED'"
              @click="clearPanier()"
              class="mr-auto mt-1 rounded-md bg-red-500 hover:bg-red-400 text-white py-1 px-2"
            >
              Tout supprimer
            </button>
          </div>
        </div>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="(openRecapVH = false), $emit('closeModalLKQ', false)"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Fin modal récap cmd vanheck -->
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import {
  manageSupplierOrder,
  saveSupplierOrder,
  getSupplierOrder,
  deleteSupplierOrder,
  deleteDocument,
  sendA01,
  sendA05,
} from "@/hooks/partner/vanheck.ts";

import { useToast } from "vue-toastification";
import ProgressBar from "@/components/ProgressBar.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { getAllBySupplier } from "@/hooks/fournisseur/depotFournisseur";

import { getFrDate } from "@/hooks/tools/date.ts";
import { getStockFournisseur, backorderStock } from "@/hooks/stock/stock.ts";
import { sendCommandDSV } from "@/hooks/partner/dasilva.ts";
import { getFournisseurList } from "@/hooks/articles/articles.ts";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      openRecapVH: true,
      indexDepot: 0,
      depotTab: [],
      // logCode: "9980177",
      depot: "STANDARD",

      commande: [],
      loadingPreparation: false,

      fournisseurs: [],
      login: "",
      logCode: "",
      tabDepot: [],

      tabChecked: [],

      tabDispo: [],
      loadingRefreshAll: [],
      loadingRefreshDispo: [],

      // tabDispoSchiedam: [],
      // loadingRefreshAllSchiedam: false,
      // loadingRefreshDispoSchiedam: [],
      // tabAlternatif: [],

      loadingGlobal: false,
      execution: false,
      executionByDepot: false,

      loadingCmd: false,

      panier: {},
      loadingPA: [],
      loadingDispo: false,

      openModalRemisesVH: false,
      PrixLot: [],
      indexArtSim: null,

      fournisseurTab: [],
      hoverText: [],
    };
  },
  components: {
    PulseLoader,
    ProgressBar,
  },
  props: [
    "bc_id",
    "fournisseur",
    "prefix_tarif",
    "comment",
    "tab_racine",
    "client_tab",
  ],
  computed: {
    ...mapGetters(["user", "plateform", "comptoirSessionActive"]),
  },
  methods: {
    ...mapActions(["savecomptoirsession"]),

    async clearPanier() {
      await deleteDocument(this.panier._id);
      this.panier = {};

      if (this.panier.creation_type == "comptoir") {
        this.savecomptoirsession({
          clientFocus: this.comptoirSessionActive[0].Client,
          vhi_cart_item: 0,
          dsv_cart_item: 0,
        });
      }
    },

    async getFournisseursDepot() {
      this.tabDepot = await getAllBySupplier(
        this.user.proprietaire,
        this.prefix_tarif
      );
    },
    // async displayFournisseur() {
    //   this.fournisseurTab = [];
    //   this.loadingFournisseur = true;
    //   this.openFournisseur = true;
    //   console.log("client_tab : ", this.client_tab);

    //   for (const [indexArt, article] of this.panier.order_articles.entries()) {
    //     const article_index = await getFournisseurList(
    //       this.user.proprietaire,
    //       article,
    //       this.client_tab ? this.client_tab.Name : "DIVERS",
    //       this.client_tab ? this.client_tab.Group.Name : "DIVERS",
    //       article.quantity
    //     );
    //     console.log("b fournisseurTab : ", article_index[0]);
    //     this.fournisseurTab[indexArt] = article_index[0];
    //     this.loadingFournisseur = false;
    //   }
    //   for (const [indexArt, article] of this.panier.order_articles.entries()) {
    //     for (const fourn of this.fournisseurTab) {
    //       console.log("c fournisseurTab : ", this.fournisseurTab);
    //       fourn.dispo = await getStockFournisseur(
    //         fourn.article,
    //         article.Ref_fournisseur,
    //         article.Code_marque,
    //         article.Prefixe_tarif,
    //         this.plateform.grossierID,
    //         article.quantity,
    //         this.plateform.grossierID,
    //         this.user.proprietaire
    //       );
    //       // this.fournisseurTab[indexArt].article["fraisdeport"] =
    //       // this.fournisseurTab[indexArt].dispo.fraisdeport;
    //       this.fournisseurTab[indexArt].loadingDispo = false;
    //     }
    //   }
    //   console.log("fournisseurTab : ", this.fournisseurTab);
    // },
    async displayFournisseur(oneOrAll, indexArt = null) {
      this.fournisseurTab = [];
      this.loadingDispo = true;

      try {
        if (this.panier?.order_articles.length > 0) {
          if (oneOrAll && indexArt !== null) {
            // Recherche du fournisseur pour un seul article
            let article = this.panier.order_articles[indexArt];

            article.loadingDispo = true;

            article.quantity = article.quantity_asked;

            if (this.panier.supplier_prefix_tariff === "Vhi") {
              await this.verifyDispoA01(oneOrAll, indexArt);
            } else {
              await this.fetchFournisseurForArticle(indexArt);
            }

            article.loadingDispo = false;
          } else {
            // Recherche du fournisseur pour tous les articles
            this.loadingGlobal = true;

            if (this.panier.supplier_prefix_tariff === "Vhi") {
              await this.verifyDispoA01(oneOrAll, indexArt);
            } else {
              const promises = this.panier.order_articles.map(
                (article, index) => {
                  article.quantity = article.quantity_asked;
                  return this.fetchFournisseurForArticle(index);
                }
              );
              await Promise.all(promises);
            }

            this.loadingGlobal = false;
          }
        }
      } catch (error) {
        console.error(
          "Erreur lors de la récupération des fournisseurs :",
          error
        );
      }

      this.loadingDispo = false;
    },

    async fetchFournisseurForArticle(indexArt) {
      const article = this.panier.order_articles[indexArt];
      if (!article) return;

      // this.panier.order_articles[indexArt].availability = null;
      this.loadingPA[indexArt] = true;

      try {
        // Récupération du fournisseur
        const article_index = await getFournisseurList(
          this.user.proprietaire,
          article,
          this.client_tab?.Name || "DIVERS",
          this.client_tab?.Group?.Name || "DIVERS",
          article.quantity_asked
        );

        this.panier.order_articles[indexArt].availability = {};

        if (this.panier.order_articles[indexArt]) {
          // Récupération de la disponibilité
          this.panier.order_articles[indexArt].availability =
            await getStockFournisseur(
              article,
              article.Ref_fournisseur,
              article.Code_marque,
              article.Prefixe_tarif,
              this.plateform.grossierID,
              article.quantity_asked,
              this.plateform.grossierID,
              this.user.proprietaire
            );
        }
      } catch (error) {
        console.error(
          `Erreur lors de la récupération du fournisseur pour l'article ${indexArt} :`,
          error
        );
      }

      this.loadingPA[indexArt] = false;
    },
    handleMouseOver(index) {
      this.hoverText[index] = true;
    },
    handleMouseLeave(index) {
      this.hoverText[index] = false;
    },

    async getSupplierCard() {
      const panier = await getSupplierOrder(
        {
          platform: this.user.proprietaire,
          session_id: this.tab_racine?._id,
          supplier_prefix_tariff: this.prefix_tarif,
          status: "CREATED",
        },
        true
      );

      if (panier) {
        this.panier = panier;
      }
    },
    async saveSupplierCart() {
      await saveSupplierOrder(this.panier, true);
    },

    dispoInProgress() {
      this.warning("Recherche de disponiblité en cours !");
    },
    async deleteOneArticle(article, indexArt) {
      this.panier.order_articles.splice(indexArt, 1);

      if (this.panier.creation_type == "comptoir") {
        this.savecomptoirsession({
          clientFocus: this.comptoirSessionActive[0].Client,
          vhi_cart_item:
            this.prefix_tarif == "Vhi"
              ? this.panier.order_articles.length
              : this.comptoirSessionActive[0]?.vhi_cart_item,
          dsv_cart_item:
            this.prefix_tarif == "Dsv"
              ? this.panier.order_articles.length
              : this.comptoirSessionActive[0]?.dsv_cart_item,
        });
      }

      await this.saveSupplierCart();

      this.success("Supprimé !");
    },

    // VAN HECK
    async verifyDispoA01(oneOrAll, indexArt) {
      this.loadingDispo = true;
      if (this.prefix_tarif == "Vhi") {
        try {
          if (this.panier?.order_articles.length > 0) {
            if (oneOrAll) {
              this.loadingPA[indexArt] = true;

              const data = await sendA01(
                this.panier.order_articles[indexArt],
                this.plateform.grossierID,
                this.plateform.grossierID
              );

              if (data && this.panier.order_articles[indexArt]) {
                this.panier.order_articles[indexArt].prix_net = data.prix_net;
                this.panier.order_articles[indexArt].availability =
                  data.availability;
              }

              this.loadingPA[indexArt] = false;
            } else {
              this.loadingGlobal = true;

              for (const [
                index,
                article,
              ] of await this.panier.order_articles.entries()) {
                this.loadingPA[index] = true;

                article.quantity = article.quantity_asked;

                const data = await sendA01(
                  article,
                  this.plateform.grossierID,
                  this.plateform.grossierID
                );

                if (data && this.panier.order_articles[index]) {
                  this.panier.order_articles[index].prix_net = data.prix_net;
                  this.panier.order_articles[index].availability =
                    data.availability;
                }

                this.loadingPA[index] = false;
              }

              this.loadingGlobal = false;
            }

            await this.saveSupplierCart();
          }
        } catch (error) {
          console.error("Erreur lors de la récupération des données :", error);
        }
      }
      // else {
      //   this.warning("Impossible de récupérer !");
      // }
      this.loadingDispo = false;
    },
    async sendOrder() {
      try {
        if (!this.panier.comment) {
          this.warning("Veuillez renseigner les champs obligatoires !");
          return;
        }

        if (this.prefix_tarif == "Vhi") {
          this.loadingCmd = true;
          let data = {};
          if (this.prefix_tarif == "Vhi") {
            data = await sendA05(
              this.panier.order_articles,
              this.user.proprietaire,
              this.user.username,
              this.plateform.grossierID,
              this.panier.order_number,
              this.panier.comment,
              this.plateform.grossierID
            );
          } else if (this.prefix_tarif == "Dsv") {
            data = await sendCommandDSV(
              this.user.proprietaire,
              this.panier.order_articles,
              this.user.username
            );
          }

          if (data) {
            for (const [
              index,
              article,
            ] of this.panier.order_articles.entries()) {
              article.availability =
                data.response_order_articles[index].availability;
              article.numero_commande =
                data.response_order_articles[index].numero_commande;

              // BACKORDER
              await backorderStock(
                this.user._id,
                this.user.proprietaire,
                article._id,
                article.Ref_fournisseur,
                article.Code_marque,
                article.Code_EAN,
                0,
                0,
                10,
                "",
                "Commande chez " + article?.FournisseurSelected,
                article.Prefixe_tarif,
                this.user.proprietaire,
                this.panier.comment,
                "Commande Fournisseur",
                "Commande Fournisseur",
                article.quantity_asked
              );
            }
          }

          this.panier.supplier_order_reference = data.supplier_order_number;
          this.panier.status = "CONFIRMED";
          this.panier.percentage_status = 33;
          this.panier.approved_by = this.user.username;
          this.panier.approved_at = new Date();

          await this.saveSupplierCart();

          if (this.panier.creation_type == "comptoir") {
            this.savecomptoirsession({
              clientFocus: this.comptoirSessionActive[0]?.Client,
              vhi_cart_item:
                this.prefix_tarif == "Vhi"
                  ? 0
                  : this.comptoirSessionActive[0]?.vhi_cart_item,
              dsv_cart_item:
                this.prefix_tarif == "Dsv"
                  ? 0
                  : this.comptoirSessionActive[0]?.dsv_cart_item,
            });
          }

          this.loadingCmd = false;

          this.success("Commandé !");
        } else {
          this.warning("Impossible de commander !");
        }
      } catch (error) {
        console.log(error);
        this.loadingCmd = false;

        this.warning("Impossible de commander !");
      }
    },

    getFRDate(date) {
      return getFrDate(date);
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return date.toLocaleString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    },
    isDayDelivery(dateString) {
      const date = new Date(dateString);
      return date.getHours() === 13 && date.getMinutes() === 59;
    },

    // Remises fournisseurs VH
    openModalRefSimil(ref, index) {
      this.getPrixLot(ref);
      this.indexArtSim = index;
      this.openModalRemisesVH = true;
    },
    getPrixLot(ref) {
      axios
        .get(`${process.env.VUE_APP_API}/vanheck/getPrixLot/${ref}`)
        .then((response) => {
          this.PrixLot = response.data;
        })
        .catch(() => {
          this.PrixLot = [];
        });
    },
    changeQuantity(quantity) {
      this.panier.order_articles[this.indexArtSim].quantity = quantity;
      this.panier.order_articles[this.indexArtSim].quantity_asked = quantity;

      this.displayFournisseur(true, this.indexArtSim);
      this.saveSupplierCart();

      this.openModalRemisesVH = false;
      this.indexArtSim = null;
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },

    // Message
    success(msg) {
      this.toast.success(msg, {
        position: "bottom-right",
        timeout: 4000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    error(msg) {
      this.toast.error(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning(msg) {
      this.toast.warning(msg, {
        position: "bottom-right",
        timeout: 2000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  async mounted() {
    this.loadingPreparation = true;

    await this.getSupplierCard();

    this.loadingPreparation = false;

    // await this.verifyDispoA01(false, null);

    this.getFournisseursDepot();

    // this.displayFournisseur(false, null);
  },
};
</script>
