<template>
  <!-- Modal PDF avec lien -->
  <div
    v-if="openModalPDF"
    class="fixed inset-0 z-50 flex items-center justify-center"
  >
    <!-- Overlay -->
    <div
      class="absolute inset-0 bg-gray-700 opacity-75"
      @click="openModalPDF = false"
    ></div>
    <div
      @click="openModalPDF = false"
      class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
    >
      <svg
        class="text-white fill-current"
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 18 18"
      >
        <path
          d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
        />
      </svg>
    </div>

    <!-- Modal Content -->
    <div
      class="relative mx-64 rounded-lg shadow-lg w-full h-full overflow-hidden flex flex-col"
    >
      <!-- Body -->
      <div class="flex-1 overflow-auto">
        <iframe
          :src="factureLink"
          class="w-full h-full"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  </div>

  <div class="bg-white w-full lg:p-4 xl:p-4 md:p-4 sm:p-4 rounded-sm shadow">
    <div class="flex justify-between">
      <backButton />
      <div class="flex justify-center">
        <button
          @click="
            detailFacture.link && detailFacture.link != ''
              ? openOneFacture(detailFacture.link)
              : createPDF(detailFacture)
          "
          class="bg-orange-600 hover:bg-orange-700 text-white py-2 px-4 rounded"
        >
          Imprimer
        </button>
      </div>
    </div>

    <div
      v-if="loadingPDF"
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-20"
    >
      <div class="bg-white py-5 px-20 rounded border shadow">
        <!-- <svg
                          class="animate-spin h-10 w-10 text-blue-500 mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z"></path>
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg> -->

        <div class="loader"></div>
        <p class="text-center mt-2">Chargement...</p>
      </div>
    </div>

    <div class="p-8 w-full">
      <!-- En-tête -->
      <div class="w-full">
        <div
          class="flex flex-col items-center w-full md:flex-row sm:flex-row lg:pb-10 xl:pb-10 md:pb-10 sm:pb-5"
        >
          <div class="w-1/2 sm:w-1/4">
            <img
              :src="plateform.lien_logo"
              alt="Logo de l'entreprise"
              class="w-1/2 h-auto mx-auto"
            />
          </div>
        </div>

        <!-- "Bon de livraison" sous l'image en md et sm -->
        <div class="mt-4 md:mt-0 sm:mt-0 text-center">
          <div
            class="text-gray-700 uppercase font-medium text-xl sm:text-s max-w-3/4 mx-auto"
          >
            Facture
            <span class="text-bluevk">n° {{ detailFacture.Numero }}</span>
          </div>
        </div>
      </div>
      <div class="flex justify-end text-gray-700 text-xs font-medium pt-8">
        Le {{ getFRDate(detailFacture.Date) }}
      </div>

      <!-- Adresses -->
      <div class="sm:flex sm:justify-between sm:pt-4 sm:pb-8">
        <div class="sm:w-2/4 md:mr-3 bg-white rounded-sm shadow-md">
          <div
            class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
          >
            Expéditeur
          </div>
          <div class="md:flex justify-between">
            <!-- Première partie -->
            <div class="flex-1 md:w-1/2 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ plateform.Titre2 }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ plateform.Adresse }} <br />
                {{ plateform.Code_postale }}
                <span class="uppercase">{{ plateform.Ville }}</span
                ><br />
                {{ plateform.Pays }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs font-bold text-gray-700">
                {{ plateform.Titre1 }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ plateform.Email }}<br />
                {{ plateform.Telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ plateform.Siret }} - {{ plateform.Tva }} -
                {{ plateform.Naf }}
              </div>
            </div>
          </div>
        </div>
        <!-- encart destinataire -->
        <div class="sm:w-2/4 md:ml-3 bg-white rounded-sm shadow-md">
          <div
            class="bg-bluevk text-white text-xs font-medium py-1 px-2 rounded-t-sm"
          >
            Destinataire
          </div>
          <div
            class="md:flex justify-between"
            v-if="detailFacture.clientInfo[0]"
          >
            <!-- Première partie -->
            <div class="flex-1 m-3">
              <div class="text-bluevk text-xs uppercase font-bold">
                {{ detailFacture.Client }}
              </div>
              <div class="text-bluevk text-xs font-m mt-1">
                {{ detailFacture.clientInfo[0].Adresse }}<br />
                {{ detailFacture.clientInfo[0].PostalCode }}
                <span class="uppercase">{{
                  detailFacture.clientInfo[0].City
                }}</span
                ><br />
                {{ detailFacture.clientInfo[0].Country }}
              </div>
            </div>
            <!-- Filet entre les deux parties -->
            <div class="my-1 border-l border-gray-300"></div>
            <!-- Deuxième partie -->
            <div class="flex-1 m-3 pb-3">
              <div class="text-xs text-gray-700 font-bold">
                {{ detailFacture.Name }}
              </div>
              <div class="text-xs text-gray-700 font-m mt-1">
                {{ detailFacture.clientInfo[0].email }}<br />
                {{ detailFacture.clientInfo[0].telephone }}
              </div>
              <div class="text-xs text-gray-700 mt-1">
                {{ detailFacture.clientInfo[0].siret }} -
                {{ detailFacture.clientInfo[0].numerotva }} -
                {{ detailFacture.clientInfo[0].nafape }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="detailFacture.Bdls.length > 0">
        <div v-for="(bdl, indexBdl) in detailFacture.Bdls" :key="indexBdl">
          <div class="md:mt-8">
            <div class="text-xs font-bold text-gray-700">
              Bdl n° : {{ bdl.Numero }}
            </div>
            <div class="text-xs font-bold text-gray-700">
              Servi par : {{ bdl.Name }}
            </div>
            <div class="text-xs italic font-medium text-bluevk ml-auto">
              Commentaire : {{ bdl.Note }}
            </div>
          </div>
          <!-- tableau responsive -->
          <div class="overflow-x-auto">
            <table
              class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse"
            >
              <!-- Table headers -->
              <thead>
                <tr class="bg-bluevk text-white text-left px-3 rounded-t-sm">
                  <th class="px-2 py-1 text-xs font-medium">Qté</th>
                  <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                  <th class="px-2 py-1 text-xs font-medium">Appellation</th>
                  <th
                    class="px-2 py-1 text-xs font-medium"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    PA €
                  </th>
                  <th
                    class="px-2 py-1 text-xs font-medium"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    Marge sur PV €
                  </th>
                  <th
                    class="px-2 py-1 text-xs font-medium"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    Marge sur PV %
                  </th>
                  <th class="px-2 py-1 text-xs font-medium">Prix brut HT</th>
                  <th class="px-2 py-1 text-xs font-medium">Remise</th>
                  <th class="px-2 py-1 text-xs font-medium">Prix net HT</th>
                  <th class="px-2 py-1 text-xs font-medium">Montant HT</th>
                </tr>
              </thead>

              <!-- Table body -->
              <tbody class="text-xs font-medium">
                <!-- Rows -->
                <tr v-for="(article, index) in bdl.Articles" :key="index">
                  <td class="border border-gray-300 p-2">
                    {{ article.quantity }}
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ article.Ref_fournisseur }}
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ article.Description }}
                  </td>

                  <!-- Prix achat -->
                  <td
                    class="border border-gray-300 p-2 font-bold text-orange-600"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    {{ parseFloat(article.Prix_achat).toFixed(2) }}
                    €
                  </td>

                  <!-- Marge sur PV € -->
                  <td
                    class="border border-gray-300 p-2 font-bold text-green-600"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    {{
                      parseFloat(
                        parseFloat(article.Prix_vente).toFixed(2) -
                          parseFloat(article.Prix_achat).toFixed(2)
                      ).toFixed(2)
                    }}
                    €
                  </td>

                  <!-- Marge sur PV % -->
                  <td
                    class="border border-gray-300 p-2 font-bold text-green-600"
                    v-if="user.accountType == 'Administrateur'"
                  >
                    {{
                      parseFloat(
                        ((parseFloat(article.Prix_vente).toFixed(2) -
                          parseFloat(article.Prix_achat).toFixed(2)) /
                          parseFloat(article.Prix_euro).toFixed(2)) *
                          100
                      ).toFixed(2)
                    }}
                    %
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ parseFloat(article.Prix_euro).toFixed(2) }}€
                  </td>

                  <td class="border border-gray-300 p-2">
                    {{ article.remise }}%
                  </td>

                  <td class="border border-gray-300 p-2">
                    <div v-if="article.PrixFournisseurType != 'prixnet'">
                      {{
                        parseFloat(
                          article.Prix_euro -
                            article.Prix_euro * (article.remise / 100)
                        ).toFixed(2)
                      }}
                    </div>
                    <div v-else>
                      {{ parseFloat(article.Prix_vente).toFixed(2) }}
                    </div>
                  </td>

                  <td class="border border-gray-300 p-2">
                    <div v-if="article.PrixFournisseurType != 'prixnet'">
                      {{
                        parseFloat(
                          (article.Prix_euro -
                            article.Prix_euro * (article.remise / 100)) *
                            article.quantity
                        ).toFixed(2)
                      }}
                    </div>
                    <div v-else>
                      {{
                        parseFloat(
                          article.Prix_vente * article.quantity
                        ).toFixed(2)
                      }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="md:mt-8">
          <div class="text-xs font-bold text-gray-700">
            Servi par : {{ detailFacture.Name }}
          </div>
          <div class="text-xs italic font-medium text-bluevk ml-auto">
            Commentaire : {{ detailFacture.Note }}
          </div>
        </div>
        <!-- tableau responsive -->
        <div class="overflow-x-auto">
          <table
            class="mt-2 mb-4 w-full text-gray-700 text-xs rounded-sm border-collapse"
          >
            <!-- Table headers -->
            <thead>
              <tr class="bg-bluevk text-white text-left px-3 rounded-t-sm">
                <th class="px-2 py-1 text-xs font-medium">Qté</th>
                <th class="px-2 py-1 text-xs font-medium">Réf.</th>
                <th class="px-2 py-1 text-xs font-medium">Appellation</th>
                <th
                  class="px-2 py-1 text-xs font-medium"
                  v-if="user.accountType == 'Administrateur'"
                >
                  PA €
                </th>
                <th
                  class="px-2 py-1 text-xs font-medium"
                  v-if="user.accountType == 'Administrateur'"
                >
                  Marge sur PV €
                </th>
                <th
                  class="px-2 py-1 text-xs font-medium"
                  v-if="user.accountType == 'Administrateur'"
                >
                  Marge sur PV %
                </th>
                <th class="px-2 py-1 text-xs font-medium">Prix brut HT</th>
                <th class="px-2 py-1 text-xs font-medium">Remise</th>
                <th class="px-2 py-1 text-xs font-medium">Prix net HT</th>
                <th class="px-2 py-1 text-xs font-medium">Montant HT</th>
              </tr>
            </thead>

            <!-- Table body -->
            <tbody class="text-xs font-medium">
              <!-- Rows -->
              <tr
                v-for="(article, index) in detailFacture.Articles"
                :key="index"
              >
                <td class="border border-gray-300 p-2">
                  {{ article.quantity }}
                </td>

                <td class="border border-gray-300 p-2">{{ article.Ref_fournisseur }}</td>

                <td class="border border-gray-300 p-2">
                  {{ article.Description }}
                </td>

                <!-- Prix achat -->
                <td
                  class="border border-gray-300 p-2 font-bold text-orange-600"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{
                    parseFloat(
                      detailFacture.Articles[index].Prix_achat
                    ).toFixed(2)
                  }}
                  €
                </td>

                <!-- Marge sur PV € -->
                <td
                  class="border border-gray-300 p-2 font-bold text-green-600"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{ setMargeArticle(index) }} €
                </td>

                <!-- Marge sur PV % -->
                <td
                  class="border border-gray-300 p-2 font-bold text-green-600"
                  v-if="user.accountType == 'Administrateur'"
                >
                  {{ setMargeArticlePercent(index) }} %
                </td>

                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(detailFacture.Articles[index].Prix_euro).toFixed(
                      2
                    )
                  }}€
                </td>

                <td class="border border-gray-300 p-2">
                  {{ detailFacture.Articles[index].remise }}%
                </td>

                <td class="border border-gray-300 p-2">
                  <div
                    v-if="
                      detailFacture.Articles[index].PrixFournisseurType !=
                      'prixnet'
                    "
                  >
                    {{
                      parseFloat(
                        detailFacture.Articles[index].Prix_euro -
                          detailFacture.Articles[index].Prix_euro *
                            (article.remise / 100)
                      ).toFixed(2)
                    }}
                  </div>
                  <div v-else>
                    {{
                      parseFloat(
                        detailFacture.Articles[index].Prix_vente
                      ).toFixed(2)
                    }}
                  </div>
                </td>

                <td class="border border-gray-300 p-2">
                  {{
                      article.PrixFournisseurType === "prixnet"
                        ? parseFloat(article.Prix_vente * article.quantity).toFixed(2)
                        : parseFloat(
                            (article.Prix_euro - article.Prix_euro * (article.remise / 100)) *
                              article.quantity
                          ).toFixed(2)
                    }}€
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="flex justify-end items-center pb-8 text-gray-700">
        <div class="w-1/3 flex justify-end">
          <table
            class="mt-4 mb-8 w-full rounded border-collapse border border-gray-300"
          >
            <thead></thead>
            <tbody class="text-xs font-medium">
              <!-- Première ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Transport</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(detailFacture.Transport).toFixed(2) }}€
                </td>
              </tr>

              <!-- Deuxième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total HT</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(detailFacture.TotalPrice).toFixed(2) }}€
                </td>
              </tr>

              <!-- Troisième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">TVA</td>
                <td class="border border-gray-300 p-2">
                  {{ parseFloat(detailFacture.TotalPrice * 0.2).toFixed(2) }}€
                </td>
              </tr>

              <!-- Quatrième ligne -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">Total TTC</td>
                <td class="border border-gray-300 p-2">
                  {{
                    parseFloat(
                      detailFacture.TotalPrice + detailFacture.TotalPrice * 0.2
                    ).toFixed(2)
                  }}€
                </td>
              </tr>
              <!-- Prix achat -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">
                  Total Prix Achat
                </td>
                <td class="border border-gray-300 p-2">
                  {{ setTotalPrixAchat }}
                  €
                </td>
              </tr>

              <!-- Prix vente -->
              <tr>
                <td class="border border-gray-300 font-bold p-2">
                  Total Prix Vente
                </td>
                <td class="border border-gray-300 p-2">
                  {{ setTotalPrixVente }}
                  €
                </td>
              </tr>

              <!-- Cinquième ligne -->
              <tr v-if="user.accountType == 'Administrateur'">
                <td class="border border-gray-300 font-bold p-2">Marge</td>
                <td class="border border-gray-300 font-bold p-2 text-green-600">
                  {{ setMargeTotal }} €, soit {{ setMargeTotalePourcentage }} %
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="w-1/3 flex ml-auto">
        <div
          class="text-xs font-medium flex justify-end items-center text-gray-700 ml-auto"
        >
          <p>
            <span class="font-bold">Date d'échéance :</span>
            {{ detailFacture.paymentDeadline }}
          </p>
        </div>
      </div>
      <!-- Mode de règlement -->
      <div class="flex justify-end items-center pb-8 text-gray-700">
        <div class="w-1/3 flex justify-end">
          <table
            class="mt-2 mb-8 w-full rounded border-collapse border border-gray-300"
            v-if="detailFacture.reglement.length > 0"
          >
            <thead>
              <tr>
                <td
                  class="text-xs font-medium py-1 px-2 rounded-t-sm border border-gray-300 bg-bluevk text-white font-bold p-2"
                  colspan="2"
                >
                  Mode de règlement
                </td>
              </tr>
            </thead>
            <tbody class="text-xs font-medium">
              <tr>
                <td class="border border-gray-300 p-2">
                  <div class="flex flex-row space-x-2">
                    <span
                      v-for="(regl, indexRegl) in detailFacture.reglement"
                      :key="indexRegl"
                    >
                      {{ regl.placeholder }} {{ regl.model }}€
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div v-if="FactureImprime" class="mt-4">
      <h2 class="text-xl font-semibold mb-2">Facture</h2>
      <p><strong>Numéro de commande :</strong> {{ numeroCommande }}</p>
      <p><strong>Nom du client :</strong> {{ nomClient }}</p>
      <p><strong>Articles :</strong></p>
      <pre>{{ articles }}</pre>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import { createfacture } from "@/hooks/pdf/facture";
import { createfacturepro } from "@/hooks/pdf/groupebdl";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";

export default {
  data() {
    return {
      bonDeLivraisonImprime: false,
      loadingPDF: false,

      detailFacture: {},
      openModalPDF: false,
      showDetailFacture: false,
      factureLink: "",
    };
  },
  components: {
    backButton,
  },
  computed: {
    ...mapGetters(["user", "plateform", "factureSelected"]),
    dateEcheance() {
      const dateEditionParts = this.detailFacture.Date.split("/");
      const day = parseInt(dateEditionParts[0], 10);
      const month = parseInt(dateEditionParts[1], 10);
      const year = parseInt(dateEditionParts[2], 10);

      const nextMonth = month === 12 ? 1 : month + 1;
      const nextYear = month === 12 ? year + 1 : year;

      const lastDayOfNextMonth = new Date(nextYear, nextMonth, 0);

      const dayFormatted = String(lastDayOfNextMonth.getDate()).padStart(
        2,
        "0"
      );
      const monthFormatted = String(lastDayOfNextMonth.getMonth() + 1).padStart(
        2,
        "0"
      );
      const yearFormatted = lastDayOfNextMonth.getFullYear();

      return `${dayFormatted}/${monthFormatted}/${yearFormatted}`;
    },
    setTotalPrixAchat() {
      var totalPrixAchat = 0.0;
      for (const art of this.detailFacture.Articles) {
        if (art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {
          totalPrixAchat += parseFloat(parseFloat(art.Prix_achat).toFixed(2));
        }
      }
      return totalPrixAchat
        ? parseFloat(parseFloat(totalPrixAchat).toFixed(2))
        : "-";
    },
    setTotalPrixVente() {
      var totalPrixVente = 0.0;
      for (const art of this.detailFacture.Articles) {
        if (art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {
          const prixVente =
            art.PrixFournisseurType == "prixnet"
              ? parseFloat(parseFloat(art.Prix_vente).toFixed(2))
              : parseFloat(
                  parseFloat(
                    art.Prix_euro - art.Prix_euro * (art.remise / 100)
                  ).toFixed(2)
                );

          totalPrixVente += parseFloat(parseFloat(prixVente).toFixed(2));
        }
      }
      return totalPrixVente
        ? parseFloat(parseFloat(totalPrixVente).toFixed(2))
        : "-";
    },
    setMargeTotal() {
      var total = 0.0;
      for (const [index, art] of this.detailFacture.Articles.entries()) {
        if (art.Prix_achat && (art.Prix_vente || art.Prix_euro)) {
          total += this.setMargeArticle(index);
        }
      }
      return total ? parseFloat(parseFloat(total).toFixed(2)) : "-";
    },
    setMargeTotalePourcentage() {
      const total = (1 - this.setTotalPrixAchat / this.setTotalPrixVente) * 100;
      return total ? parseFloat(parseFloat(total).toFixed(2)) : "-";
    },
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    async createPDF(objet) {
      this.loadingPDF = true;
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (objet.regroupe) {
        await this.createPDFfacture(objet._id, dateedition, datepdf);
      } else {
        if (dateedition == datepdf) {
          if (objet.TotalPrice >= 0) {
            createfacture(objet, false, false);
          } else {
            createfacture(objet, false, true);
          }
        } else if (objet.TotalPrice >= 0) {
          createfacture(objet, true, false);
        } else {
          createfacture(objet, true, true);
        }
      }
      this.loadingPDF = false;
    },
    async createPDFfacture(id, dateedition, datepdf) {
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
          id: id,
        })
        .then((objet) => {
          if (dateedition == datepdf) {
            if (objet.data.TotalPrice >= 0) {
              createfacturepro(objet.data, false, false);
            } else {
              createfacturepro(objet.data, false, true);
            }
          } else if (objet.data.TotalPrice >= 0) {
            createfacturepro(objet.data, true, false);
          } else {
            createfacturepro(objet.data, true, true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingPDF = false;
        });
    },

    async openModalDetail(idFacture, article) {
      console.log("idFacture : ", idFacture);
      console.log("article : ", article);

      let data;
      if (
        (article.Bdls && article.Bdls.length > 0) ||
        (article.Articles && article.Articles.length > 0)
      ) {
        data = article;
      } else {
        await axios
          .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
            id: idFacture,
          })
          .then((response) => {
            console.log("response : ", response.data);
            data = response.data;
          })
          .catch((error) => {
            console.error("Error fetching facture details:", error);
          });
      }
      this.detailFacture = data;
    },
    setMargeArticle(indexArt) {
      var marge = 0.0;
      for (const [index, art] of this.detailFacture.Articles.entries()) {
        if (
          indexArt == index &&
          art.Prix_achat &&
          (art.Prix_vente || art.Prix_euro)
        ) {
          const prixVente =
            art.PrixFournisseurType == "prixnet"
              ? parseFloat(parseFloat(art.Prix_vente).toFixed(2))
              : parseFloat(
                  parseFloat(
                    art.Prix_euro - art.Prix_euro * (art.remise / 100)
                  ).toFixed(2)
                );

          marge = prixVente - parseFloat(art.Prix_achat).toFixed(2);
        }
      }
      return marge ? parseFloat(parseFloat(marge).toFixed(2)) : "-";
    },
    setMargeArticlePercent(indexArt) {
      var marge = 0.0;
      for (const [index, art] of this.detailFacture.Articles.entries()) {
        if (
          indexArt == index &&
          art.Prix_achat &&
          (art.Prix_vente || art.Prix_euro)
        ) {
          const prixVente =
            art.PrixFournisseurType == "prixnet"
              ? parseFloat(parseFloat(art.Prix_vente).toFixed(2))
              : parseFloat(
                  parseFloat(
                    art.Prix_euro - art.Prix_euro * (art.remise / 100)
                  ).toFixed(2)
                );

          marge = (1 - parseFloat(art.Prix_achat).toFixed(2) / prixVente) * 100;
        }
      }
      return marge ? parseFloat(parseFloat(marge).toFixed(2)) : "-";
    },
    openOneFacture(link) {
      this.showDetailFacture = false;
      this.openModalPDF = true;
      this.factureLink = link;
    },
  },
  async beforeMount() {
    this.detailFacture = this.factureSelected;
    await this.openModalDetail(this.factureSelected._id, this.factureSelected);
  },
};
</script>

<style>
.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
