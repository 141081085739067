<template>
  <div
    v-if="openModalArticle"
    :class="`modal ${
      !openModalArticle && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openModalArticle = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openModalArticle = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <article-info
          :key="articleInfo_componentKey"
          @checkCross="checkCross"
        />
      </div>
    </div>
  </div>
  <div class="flex flex-row">
    <div class="bg-white rounded shadow w-1/5 h-[85vh] flex flex-col">
      <div :class="['w-full ', !showCatalogue ? 'h-full' : 'max-h-[80%]']">
        <div
          v-if="showCatalogue"
          @click="open_famille_process()"
          class="p-2 flex justify-between w-full hover:bg-gray-100 cursor-pointer"
        >
          <div>Familles</div>
          <span class="material-symbols-rounded" v-if="open_famille">
            expand_circle_down
          </span>
          <span class="material-symbols-rounded" v-else>
            expand_circle_right
          </span>
        </div>
        <div
          v-if="!showCatalogue || (showCatalogue && open_famille)"
          class="max-h-[95%] overflow-y-auto scroll-designed"
        >
          <div
            v-for="(fam, indexF) in familleUniverselle"
            :key="indexF"
            class="flex flex-row space-x-4 h-10 w-full hover:bg-gray-100 cursor-pointer p-2"
            @click="openCategory(fam)"
          >
            <!-- <div>img</div> -->
            <div>{{ fam.name }}</div>
          </div>
        </div>
      </div>
      <div v-if="showCatalogue" class="w-full mt-2">
        <div
          class="p-2 flex justify-between w-full hover:bg-gray-100 cursor-pointer"
          @click="open_marque = !open_marque"
        >
          <div>Marques</div>
          <span class="material-symbols-rounded" v-if="open_marque">
            expand_circle_down
          </span>
          <span class="material-symbols-rounded" v-else>
            expand_circle_right
          </span>
        </div>
        <div v-if="open_marque" class="flex flex-col space-y-1 p-2">
          <input
            type="text"
            class="px-2 w-full border border-gray-200 rounded-full"
            placeholder="Rechercher une marque"
            v-model="marque_value"
            @input="search_marque(marque_value)"
          />
          <div
            class="flex flex-col space-y-1 rounded max-h-[20vh] overflow-y-auto scroll-designed"
          >
            <div
              v-for="(marque, indexM) in marque_tab"
              @click="search_articles('marque', marque)"
              :key="indexM"
              :class="
                search_tab.marque.includes(marque)
                  ? 'text-blue-500 bg-blue-100 rounded-sm px-1 cursor-pointer'
                  : 'px-1 hover:text-bluevk cursor-pointer'
              "
            >
              {{ marque }}
            </div>
          </div>
        </div>
        <div
          class="p-2 flex justify-between w-full hover:bg-gray-100 cursor-pointer"
          v-if="sous_category_tab && sous_category_tab.length > 0"
          @click="open_sous_category = !open_sous_category"
        >
          <div>Catégories</div>
          <span class="material-symbols-rounded" v-if="open_sous_category">
            expand_circle_down
          </span>
          <span class="material-symbols-rounded" v-else>
            expand_circle_right
          </span>
        </div>
        <div
          v-if="
            open_sous_category &&
            sous_category_tab &&
            sous_category_tab.length > 0
          "
          class="flex flex-col space-y-1 p-2"
        >
          <input
            type="text"
            class="px-2 w-full border border-gray-200 rounded-full"
            placeholder="Rechercher une catégorie"
            v-model="sous_category_value"
            @input="search_sous_category(sous_category_value)"
          />
          <div
            class="flex flex-col space-y-1 rounded max-h-[20vh] overflow-y-auto scroll-designed"
          >
            <div
              v-for="(sous_cat, indexSC) in sous_category_tab"
              @click="selectSousCategory(sous_cat)"
              :key="indexSC"
              class="px-1 hover:text-bluevk cursor-pointer"
            >
              {{ sous_cat.name }}
            </div>
          </div>
        </div>

        <div
          class="p-2 flex justify-between w-full hover:bg-gray-100 cursor-pointer"
          @click="open_type = !open_type"
          v-if="type_tab.length > 0"
        >
          <div>Type</div>
          <span class="material-symbols-rounded" v-if="open_type">
            expand_circle_down
          </span>
          <span class="material-symbols-rounded" v-else>
            expand_circle_right
          </span>
        </div>
        <div
          v-if="open_type && type_tab.length > 0"
          class="flex flex-col space-y-1 p-2"
        >
          <input
            type="text"
            class="px-2 w-full border border-gray-200 rounded-full"
            placeholder="Rechercher un type"
            v-model="type_value"
            @input="search_type_tab(type_value)"
          />
          <div
            class="flex flex-col space-y-1 rounded max-h-[20vh] overflow-y-auto scroll-designed"
          >
            <div
              v-for="(type, indexTy) in type_tab"
              @click="search_articles('type', type)"
              :key="indexTy"
              :class="
                search_tab.type.includes(type)
                  ? 'text-blue-500 bg-blue-100 rounded-sm px-1 cursor-pointer'
                  : 'px-1 hover:text-bluevk cursor-pointer'
              "
            >
              {{ type }}
            </div>
          </div>
        </div>

        <!-- <div
          class="p-2 flex justify-between w-full hover:bg-gray-100 cursor-pointer"
          @click="open_dimensions = !open_dimensions"
        >
          <div>Caractéristiques</div>
          <span class="material-symbols-rounded" v-if="open_dimensions">
            expand_circle_down
          </span>
          <span class="material-symbols-rounded" v-else>
            expand_circle_right
          </span>
        </div>
        <div v-if="open_dimensions">Liste des dimensions</div> -->
      </div>
    </div>
    <div v-if="!showCatalogue" class="relative flex flex-col w-4/5">
      <div
        v-if="showCategory"
        class="absolute bg-gray-500 bg-opacity-50 w-full h-full z-20"
      >
        <div class="flex flex-col space-y-8 bg-white h-5/6 w-11/12 p-4">
          <div class="flex justify-between">
            <div></div>
            <div
              class="font-bold text-2xl text-bluevk border-b-4 border-orange-500"
            >
              {{ selected_famille_name }}
            </div>
            <button
              class="material-symbols-rounded text-red-500 hover:text-red-700"
              @click="closeCategory()"
            >
              close
            </button>
          </div>
          <div
            class="p-2 grid grid-cols-4 gap-8 max-h-[80vh] overflow-y-auto scroll-designed-orange"
          >
            <div
              class="flex flex-col space-y-2"
              v-for="(cat, indexC) in categoryUniverselle"
              :key="indexC"
            >
              <div
                class="font-bold text-bluevk hover:text-orange-500 cursor-pointer"
                @click="select_category(cat)"
              >
                {{ cat.name }}
              </div>
              <div
                class="flex flex-col space-y-1 max-h-[15vh] overflow-y-auto scroll-designed"
              >
                <div
                  class="text-sm hover:text-orange-500 cursor-pointer w-fit"
                  v-for="(sousCat, indexSC) in cat.sousFamille"
                  :key="indexSC"
                  @click="selectSousCategory(sousCat)"
                >
                  {{ sousCat.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-4">
        <div class="grid grid-cols-2 gap-4">
          <div class="h-[40vh]">
            <div class="h-full w-full" v-if="promoUniverselImage0">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage0.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[40vh]">
            <div class="h-full w-full" v-if="promoUniverselImage1">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage1.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div
          class="bg-white mt-4 rounded shadow w-full h-[3vh] flex flex-row itemse-center text-sm"
        >
          Barre de message
        </div> -->
        <div class="mt-4 grid grid-cols-5 gap-4">
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage2">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage2.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage3">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage3.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage4">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage4.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage5">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage5.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
          <div class="h-[28vh]">
            <div class="h-full w-full" v-if="promoUniverselImage6">
              <div class="h-full w-full bg-white rounded shadow p-2">
                <div class="relative h-full w-full">
                  <img :src="promoUniverselImage6.url" class="h-full mx-auto" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showCatalogue" class="relative flex flex-col w-4/5">
      <div
        v-if="showCategory"
        class="absolute bg-gray-500 bg-opacity-50 w-full h-full z-20"
      >
        <div class="flex flex-col space-y-8 bg-white h-5/6 w-11/12 p-4">
          <div class="flex justify-between">
            <div></div>
            <div
              class="font-bold text-2xl text-bluevk border-b-4 border-orange-500"
            >
              {{ selected_famille_name }}
            </div>
            <button
              class="material-symbols-rounded text-red-500 hover:text-red-700"
              @click="closeCategory()"
            >
              close
            </button>
          </div>
          <div
            class="p-2 grid grid-cols-4 gap-8 max-h-[80vh] overflow-y-auto scroll-designed-orange"
          >
            <div
              class="flex flex-col space-y-2"
              v-for="(cat, indexC) in categoryUniverselle"
              :key="indexC"
            >
              <div
                class="font-bold text-bluevk hover:text-orange-500 cursor-pointer"
                @click="select_category(cat)"
              >
                {{ cat.name }}
              </div>
              <div
                class="flex flex-col space-y-1 max-h-[15vh] overflow-y-auto scroll-designed"
              >
                <div
                  class="text-sm hover:text-orange-500 cursor-pointer w-fit"
                  v-for="(sousCat, indexSC) in cat.sousFamille"
                  :key="indexSC"
                  @click="selectSousCategory(sousCat)"
                >
                  {{ sousCat.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-between items-center items-center">
        <div class="w-1/4">
          <button
            class="ml-4 text-sm flex flex-row items-center w-fit hover:text-blue-500"
            @click="backAccueil()"
          >
            <span class="material-symbols-rounded text-sm">
              arrow_back_ios
            </span>
            <span>Accueil</span>
          </button>
        </div>
        <div
          class="font-bold text-2xl text-bluevk border-b-4 border-orange-500"
        >
          <span v-if="search_type === 'category'">{{
            selectedCategory.name
          }}</span>
          <span v-else-if="search_type === 'sous_category'">{{
            selectedSousCategory.name
          }}</span>
        </div>
        <div class="w-1/4 flex justify-end">
          <input
            type="text"
            class="px-4 py-1 w-full border border-gray-200 rounded-full"
            placeholder="Rechercher un article"
            v-model="article_value"
            @input="filter_article(article_value)"
          />
        </div>
      </div>

      <div
        class="scroll-container flex flex-col space-y-2 ml-4 mt-2 h-80 overflow-y-auto"
        @scroll="handleScroll"
        ref="articleContainer"
      >
        <div
          class="space-y-2"
          v-for="(famille, indexFamille) in ArticlesTab.data"
          :key="indexFamille"
        >
          <section-title
            :titre="famille.designation"
            @click="famille.show = !famille.show"
          />
          <div
            v-for="(article, indexArticle) in famille.data"
            :key="article.dbArticle._id + reloadkey.toString()"
          >
            <article-catalogue
              v-if="article.type == 'db'"
              v-show="famille.show"
              :tdArticle="article.tdArticle"
              :article="article.dbArticle"
              :Ref_fournisseur="article.dbArticle.Ref_fournisseur"
              :Code_marque="article.dbArticle.Code_marque"
              :Fournisseur="article.dbArticle.Prefixe_tarif"
              :genericArticleId="
                article.tdArticle.genericArticles[0].genericArticleId
              "
              :Description="article.dbArticle.Description"
              :partsList="article.partsList"
              :Images="article.image"
              :Location="[]"
              :Systeme="[]"
              :Stock="article.stock"
              :Prix="article.prix"
              :quantity="article.quantity"
              @checkedArticle="checkArticle(indexArticle, indexFamille)"
              :compareTabLength="compareTab.length"
              @infoArticle="plusInfo(article.dbArticle)"
              :partsListTab="localpartsListTab"
              @displayPartsList="displayPartsList"
              @deletePartsList="deletePartsList"
              :savedSearch="ArticlesTab.savedSearch"
              @setFraisdePort="article.dbArticle['fraisdeport'] = $event"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import articleCatalogue from "@/components/Article/articleCatalogue.vue";
import articleInfo from "@/components/articleInfo/articleInfo.vue";
export default {
  data() {
    return {
      familleUniverselle: [],
      selectedFamille: "",
      selected_famille_name: "",
      selectedCategory: {},
      selectedSousCategory: {},
      categoryUniverselle: [],
      sousCategoryUniverselle: [],
      showCategory: false,
      showSousCategory: false,
      showCatalogue: false,
      ArticlesTab: [],
      article_tab_filtered: [],
      marque_tab: [],
      marque_tab_filtered: [],
      type_tab: [],
      type_tab_filtered: [],
      sous_category_tab: [],
      sous_category_tab_filtered: [],
      reloadkey: 0,
      compareTab: [],
      scrollTab: [],

      currentPage: 1,
      maxPages: 1,
      lastPageLimit: 0,
      articlesPerPage: 20,
      count_articles: 0,

      promoUniverselImage0: null,
      promoUniverselImage1: null,
      promoUniverselImage2: null,
      promoUniverselImage3: null,
      promoUniverselImage4: null,
      promoUniverselImage5: null,
      promoUniverselImage6: null,

      open_famille: false,
      open_marque: true,
      open_type: true,
      open_dimensions: true,
      open_sous_category: true,

      search_tab: {
        marque: [],
        sous_category: [],
        type: [],
        dimensions: [],
      },
      search_type: "",

      marque_value: "",
      sous_category_value: "",
      type_value: "",
      article_value: "",

      articleInfo_componentKey: 0,
      openModalArticle: false,
    };
  },
  components: {
    articleCatalogue,
    articleInfo,
  },
  computed: {
    ...mapGetters(["plateform", "user", "plateform", "linkUniverselSelected"]),
  },
  methods: {
    ...mapActions([
      "selectSousCategoryUniverselle",
      "selectLinkUniversel",
      "setArticleInfo",
    ]),
    async getFamilleUniverselle() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      const catconf = await axios.get(
        `${process.env.VUE_APP_API}/configurationUniversel/getGroupe/${plateform_mere}`
      );
      const response = await axios.get(
        `${process.env.VUE_APP_API}/configurationUniversel/getConfiguration/${plateform_mere}`
      );
      this.familleUniverselle = response.data;
      this.familleUniverselle.sort((a, b) => {
        return a.name.localeCompare(b.name, "fr", { sensitivity: "base" });
      });
    },
    async openCategory(famille) {
      if (this.selectedFamille === famille._id) {
        this.closeCategory();
        this.categoryUniverselle = [];
        this.sousCategoryUniverselle = [];
        this.selectedFamille = "";
        this.selected_famille_name = "";
      } else {
        this.showCategory = true;
        this.sousCategoryUniverselle = [];
        this.selectedFamille = famille._id;
        this.selected_famille_name = famille.name;
      }

      if (this.showCategory) {
        this.categoryUniverselle = famille.famille;
      }
    },
    closeCategory() {
      this.showCategory = false;
      this.showSousCategory = false;
      this.categoryUniverselle = [];
      this.sousCategoryUniverselle = [];
      this.selectedFamille = "";
      this.selected_famille_name = "";
    },
    async openSousCategory(category) {
      if (this.selectedCategory === category._id) {
        this.showSousCategory = false;
        this.sousCategoryUniverselle = [];
      } else {
        this.showSousCategory = true;
        this.selectedCategory = category;
      }

      if (this.showSousCategory) {
        const catconf = await axios.post(
          `${process.env.VUE_APP_API}/configurationUniversel/getSousFamille`,
          {
            id: category._id,
          }
        );
        this.sousCategoryUniverselle = catconf.data;
      }
    },
    async select_category(category) {
      this.ArticlesTab = [];
      this.scrollTab = [];
      this.search_tab = {
        marque: [],
        sous_category: [],
        type: [],
        dimensions: [],
      };
      this.search_type = "category";
      this.articlesPerPage = 20;
      this.currentPage = 1;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      const article = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/get_category_articles`,
        {
          plateform: plateform_mere,
          category: category,
          filter: this.search_tab,
          skip: (this.currentPage - 1) * this.articlesPerPage,
          limit: this.articlesPerPage,
          sort_by: "prix",
          sort_order: -1,
        }
      );
      this.maxPages = Math.ceil(article.data.count / this.articlesPerPage);
      this.lastPageLimit = article.data.count % this.articlesPerPage;
      this.ArticlesTab = article.data;
      this.article_tab_filtered = JSON.parse(JSON.stringify(article.data));
      this.marque_tab = article.data.Marque;
      this.marque_tab_filtered = article.data.Marque;
      this.type_tab = article.data.Famille;
      this.type_tab_filtered = article.data.Famille;
      this.sous_category_tab = category.sousFamille;
      this.sous_category_tab_filtered = category.sousFamille;
      this.count_articles = article.data.count;
      this.selectedCategory = category;
      this.showCatalogue = true;
      this.closeCategory();
    },
    async selectSousCategory(sousCategory) {
      this.ArticlesTab = [];
      this.sous_category_list = [];
      this.search_tab = {
        marque: [],
        sous_category: [],
        type: [],
        dimensions: [],
      };
      this.scrollTab = [];
      this.search_type = "sous_category";
      this.articlesPerPage = 20;
      this.currentPage = 1;
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      const article = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/getCatalogueUniverselleArticles`,
        {
          plateform: plateform_mere,
          sousCategory: sousCategory,
          filter: this.search_tab,
          skip: (this.currentPage - 1) * this.articlesPerPage,
          limit: this.articlesPerPage,
          sort_by: "prix",
          sort_order: -1,
        }
      );
      this.maxPages = Math.ceil(article.data.count / this.articlesPerPage);
      this.lastPageLimit = article.data.count % this.articlesPerPage;
      this.ArticlesTab = article.data;
      this.article_tab_filtered = JSON.parse(JSON.stringify(article.data));
      this.marque_tab = article.data.Marque;
      this.marque_tab_filtered = article.data.Marque;
      this.type_tab = article.data.Famille;
      this.type_tab_filtered = article.data.Famille;
      this.count_articles = article.data.count;
      this.selectedSousCategory = sousCategory;
      this.showCatalogue = true;
      this.closeCategory();
    },
    async loadMoreArticles() {
      if (this.currentPage + 1 <= this.maxPages) {
        this.currentPage === this.maxPages
          ? (this.articlesPerPage = this.lastPageLimit)
          : (this.articlesPerPage = 20);
        this.currentPage++;
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere_catalogue) {
          plateform_mere = this.plateform.plateform_mere_catalogue;
        }
        let article;
        if (this.search_type === "category") {
          article = await axios.post(
            `${process.env.VUE_APP_API}/configurationUniversel/get_category_articles`,
            {
              plateform: plateform_mere,
              category: this.selectedCategory,
              filter: this.search_tab,
              skip: (this.currentPage - 1) * this.articlesPerPage,
              limit: this.articlesPerPage,
              sort_by: "prix",
              sort_order: -1,
            }
          );
        } else if (this.search_type === "sous_category") {
          article = await axios.post(
            `${process.env.VUE_APP_API}/configurationUniversel/getCatalogueUniverselleArticles`,
            {
              plateform: plateform_mere,
              sousCategory: this.selectedSousCategory,
              filter: this.search_tab,
              skip: (this.currentPage - 1) * this.articlesPerPage,
              limit: this.articlesPerPage,
              sort_by: "prix",
              sort_order: -1,
            }
          );
        }
        await this.ArticlesTab.data[0].data.push(...article.data.data[0].data);
        this.article_tab_filtered = JSON.parse(
          JSON.stringify(this.ArticlesTab)
        );
      }
    },
    handleScroll() {
      const container = this.$refs.articleContainer;
      const scrollH = container.scrollHeight - 500;
      if (container.scrollTop + container.clientHeight + 1 >= scrollH) {
        if (!this.scrollTab.includes(scrollH)) {
          this.loadMoreArticles();
          this.scrollTab.push(scrollH);
        }
      }
    },
    async getPromoUniverselImage() {
      const getPromoUniverselImage = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/getPromoUniverselImage`,
        {
          plateform: this.user.proprietaire,
        }
      );
      this.promoUniverselImage0 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 0
      );
      this.promoUniverselImage1 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 1
      );
      this.promoUniverselImage2 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 2
      );
      this.promoUniverselImage3 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 3
      );
      this.promoUniverselImage4 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 4
      );
      this.promoUniverselImage5 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 5
      );
      this.promoUniverselImage6 = getPromoUniverselImage.data.find(
        (promo) => promo.position === 6
      );
    },
    async search_articles(section, value) {
      this.scrollTab = [];
      this.articlesPerPage = 20;
      this.currentPage = 1;
      this.search_tab[section].includes(value)
        ? this.search_tab[section].splice(
            this.search_tab[section].indexOf(value),
            1
          )
        : this.search_tab[section].push(value);

      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere_catalogue) {
        plateform_mere = this.plateform.plateform_mere_catalogue;
      }
      let article;
      if (this.search_type == "category") {
        article = await axios.post(
          `${process.env.VUE_APP_API}/configurationUniversel/get_category_articles`,
          {
            plateform: plateform_mere,
            category: this.selectedCategory,
            filter: this.search_tab,
            skip: (this.currentPage - 1) * this.articlesPerPage,
            limit: this.articlesPerPage,
            sort_by: "prix",
            sort_order: -1,
          }
        );
      } else if (this.search_type == "sous_category") {
        article = await axios.post(
          `${process.env.VUE_APP_API}/configurationUniversel/getCatalogueUniverselleArticles`,
          {
            plateform: plateform_mere,
            sousCategory: this.selectedSousCategory,
            filter: this.search_tab,
            skip: (this.currentPage - 1) * this.articlesPerPage,
            limit: this.articlesPerPage,
            sort_by: "prix",
            sort_order: -1,
          }
        );
      }
      this.ArticlesTab = JSON.parse(JSON.stringify(article.data));
      this.article_tab_filtered = JSON.parse(JSON.stringify(article.data));
    },
    open_famille_process() {
      this.open_famille = !this.open_famille;
      this.open_marque = false;
      this.open_type = false;
      this.open_dimensions = false;
      this.open_sous_category = false;
    },
    backAccueil() {
      this.showCatalogue = false;
      this.ArticlesTab = [];
      this.selectedSousCategory = "";
    },
    plusInfo(art) {
      this.articleInfo_componentKey++;
      this.openModalArticle = true;
      this.setArticleInfo(art);
    },
    search_marque(value) {
      if (value === "") {
        this.marque_tab = this.marque_tab_filtered;
      } else {
        this.marque_tab = this.marque_tab_filtered.filter((marque) =>
          marque.toLowerCase().includes(value.toLowerCase())
        );
        if (this.marque_tab.length === 0) {
          this.marque_tab = ["Aucune marque trouvée"];
        }
      }
    },
    search_sous_category(value) {
      if (value === "") {
        this.sous_category_tab = this.sous_category_tab_filtered;
      } else {
        this.sous_category_tab = this.sous_category_tab_filtered.filter(
          (sous_category) =>
            sous_category.name.toLowerCase().includes(value.toLowerCase())
        );
        if (this.sous_category_tab.length === 0) {
          this.sous_category_tab = [{ name: "Aucune catégorie trouvée" }];
        }
      }
    },
    search_type_tab(value) {
      if (value === "") {
        this.type_tab = this.type_tab_filtered;
      } else {
        this.type_tab = this.type_tab_filtered.filter((type) =>
          type.toLowerCase().includes(value.toLowerCase())
        );
        if (this.type_tab.length === 0) {
          this.type_tab = ["Aucun type trouvé"];
        }
      }
    },
    filter_article(value) {
      if (value === "") {
        this.ArticlesTab = JSON.parse(
          JSON.stringify(this.article_tab_filtered)
        );
      } else {
        this.ArticlesTab.data[0].data =
          this.article_tab_filtered.data[0].data.filter(
            (article) =>
              article.dbArticle.Description.toLowerCase().includes(
                value.toLowerCase()
              ) ||
              article.dbArticle.Ref_fournisseur.toLowerCase().includes(
                value.toLowerCase()
              )
          );

        if (this.ArticlesTab.length === 0) {
          this.ArticlesTab = {
            data: [
              {
                data: [
                  {
                    dbArticle: {
                      Description: "Aucun article trouvé",
                      Ref_fournisseur: "",
                    },
                  },
                ],
              },
            ],
          };
        }
      }
    },
  },
  beforeMount() {
    this.getPromoUniverselImage();
  },
  async mounted() {
    this.getFamilleUniverselle();
    if (this.linkUniverselSelected) {
      this.selectSousCategory(this.linkUniverselSelected);
      await this.selectLinkUniversel({ state: "del", uni: null });
    }
  },
};
</script>
<style scoped>
.scroll-container::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: just to hide the scrollbar */
}

.scroll-designed::-webkit-scrollbar {
  width: 6px; /* Épaisseur de la scrollbar */
}

.scroll-designed::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Couleur de fond discrète */
  border-radius: 10px;
}

.scroll-designed::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #467bf9, #4a7ae0); /* Dégradé doré */
  border-radius: 10px;
}

.scroll-designed::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    180deg,
    #0242d6,
    #001e78
  ); /* Couleur plus foncée au survol */
}

.scroll-designed-orange::-webkit-scrollbar {
  width: 6px; /* Épaisseur de la scrollbar */
}

.scroll-designed-orange::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Couleur de fond discrète */
  border-radius: 10px;
}

.scroll-designed-orange::-webkit-scrollbar-thumb {
  background: linear-gradient(180deg, #f97316, #e9670a); /* Dégradé doré */
  border-radius: 10px;
}

.scroll-designed-orange::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(
    180deg,
    #d26211,
    #b0500c
  ); /* Couleur plus foncée au survol */
}
</style>
