<template>
  <div
    class="flex flex-col space-y-4"
    v-if="user.accountType == 'Administrateur'"
  >
    <div class="flex">
      <div class="flex justify-start">
        <button
          class="p-1 bg-bluevk hover:bg-blue-500 text-white rounded-md shadow-lg"
          @click="$router.push('/statistiques')"
        >
          Ancienne version
        </button>
      </div>
      <titrePages class="flex flex-1 justify-center" :title="'Statistiques'" />
    </div>

    <div class="flex flex-row space-x-2 items-center">
      <button
        @click="(time = 'day'), initStats()"
        class="px-4 py-2 bg-bluevk text-white font-semibold rounded-lg shadow-md hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
      >
        Jour
      </button>
      <button
        @click="(time = 'week'), initStats()"
        class="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
      >
        Semaine
      </button>
      <button
        @click="(time = 'month'), initStats()"
        class="px-4 py-2 bg-purple-500 text-white font-semibold rounded-lg shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-300 focus:ring-offset-2"
      >
        Mois
      </button>
    </div>
    <div
      class="flex flex-row space-x-8 items-center mx-auto bg-white rounded-xl shadow py-2 px-8 w-fit"
    >
      <div
        v-if="
          global_statistics.bdl != null && global_statistics.facture != null
        "
        class="flex flex-row items-center space-x-4"
      >
        <div>
          <span
            v-if="
              global_statistics.bdl + global_statistics.facture >
              global_statistics.bdl_before + global_statistics.facture_before
            "
            class="material-symbols-rounded text-6xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-6xl text-red-500">
            trending_down
          </span>
        </div>
        <div>
          <span
            :class="[
              'font-bold text-7xl',
              global_statistics.bdl + global_statistics.facture >
              global_statistics.bdl_before + global_statistics.facture_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{
              (global_statistics.bdl + global_statistics.facture).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>

      <div
        v-if="
          global_statistics.bdl != null && global_statistics.facture != null
        "
        class="flex flex-col space-y-1 text-gray-500 text-lg"
      >
        <div
          v-if="
            global_statistics.bdl + global_statistics.facture >
            global_statistics.bdl_before + global_statistics.facture_before
          "
        >
          <span class="underline font-bold"
            >{{
              (
                global_statistics.bdl +
                global_statistics.facture -
                (global_statistics.bdl_before +
                  global_statistics.facture_before)
              ).toFixed(2)
            }}
            €</span
          >
          de plus que <span v-if="time == 'day'"> hier</span
          ><span v-else-if="time == 'week'">la semaine dernière</span
          ><span v-else>le mois dernier</span>
        </div>
        <div v-else>
          plus que
          <span class="underline font-bold"
            >{{
              (
                global_statistics.bdl_before +
                global_statistics.facture_before -
                (global_statistics.bdl + global_statistics.facture)
              ).toFixed(2)
            }}
            € </span
          >pour faire mieux que <span v-if="time == 'day'"> hier</span
          ><span v-else-if="time == 'week'">la semaine dernière</span
          ><span v-else>le mois dernier</span>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-4 gap-4">
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            inactive_order
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl"
            >Commandes en attentes</span
          >
          <span
            v-if="global_statistics.orders != null"
            class="font-bold text-blue-500 text-5xl"
            >{{ global_statistics.orders }} €</span
          >
        </div>
        <div>
          <span class="material-symbols-rounded text-4xl text-orange-500">
            hourglass_empty
          </span>
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            edit_document
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl"
            >Devis à transformer</span
          >
          <span
            v-if="global_statistics.quotation != null"
            class="font-bold text-blue-500 text-5xl"
            >{{ global_statistics.quotation }} €</span
          >
        </div>
        <div>
          <span class="material-symbols-rounded text-4xl text-orange-500">
            shopping_bag_speed
          </span>
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            package_2
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl">Bons de livraison</span>
          <span
            v-if="global_statistics.bdl != null"
            :class="[
              'font-bold text-5xl',
              global_statistics.bdl > global_statistics.bdl_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{ global_statistics.bdl }} €</span
          >
        </div>
        <div
          v-if="
            global_statistics.bdl != null &&
            global_statistics.bdl_before != null
          "
          class="flex flex-col"
        >
          <span
            v-if="global_statistics.bdl > global_statistics.bdl_before"
            class="material-symbols-rounded text-4xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-4xl text-red-500">
            trending_down
          </span>
          <span
            v-if="global_statistics.bdl > global_statistics.bdl_before"
            class="text-green-500"
            >+{{
              (global_statistics.bdl - global_statistics.bdl_before).toFixed(2)
            }}
            €</span
          >
          <span v-else class="text-red-500"
            >-{{
              (global_statistics.bdl_before - global_statistics.bdl).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            credit_score
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl">Factures</span>
          <span
            v-if="global_statistics.facture != null"
            :class="[
              'font-bold text-5xl',
              global_statistics.facture > global_statistics.facture_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{ global_statistics.facture }} €</span
          >
        </div>
        <div
          v-if="
            global_statistics.facture != null &&
            global_statistics.facture_before != null
          "
          class="flex flex-col"
        >
          <span
            v-if="global_statistics.facture > global_statistics.facture_before"
            class="material-symbols-rounded text-4xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-4xl text-red-500">
            trending_down
          </span>
          <span
            v-if="global_statistics.facture > global_statistics.facture_before"
            class="text-green-500"
            >+{{
              (
                global_statistics.facture - global_statistics.facture_before
              ).toFixed(2)
            }}
            €</span
          >
          <span v-else class="text-red-500"
            >-{{
              (
                global_statistics.facture_before - global_statistics.facture
              ).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
    </div>
    <div class="text-2xl font-bold">Magasiniers :</div>
    <div class="grid grid-cols-6 gap-4">
      <div
        v-for="(user_data, indexUD) in global_user_statistics"
        :key="indexUD"
        class="flex flex-col space-y-4 bg-white hover:bg-gray-200 rounded shadow p-2 cursor-pointer"
        @click="show_user_detail[indexUD] = !show_user_detail[indexUD]"
      >
        <div class="mx-auto font-bold text-xl">{{ user_data.user }}</div>
        <div class="flex flex-row space-x-2 w-full justify-center">
          <div
            :class="[
              'text-2xl',
              user_data.data.bdl + user_data.data.facture >
              user_data.data.bdl_before + user_data.data.facture_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
          >
            {{ (user_data.data.bdl + user_data.data.facture).toFixed(2) }} €
          </div>
          <div class="flex flex-row items-center">
            <span
              v-if="
                user_data.data.bdl + user_data.data.facture >
                user_data.data.bdl_before + user_data.data.facture_before
              "
              class="material-symbols-rounded text-2xl text-green-500"
            >
              trending_up
            </span>
            <span v-else class="material-symbols-rounded text-2xl text-red-500">
              trending_down
            </span>
            <span
              v-if="
                user_data.data.bdl + user_data.data.facture >
                user_data.data.bdl_before + user_data.data.facture_before
              "
              class="text-green-500"
              >+{{
                (
                  user_data.data.bdl +
                  user_data.data.facture -
                  (user_data.data.bdl_before + user_data.data.facture_before)
                ).toFixed(2)
              }}
              €</span
            >
            <span v-else class="text-red-500"
              >-{{
                (
                  user_data.data.bdl_before +
                  user_data.data.facture_before -
                  (user_data.data.bdl + user_data.data.facture)
                ).toFixed(2)
              }}
              €</span
            >
          </div>
        </div>
        <div v-if="show_user_detail[indexUD]" class="grid grid-cols-2 gap-4">
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Commandes</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.orders }} €
            </div>
          </div>
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Devis</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.quotation }} €
            </div>
          </div>
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Bon de livraison</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.bdl }} €
            </div>
          </div>
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Facture</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.facture }} €
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="text-2xl font-bold">Commerciaux :</div>
    <div class="grid grid-cols-6 gap-4">
      <div
        v-for="(user_data, indexUD) in global_commercial_statistics"
        :key="indexUD"
        class="flex flex-col space-y-4 bg-white hover:bg-gray-200 rounded shadow p-2 cursor-pointer"
        @click="
          show_commercial_detail[indexUD] = !show_commercial_detail[indexUD]
        "
      >
        <div class="mx-auto font-bold text-xl">{{ user_data.user }}</div>
        <div class="flex flex-row space-x-2 w-full justify-center">
          <div
            :class="[
              'text-2xl',
              user_data.data.bdl + user_data.data.facture >
              user_data.data.bdl_before + user_data.data.facture_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
          >
            {{ (user_data.data.bdl + user_data.data.facture).toFixed(2) }} €
          </div>
          <div class="flex flex-row items-center">
            <span
              v-if="
                user_data.data.bdl + user_data.data.facture >
                user_data.data.bdl_before + user_data.data.facture_before
              "
              class="material-symbols-rounded text-2xl text-green-500"
            >
              trending_up
            </span>
            <span v-else class="material-symbols-rounded text-2xl text-red-500">
              trending_down
            </span>
            <span
              v-if="
                user_data.data.bdl + user_data.data.facture >
                user_data.data.bdl_before + user_data.data.facture_before
              "
              class="text-green-500"
              >+{{
                (
                  user_data.data.bdl +
                  user_data.data.facture -
                  (user_data.data.bdl_before + user_data.data.facture_before)
                ).toFixed(2)
              }}
              €</span
            >
            <span v-else class="text-red-500"
              >-{{
                (
                  user_data.data.bdl_before +
                  user_data.data.facture_before -
                  (user_data.data.bdl + user_data.data.facture)
                ).toFixed(2)
              }}
              €</span
            >
          </div>
        </div>
        <div
          v-if="show_commercial_detail[indexUD]"
          class="grid grid-cols-2 gap-4"
        >
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Commandes</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.orders }} €
            </div>
          </div>
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Devis</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.quotation }} €
            </div>
          </div>
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Bon de livraison</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.bdl }} €
            </div>
          </div>
          <div class="flex flex-col">
            <div class="mx-auto text-gray-500 text-lg">Facture</div>
            <div class="mx-auto text-xl text-blue-500">
              {{ user_data.data.facture }} €
            </div>
          </div>
        </div>
        <button
          @click="$router.push('/detailCommercial')"
          class="bg-bluevk shadow m-auto rounded-md text-white p-4 text-xl hover:opacity-60"
          v-if="user.team == 'Administration'"
        >
          Voir en détail
        </button>
      </div>
    </div>
  </div>
  <div v-else class="flex flex-col space-y-4">
    <h1 class="text-2xl font-semibold flex justify-center">Statistiques</h1>

    <div class="flex flex-row space-x-2 items-center">
      <button
        @click="(time = 'day'), initStats()"
        class="px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-300 focus:ring-offset-2"
      >
        Jour
      </button>
      <button
        @click="(time = 'week'), initStats()"
        class="px-4 py-2 bg-green-500 text-white font-semibold rounded-lg shadow-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-300 focus:ring-offset-2"
      >
        Semaine
      </button>
      <button
        @click="(time = 'month'), initStats()"
        class="px-4 py-2 bg-purple-500 text-white font-semibold rounded-lg shadow-md hover:bg-purple-600 focus:outline-none focus:ring-2 focus:ring-purple-300 focus:ring-offset-2"
      >
        Mois
      </button>
    </div>
    <div
      class="flex flex-row space-x-8 items-center mx-auto bg-white rounded-xl shadow py-2 px-8 w-fit"
    >
      <div
        v-if="user_statistics.bdl != null && user_statistics.facture != null"
        class="flex flex-row items-center space-x-4"
      >
        <div>
          <span
            v-if="
              user_statistics.bdl + user_statistics.facture >
              user_statistics.bdl_before + user_statistics.facture_before
            "
            class="material-symbols-rounded text-6xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-6xl text-red-500">
            trending_down
          </span>
        </div>
        <div>
          <span
            :class="[
              'font-bold text-7xl',
              user_statistics.bdl + user_statistics.facture >
              user_statistics.bdl_before + user_statistics.facture_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{
              (user_statistics.bdl + user_statistics.facture).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>

      <div
        v-if="
          user_statistics.bdl != null &&
          user_statistics.facture != null &&
          global_statistics.bdl != null &&
          global_statistics.facture != null
        "
        class="flex flex-col space-y-1 text-gray-500 text-lg"
      >
        <div>
          <span class="underline font-bold"
            >{{
              (
                ((user_statistics.bdl + user_statistics.facture) /
                  (global_statistics.bdl + global_statistics.facture)) *
                100
              ).toFixed(2)
            }}
            %</span
          >
          de {{ global_statistics.bdl + global_statistics.facture }} € en global
        </div>
        <div
          v-if="
            user_statistics.bdl + user_statistics.facture >
            user_statistics.bdl_before + user_statistics.facture_before
          "
        >
          <span class="underline font-bold"
            >{{
              (
                user_statistics.bdl +
                user_statistics.facture -
                (user_statistics.bdl_before + user_statistics.facture_before)
              ).toFixed(2)
            }}
            €</span
          >
          de plus que <span v-if="time == 'day'"> hier</span
          ><span v-else-if="time == 'week'">la semaine dernière</span
          ><span v-else>le mois dernier</span>
        </div>
        <div v-else>
          plus que
          <span class="underline font-bold"
            >{{
              (
                user_statistics.bdl_before +
                user_statistics.facture_before -
                (user_statistics.bdl + user_statistics.facture)
              ).toFixed(2)
            }}
            € </span
          >pour faire mieux que <span v-if="time == 'day'"> hier</span
          ><span v-else-if="time == 'week'">la semaine dernière</span
          ><span v-else>le mois dernier</span>
        </div>
      </div>
    </div>
    <div class="text-2xl font-bold">Tes chiffres au comptoir :</div>
    <div class="grid grid-cols-4 gap-4">
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            inactive_order
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl"
            >Commandes en attentes</span
          >
          <span
            v-if="user_statistics.orders != null"
            class="font-bold text-blue-500 text-5xl"
            >{{ user_statistics.orders }} €</span
          >
        </div>
        <div>
          <span class="material-symbols-rounded text-4xl text-orange-500">
            hourglass_empty
          </span>
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            edit_document
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl"
            >Devis à transformer</span
          >
          <span
            v-if="user_statistics.quotation != null"
            class="font-bold text-blue-500 text-5xl"
            >{{ user_statistics.quotation }} €</span
          >
        </div>
        <div>
          <span class="material-symbols-rounded text-4xl text-orange-500">
            shopping_bag_speed
          </span>
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            package_2
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl">Bons de livraison</span>
          <span
            v-if="user_statistics.bdl != null"
            :class="[
              'font-bold text-5xl',
              user_statistics.bdl > user_statistics.bdl_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{ user_statistics.bdl }} €</span
          >
        </div>
        <div
          v-if="
            user_statistics.bdl != null && user_statistics.bdl_before != null
          "
          class="flex flex-col"
        >
          <span
            v-if="user_statistics.bdl > user_statistics.bdl_before"
            class="material-symbols-rounded text-4xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-4xl text-red-500">
            trending_down
          </span>
          <span
            v-if="user_statistics.bdl > user_statistics.bdl_before"
            class="text-green-500"
            >+{{
              (user_statistics.bdl - user_statistics.bdl_before).toFixed(2)
            }}
            €</span
          >
          <span v-else class="text-red-500"
            >-{{
              (user_statistics.bdl_before - user_statistics.bdl).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            credit_score
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl">Factures</span>
          <span
            v-if="user_statistics.facture != null"
            :class="[
              'font-bold text-5xl',
              user_statistics.facture > user_statistics.facture_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{ user_statistics.facture }} €</span
          >
        </div>
        <div
          v-if="
            user_statistics.facture != null &&
            user_statistics.facture_before != null
          "
          class="flex flex-col"
        >
          <span
            v-if="user_statistics.facture > user_statistics.facture_before"
            class="material-symbols-rounded text-4xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-4xl text-red-500">
            trending_down
          </span>
          <span
            v-if="user_statistics.facture > user_statistics.facture_before"
            class="text-green-500"
            >+{{
              (
                user_statistics.facture - user_statistics.facture_before
              ).toFixed(2)
            }}
            €</span
          >
          <span v-else class="text-red-500"
            >-{{
              (
                user_statistics.facture_before - user_statistics.facture
              ).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
    </div>
    <div class="text-2xl font-bold" v-if="user.team == 'Commercial'">
      Les chiffres de tes clients :
    </div>
    <div class="grid grid-cols-4 gap-4" v-if="user.team == 'Commercial'">
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            inactive_order
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl"
            >Commandes en attentes</span
          >
          <span
            v-if="commercial_statistics.orders != null"
            class="font-bold text-blue-500 text-5xl"
            >{{ commercial_statistics.orders }} €</span
          >
        </div>
        <div>
          <span class="material-symbols-rounded text-4xl text-orange-500">
            hourglass_empty
          </span>
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            edit_document
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl"
            >Devis à transformer</span
          >
          <span
            v-if="commercial_statistics.quotation != null"
            class="font-bold text-blue-500 text-5xl"
            >{{ commercial_statistics.quotation }} €</span
          >
        </div>
        <div>
          <span class="material-symbols-rounded text-4xl text-orange-500">
            shopping_bag_speed
          </span>
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            package_2
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl">Bons de livraison</span>
          <span
            v-if="commercial_statistics.bdl != null"
            :class="[
              'font-bold text-5xl',
              commercial_statistics.bdl > commercial_statistics.bdl_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{ commercial_statistics.bdl }} €</span
          >
        </div>
        <div
          v-if="
            commercial_statistics.bdl != null &&
            commercial_statistics.bdl_before != null
          "
          class="flex flex-col"
        >
          <span
            v-if="commercial_statistics.bdl > commercial_statistics.bdl_before"
            class="material-symbols-rounded text-4xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-4xl text-red-500">
            trending_down
          </span>
          <span
            v-if="commercial_statistics.bdl > commercial_statistics.bdl_before"
            class="text-green-500"
            >+{{
              (
                commercial_statistics.bdl - commercial_statistics.bdl_before
              ).toFixed(2)
            }}
            €</span
          >
          <span v-else class="text-red-500"
            >-{{
              (
                commercial_statistics.bdl_before - commercial_statistics.bdl
              ).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
      <div class="p-2 flex flex-row justify-between bg-white rounded shadow">
        <div>
          <span class="material-symbols-rounded text-5xl text-blue-500">
            credit_score
          </span>
        </div>
        <div class="flex flex-col space-y-1">
          <span class="font-bold text-gray-500 text-xl">Factures</span>
          <span
            v-if="commercial_statistics.facture != null"
            :class="[
              'font-bold text-5xl',
              commercial_statistics.facture >
              commercial_statistics.facture_before
                ? 'text-green-500'
                : 'text-blue-500',
            ]"
            >{{ commercial_statistics.facture }} €</span
          >
        </div>
        <div
          v-if="
            commercial_statistics.facture != null &&
            commercial_statistics.facture_before != null
          "
          class="flex flex-col"
        >
          <span
            v-if="
              commercial_statistics.facture >
              commercial_statistics.facture_before
            "
            class="material-symbols-rounded text-4xl text-green-500"
          >
            trending_up
          </span>
          <span v-else class="material-symbols-rounded text-4xl text-red-500">
            trending_down
          </span>
          <span
            v-if="
              commercial_statistics.facture >
              commercial_statistics.facture_before
            "
            class="text-green-500"
            >+{{
              (
                commercial_statistics.facture -
                commercial_statistics.facture_before
              ).toFixed(2)
            }}
            €</span
          >
          <span v-else class="text-red-500"
            >-{{
              (
                commercial_statistics.facture_before -
                commercial_statistics.facture
              ).toFixed(2)
            }}
            €</span
          >
        </div>
      </div>
    </div>

    <button
      @click="$router.push('/detailCommercial')"
      class="bg-bluevk shadow m-auto rounded-md text-white p-4 text-xl hover:opacity-60"
      v-if="user.team == 'Commercial'"
    >
      Voir en détail
    </button>
  </div>
</template>
<script>
import axios from "axios";
import { mapGetters } from "vuex";
import titrePages from "@/components/Text/grandeTitre.vue";
export default {
  name: "StatisticsPage",
  data() {
    return {
      time: "day",
      global_statistics: {},
      global_user_statistics: [],
      global_commercial_statistics: [],
      user_statistics: {},
      commercial_statistics: {},
      show_user_detail: [],
      show_commercial_detail: [],
    };
  },
  components: {
    titrePages,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    async getGlobalStatistics() {
      this.global_statistics = {};
      this.global_user_statistics = [];
      this.global_commercial_statistics = [];
      let request = await axios.post(
        `${process.env.VUE_APP_API}/venteStat/global`,
        {
          plateform: this.user.proprietaire,
          time: this.time,
        }
      );
      this.global_statistics = request.data;
      let user_request = await axios.get(
        `${process.env.VUE_APP_API}/user/getPlateformUsers/${this.user.proprietaire}`
      );
      for (const us of user_request.data) {
        let user_stat = await axios.post(
          `${process.env.VUE_APP_API}/venteStat/globalByUser`,
          {
            plateform: this.user.proprietaire,
            user: us.username,
            time: this.time,
          }
        );
        this.global_user_statistics.push({
          user: us.username,
          data: user_stat.data,
        });
        if (us.team == "Commercial") {
          let user_stat = await axios.post(
            `${process.env.VUE_APP_API}/venteStat/globalByCommercial`,
            {
              plateform: this.user.proprietaire,
              user: us.username,
              time: this.time,
            }
          );
          this.global_commercial_statistics.push({
            user: us.username,
            data: user_stat.data,
          });
        }
      }
    },
    async getStatisticsByUser() {
      this.global_statistics = {};
      this.user_statistics = {};
      this.commercial_statistics = {};
      let request1 = await axios.post(
        `${process.env.VUE_APP_API}/venteStat/global`,
        {
          plateform: this.user.proprietaire,
          time: this.time,
        }
      );
      this.global_statistics = request1.data;

      let request = await axios.post(
        `${process.env.VUE_APP_API}/venteStat/globalByUser`,
        {
          plateform: this.user.proprietaire,
          user: this.user.username,
          time: this.time,
        }
      );
      this.user_statistics = request.data;

      if (this.user.team == "Commercial") {
        let commercial_request = await axios.post(
          `${process.env.VUE_APP_API}/venteStat/globalByCommercial`,
          {
            plateform: this.user.proprietaire,
            user: this.user.username,
            time: this.time,
          }
        );

        this.commercial_statistics = commercial_request.data;
      }
    },
    initStats() {
      if (this.user.accountType == "Administrateur") {
        this.getGlobalStatistics();
      } else {
        this.getStatisticsByUser();
      }
    },
  },
  beforeMount() {
    this.initStats();
  },
};
</script>
<style></style>
