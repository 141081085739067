<template>
  <div class="w-36 flex flex-col">
    <div
      class="shadow-lg bg-gray-100 border-2 rounded-full h-6 overflow-hidden relative"
    >
      <!-- Barre de progression -->
      <div
        class="h-full transition-all duration-700 ease-in-out"
        :style="{ width: animatedProgress + '%' }"
        :class="progressColorClass"
      ></div>

      <!-- Statut centré -->
      <p
        class="absolute inset-0 flex items-center justify-center font-semibold"
      >
        {{ progress }}%
      </p>
    </div>

    <!-- Texte de statut sous la barre -->
    <p class="text-center mt-2 font-medium text-gray-700">
      {{ translatedStatus }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      animatedProgress: 0, // Démarre toujours à 0
      statut: [
        { french: "Brouillon", english: "CREATED" },
        { french: "Validée", english: "CONFIRMED" },
        { french: "En attente", english: "PENDING" },
        { french: "En attente de réception", english: "IN_DELIVERY" },
        { french: "Réceptionnée", english: "RECEIVED" },
      ],
      colorStages: [
        { threshold: 10, color: "bg-sky-600" },
        { threshold: 33, color: "bg-teal-500" },
        { threshold: 66, color: "bg-green-500" },
        { threshold: 100, color: "bg-green-600" },
      ],
    };
  },
  props: {
    progress: {
      type: Number,
      required: true,
      validator: (value) => value >= 0 && value <= 100,
    },
    status: {
      type: String,
      default: "En cours...",
    },
  },
  computed: {
    translatedStatus() {
      const foundStatus = this.statut.find((s) => s.english === this.status);
      return foundStatus ? foundStatus.french : this.status;
    },
    progressColorClass() {
      let lastStage = this.colorStages[0].color;
      for (let stage of this.colorStages) {
        if (this.animatedProgress >= stage.threshold) {
          lastStage = stage.color;
        }
      }
      return lastStage;
    },
  },
  watch: {
    progress: {
      immediate: true,
      handler(newValue) {
        this.animatedProgress = 0; // Reset à 0 avant animation
        setTimeout(() => {
          this.animatedProgress = newValue; // Anime jusqu'à la nouvelle valeur
        }, 100); // Petit délai pour éviter un effet brusque
      },
    },
  },
};
</script>
