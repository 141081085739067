<template>
  <!-- commercial -->
  <select
    @change="getAllBdlByClient()"
    v-model="commercial"
    class="focus:outline-2 outline-sky-300 w-fit p-2 border-2 rounded-md focus:border-blue-600 focus:ring focus:ring-opacity-40 focus:ring-blue-500"
  >
    <option
      v-for="(select, indexSelect) in commerciaux"
      :key="indexSelect"
      :value="select.prenom + ' ' + select.nom"
    >
      {{ select.prenom }} {{ select.nom }}
    </option>
  </select>

  <!-- tableau -->
  <div class="mt-4">
    <div
      class="mt-4 py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
    >
      <div
        class="inline-block min-w-full overflow-hidden align-middle border border-gray-200 shadow sm:rounded-lg"
      >
        <table class="relative min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Commercial
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Total PA
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Total HT
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Total TTC
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Total Marge €
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Total Marge %
              </th>
            </tr>
          </thead>

          <div
            v-show="loadingSearch"
            class="absolute flex justify-center w-full bg-white items-center space-x-2"
          >
            <span>En cours de chargement</span>
            <PulseLoader color="#2d74da" />
          </div>

          <thead>
            <tr>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider text-left text-bluevk text-xl uppercase border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                class="px-6 py-3 font-medium leading-4 tracking-wider text-left text-bluevk text-xl uppercase border-b border-gray-200 bg-gray-50"
              ></th>
              <th
                v-if="tabStats.totalAllBdl"
                class="px-6 py-3 font-medium leading-4 tracking-wider text-left text-bluevk text-xl uppercase border-b border-gray-200 bg-gray-50"
              >
                {{ parseFloat(tabStats.totalAllBdl.totalPA).toFixed(2) }} €
              </th>
              <th
                v-if="tabStats.totalAllBdl"
                class="px-6 py-3 font-medium leading-4 tracking-wider text-left text-bluevk text-xl uppercase border-b border-gray-200 bg-gray-50"
              >
                {{ parseFloat(tabStats.totalAllBdl.TotalPrice).toFixed(2) }} €
              </th>
              <th
                v-if="tabStats.totalAllBdl"
                class="px-6 py-3 font-medium leading-4 tracking-wider text-left text-bluevk text-xl uppercase border-b border-gray-200 bg-gray-50"
              >
                {{ parseFloat(tabStats.totalAllBdl.TotalPriceTTC).toFixed(2) }}
                €
              </th>
              <th
                v-if="tabStats.totalAllBdl"
                class="px-6 py-3 font-medium leading-4 tracking-wider text-left text-bluevk text-xl uppercase border-b border-gray-200 bg-gray-50"
              >
                {{ parseFloat(tabStats.totalAllBdl.TotalMarge).toFixed(2) }} €
              </th>
              <th
                v-if="tabStats.totalAllBdl"
                class="px-6 py-3 font-medium leading-4 tracking-wider text-left text-bluevk text-xl uppercase border-b border-gray-200 bg-gray-50"
              >
                {{
                  parseFloat(
                    tabStats.totalAllBdl.TotalMargePourcentage
                  ).toFixed(2)
                }}
                %
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr v-for="(u, index) in tabStats.totalByClient" :key="index">
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ u._id }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ u.commercial }}
              </td>

              <td
                class="text-sm px-6 py-4 border-b border-gray-200 whitespace-nowrap"
              >
                {{ parseFloat(u.totalPA).toFixed(2) }} €
                <!-- /
              {{ parseFloat(u.totalPV).toFixed(2) }} € -->
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ parseFloat(u.TotalPrice).toFixed(2) }} €
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ parseFloat(u.TotalMarge).toFixed(2) }} €
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ parseFloat(u.TotalMargePourcentage).toFixed(2) }} %
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>

  <!-- graphs -->
  <!-- <div class="flex space-x-12">
    <div class="flex flex-col">
      <h3 class="flex justify-center font-medium">
        Chiffre d'affaires du client (par mois)
      </h3>
      <Chart
        type="bar"
        :data="chartData"
        :options="chartOptions"
        class="h-[20rem] flex justify-center"
      />
    </div>
    <div v-if="totalEuros" class="flex flex-col my-auto">
      <p v-if="showTurnover">
        Total HT : {{ parseFloat(totalEuros.totalAllHT).toFixed(2) }} €
      </p>
      <p v-if="showTurnover">
        Total TTC : {{ parseFloat(totalEuros.totalAllTTC).toFixed(2) }} €
      </p>
      <p v-if="showMarge">
        Total Marge : {{ parseFloat(totalEuros.totalAllMarge).toFixed(2) }} €
      </p>
    </div>
  </div> -->
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { getFrDate, getDateToISO } from "@/hooks/tools/date.ts";
// import Chart from "primevue/chart";
import { getCommerciaux } from "@/hooks/commercial/commercial";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";

export default {
  data() {
    return {
      Clients: [],
      client: null,
      clientID: null,
      archived: false,
      active: true,
      fdm: null,
      dateDebut: null,
      dateFin: null,

      commerciaux: [],
      commercial: null,

      tabStats: {},
      tabExcel: [],
      tabLength: 0,

      // graphs
      totalEuros: null,
      showMarge: true,
      showTurnover: true,
      chartData: null,
      chartOptions: {
        responsive: true,
        plugins: {
          legend: {
            position: "top",
            onClick: (e, legendItem, legend) => this.toggleDataset(legendItem),
          },
          tooltip: {
            mode: "index",
            intersect: false,
          },
        },
        scales: {
          x: {
            stacked: true, // Empiler les datasets sur l'axe X
          },
          y: {
            stacked: true, // Empiler les datasets sur l'axe Y
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters(["user"]),
  },
  components: {
    // Chart,
    PulseLoader,
  },
  methods: {
    getFRDate(date) {
      return getFrDate(date);
    },
    getDateISO(date) {
      return getDateToISO(date);
    },
    async getAllBdlByClient() {
      if (this.commercial) {
        this.tabStats = {};
        this.tabLength = 0;
        this.tabExcel = [];
        this.loadingSearch = true;
        await axios
          .post(`${process.env.VUE_APP_API}/venteStat/getAllBdlByClient`, {
            plateform: this.user.proprietaire,
            client: this.client,
            commercial: this.commercial,
            archived: this.archived,
            active: this.active,
            fdm: this.fdm,
            dateDebut: this.dateDebut,
            dateFin: this.dateFin,
            skip: null,
            limit: null,
          })
          .then((response) => {
            this.tabStats = response.data;
            this.tabLength = response.data.totalByClientLength;
            this.tabExcel = response.data.totalExcel;
            this.loadingSearch = false;
          })
          .catch((error) => {
            console.log(error);
            this.loadingSearch = false;
          });
      }
    },
    // getSalesOfMonth() {
    //   axios
    //     .post(`${process.env.VUE_APP_API}/venteStat/getSalesByMonth`, {
    //       plateform: this.user.proprietaire,
    //       commercial: this.commercial,
    //       client: null,
    //       dateDebut: null,
    //       dateFin: null,
    //     })
    //     .then((response) => {
    //       console.log(response);
    //       this.chartData = response.data.chartData;
    //       this.totalEuros = response.data.total;
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //     });
    // },
    toggleDataset(legendItem) {
      const datasetIndex = this.chartData.datasets.findIndex(
        (dataset) => dataset.label === legendItem.text
      );

      if (datasetIndex !== -1) {
        const dataset = this.chartData.datasets[datasetIndex];

        if (legendItem.text === "Bénéfices (€)") {
          this.showMarge = !this.showMarge;
          dataset.hidden = !this.showMarge;
        }

        if (legendItem.text === "Chiffre d'affaires (€)") {
          this.showTurnover = !this.showTurnover;
          dataset.hidden = !this.showTurnover;
        }
      }
    },
    async getCommercial() {
      if (this.user.team == "Administration") {
        this.commerciaux = await getCommerciaux(this.user.proprietaire);
        this.commercial =
          this.commerciaux[0]?.prenom + " " + this.commerciaux[0]?.nom;
      } else if (this.user.team == "Commercial") {
        this.commerciaux.push({ prenom: this.user.prenom, nom: this.user.nom });
        this.commercial = this.user.prenom + " " + this.user.nom;
      }
    },
    formatPrice(price) {
      if (!price) return "";
      let priceString = parseFloat(price).toFixed(2).toString();
      let [integerPart, decimalPart] = priceString.split(".");
      integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return `${integerPart}.${decimalPart}`;
    },
  },
  async mounted() {
    await this.getCommercial();
    this.getAllBdlByClient();
  },
};
</script>
