<template>
  <addClientParticulier
    v-if="showClientPar"
    :clientInput="newInputClient"
    @close="showClientPar ? (showClientPar = false) : ''"
    @valid="
      showClientPar
        ? ((showClientPar = false), updateSessionClient($event))
        : (showClientPar = false)
    "
  />

  <modifierClientParticulier
    v-if="showEditClientPar"
    :clientSelectedToModify="clientSelectedToModify"
    @close="(showEditClientPar = false), getFavori(false)"
  />

  <!-- modal historique  -->
  <div
    v-if="historiqueShow"
    :class="`modal ${
      !historiqueShow && 'opacity-0 pointer-events-none'
    } z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="historiqueShow = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 78rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Historique</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="historiqueShow = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex flex-row mt-3">
          <label>
            <input
              type="checkbox"
              class="w-5 h-5 text-blue-600 rounded-md focus:outline-2 outline-sky-300"
              name="radio"
              v-model="checkboxDevis"
              @change="filterHistoriqueFile('Devis', checkboxDevis)"
            /><span class="ml-2 text-gray-700">Devis</span>
          </label>
          <label>
            <input
              type="checkbox"
              class="w-5 ml-5 h-5 text-blue-600 rounded-md focus:outline-2 outline-sky-300"
              name="radio"
              v-model="checkboxFacturation"
              @change="filterHistoriqueFile('Facturation', checkboxFacturation)"
            /><span class="ml-2 text-gray-700">Facturation</span>
          </label>
        </div>
        <div
          class="py-2 -my-2 mt-3 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          style="height: 30vh"
        >
          <div
            class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
          >
            <table class="min-w-full">
              <thead>
                <tr
                  v-for="(historiqueFile, index) in historique"
                  :key="index"
                  @click="
                    setHistoriqueFile(historiqueFile.Articles),
                      (historiqueShow = false)
                  "
                  style="cursor: pointer"
                  class="hover:bg-blue-50"
                >
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    {{ historiqueFile.Type }} N° {{ historiqueFile.Numero }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Date : {{ getFRDate(historiqueFile.Date) }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Client : {{ historiqueFile.Client }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Crée par : {{ historiqueFile.Name }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Prix Total : {{ financial(historiqueFile.TotalPrice) }} €
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Desc : {{ historiqueFile.Note }}
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                  >
                    Validation :
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                      v-if="historiqueFile.IsProcessed === true"
                    >
                      <span class="material-icons-outlined"> done </span></span
                    >
                    <span
                      class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                      v-else
                    >
                      <span class="material-icons-outlined">close</span>
                    </span>
                  </th>
                  <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                </tr>
              </thead>
            </table>
          </div>
        </div>

        <!--Footer-->
        <div class="flex justify-end pt-2">
          <button
            @click="historiqueShow = false"
            class="cursor-pointer p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal historique -->

  <!--  DETAIL MODAL -->
  <div
    class="z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center"
    v-if="showDetail"
  >
    <div
      class="absolute w-screen h-screen top-0 inset-0 bg-black opacity-50"
      @click="CloseShowDetail()"
    ></div>
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-4xl relative z-10">
      <div class="flex items-center justify-between pb-3">
        <p class="text-lg font-medium">Panier</p>
        <div class="cursor-pointer" @click="CloseShowDetail()">
          <svg
            class="text-black fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
        </div>
      </div>
      <div class="overflow-y-auto max-h-[25rem]">
        <table class="min-w-full mt-3">
          <thead class="bg-gray-50 tableHEADTR">
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Réf.
              </th>
              <th
                class="px-6 py-3 w-[24rem] max-w-24 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Description
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Qté
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                P.V.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Remise
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Px final
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-r border-gray-200 bg-gray-50 tableTDTH"
              >
                Total HT
              </th>
            </tr>
          </thead>
          <tbody class="bg-white">
            <tr
              v-for="(art, ind) in detailTab.actionArticle"
              :key="ind"
              class="tableHEADTR"
            >
              <!-- ref -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p>{{ art.Ref_fournisseur }}</p>
              </td>

              <!-- desc -->
              <td
                class="px-6 max-w-24 w-[24rem] border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p
                  class="overflow-y-auto text-sm leading-5 whitespace-normal w-[24rem] max-w-24"
                >
                  {{ art.Description }}
                </p>
              </td>

              <!-- qté -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p>{{ art.quantity }}</p>
              </td>

              <!-- p.v -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p>{{ financial(art.Prix_euro) }} €</p>
              </td>

              <!-- remise -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <p v-if="art.PrixFournisseurType != 'prixnet'">
                  {{ art.remise }} %
                </p>
                <p v-else>prix net</p>
              </td>

              <!-- px final -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <span v-if="art.PrixFournisseurType != 'prixnet'"
                  >{{
                    financial(
                      art.Prix_euro - art.Prix_euro * (art.remise / 100)
                    )
                  }}
                  €
                </span>
                <span v-else>
                  <span> {{ art.Prix_vente }} €</span>
                </span>
              </td>

              <!-- total ht -->
              <td
                class="px-6 border-b border-r border-gray-200 whitespace-nowrap tableTDTH"
              >
                <span v-if="art.PrixFournisseurType != 'prixnet'">
                  {{
                    financial(
                      (art.Prix_euro - art.Prix_euro * (art.remise / 100)) *
                        art.quantity *
                        0.2 +
                        (art.Prix_euro - art.Prix_euro * (art.remise / 100)) *
                          art.quantity
                    )
                  }}
                  €
                </span>
                <span v-else>
                  {{
                    financial(
                      parseFloat(art.Prix_vente) * art.quantity * 0.2 +
                        parseFloat(art.Prix_vente) * art.quantity
                    )
                  }}
                  €
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="pt-4">
        <div class="flex justify-end space-x-6">
          <div class="text-sm">
            <div class="flex space-x-2">
              <p>Total HT :</p>
              <p>{{ financial(parseFloat(setTotal)) }}</p>
            </div>
            <div class="flex space-x-2">
              <p>Total TTC :</p>
              <p>{{ financial(parseFloat(setTotalTTC)) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- FIN DETAIL MODAL -->

  <!-- Confirmation Modal -->
  <div
    v-if="showConfirmationModal"
    class="z-50 fixed w-screen h-screen top-0 left-0 flex items-center justify-center bg-gray-900 bg-opacity-50"
  >
    <!-- Modal Overlay -->
    <div
      @click="cancelGroupChange"
      class="absolute w-screen h-screen bg-transparent modal-overlay"
    ></div>

    <!-- Modal Container -->
    <div
      class="relative bg-gray-50 border-2 border-sky-200 rounded-md shadow-md p-6 w-[20rem] z-40"
    >
      <!-- Close Button -->
      <div
        class="absolute top-2 right-2 flex flex-col items-center text-sm text-gray-600 cursor-pointer"
        @click="cancelGroupChange"
      >
        <svg
          class="fill-current text-gray-600 hover:text-gray-800"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </div>

      <!-- Modal Content -->
      <div class="text-center">
        <p class="text-lg font-bold text-gray-800">
          Etes-vous sûr de vouloir continuer ?
        </p>
        <div class="flex justify-center space-x-4 mt-4">
          <!-- Confirm Button -->
          <button
            @click="saveModifierGroupClient()"
            class="bg-green-600 hover:bg-green-400 shadow-lg text-white px-4 py-1 rounded-lg"
          >
            Oui
          </button>
          <!-- Cancel Button -->
          <button
            @click="cancelGroupChange"
            class="bg-red-600 hover:bg-red-400 shadow-lg text-white px-4 py-1 rounded-lg"
          >
            Non
          </button>
        </div>
      </div>
    </div>
  </div>

  <!-- MODAL SUPPRIMER CLIENT PARTICULIER -->
  <div
    v-if="showDeleteClientPar"
    :class="`modal ${
      !showDeleteClientPar && 'opacity-0 pointer-events-none'
    } z-40 fixed w-screen h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showDeleteClientPar = false"
      class="absolute w-screen h-screen bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-40 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-40 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        @click="showDeleteClientPar = false"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left h-full modal-content">
        <!--Title-->
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold"></p> -->
          <div
            class="z-40 cursor-pointer modal-close"
            @click="showDeleteClientPar = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->
        <div class="flex flex-col space-y-4">
          <p class="font-medium text-xl">Supprimer définitivement ?</p>
          <div class="flex space-x-4 justify-center">
            <button
              class="shadow w-fit px-2 py-1 h-fit text-red-600 rounded-md bg-gray-100 hover:text-white hover:bg-red-300"
              @click="confirmDelete()"
            >
              Oui
            </button>
            <button
              class="shadow w-fit px-2 py-1 h-fit rounded-md hover:bg-gray-100"
              @click="showDeleteClientPar = false"
            >
              Non
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SESSION NON ACTIVE -->
  <div
    v-show="showOutilsSession"
    @click="showOutilsSession = !showOutilsSession"
    class="absolute w-screen h-screen z-20 top-0 left-0"
  ></div>

  <!-- BOUTONS NOUVELLES SESSIONS PRO ET PART -->
  <div class="flex flex-col justify-start mr-2">
    <button
      class="w-full px-4 text-xs font-medium shadow-md py-1"
      :class="
        comptoirSessionActive &&
        comptoirSessionActive.length > 0 &&
        comptoirSessionActive[0].Client &&
        comptoirSessionActive[0].Client.length > 0 &&
        comptoirSessionActive[0].Client[0].professionnel
          ? 'bg-cyan-600 border-double border-4 text-white border-orange-600 rounded-t-md'
          : 'bg-gray-300 border-[1.5px] border-cyan-600 text-cyan-600 rounded-t-md'
      "
      @click="(showNewInput = true), (cdtnClient = true), getFavori(true)"
    >
      Pro
    </button>
    <button
      class="w-full px-4 text-xs font-medium shadow-md py-1 mt-0.5"
      :class="
        comptoirSessionActive &&
        comptoirSessionActive.length > 0 &&
        comptoirSessionActive[0].Client &&
        comptoirSessionActive[0].Client.length > 0 &&
        !comptoirSessionActive[0].Client[0].professionnel
          ? 'bg-cyan-600 border-double border-4 text-white border-orange-600 rounded-b-md'
          : 'bg-slate-300 border-[1.5px] border-cyan-600 text-cyan-600 rounded-b-md'
      "
      @click="(showNewInput = true), (cdtnClient = false), getFavori(false)"
    >
      Par
    </button>
  </div>

  <!-- BOUTONS OUTILS SESSIONS -->
  <div
    v-if="
      comptoirSessionActive &&
      comptoirSessionActive.length > 0 &&
      comptoirSessionActive[0].Client &&
      comptoirSessionActive[0].Client.length > 0 &&
      !comptoirSessionActive[0].Client[0]._id.startsWith('inconnu')
    "
    class="flex mr-2 mt-2"
  >
    <div v-if="comptoirSession.length >= 1" class="flex flex-col">
      <button
        @click="chooseClient()"
        title="Info Client"
        class="material-icons-outlined text-cyan-600 text-md"
        style="font-size: 18px"
      >
        info
      </button>
      <button
        @click="getHistorique(), (historiqueShow = true)"
        title="Historique Client"
        class="material-icons-outlined text-cyan-600 text-md"
        style="font-size: 18px"
      >
        person_search
      </button>
    </div>
    <div
      v-if="
        comptoirSessionActive.length > 0 &&
        comptoirSessionActive[0].Client.length > 0 &&
        !comptoirSessionActive[0].Client[0].professionnel
      "
    >
      <button
        @click="
          resetClientFields();
          showClientPar = true;
        "
        title="Ajouter un client"
        class="material-icons-outlined text-cyan-600 ml-2 text-md"
        style="font-size: 18px"
      >
        person_add
      </button>
    </div>
  </div>

  <div
    v-if="showSessionTab"
    @click="closeInputClient()"
    class="absolute w-screen h-screen z-20 top-0 left-0"
  ></div>
  <div
    v-show="showNewInput"
    @click="showNewInput = false"
    class="absolute w-screen h-screen z-20 top-0 left-0"
  ></div>

  <div class="mr-5">
    <div class="flex relative bg-opacity-50 rounded-md py-1 px-0.5 h-fit">
      <!-- SESSION ACTIVE -->
      <div
        v-show="
          comptoirSessionActive &&
          comptoirSessionActive.length > 0 &&
          comptoirSessionActive[0].Client &&
          comptoirSessionActive[0].Client.length > 0
        "
        v-for="(session, index) in comptoirSessionActive"
        :key="index"
      >
        <div class="flex">
          <div class="flex flex-col" v-if="session.actif">
            <div class="flex space-x-2">
              <!-- NOM CLIENT DE LA SESSION -->
              <button
                :title="session.Client[0].Name"
                v-if="session.Client[0].Name"
                @click="clientPush()"
                style="height: 36px; width: auto"
                class="my-auto bg-cyan-600 border-double border-4 relative px-1 hover:bg-cyan-700 text-white shadow rounded-md cursor-pointer z-10 border-orange-600 text-sm 2xl:text-base"
              >
                <div v-if="session.Client.length > 0">
                  <div class="xs:block lg:hidden">
                    {{ session.Client[0].Name.substr(0, 6) }}..
                  </div>
                  <div class="hidden lg:block">
                    {{ session.Client[0].Name.substr(0, 25) }}
                  </div>
                </div>
                <div
                  v-if="comptoirSessionActive.length > 0"
                  @click="removeSession()"
                  class="absolute top-0 right-0 -my-4 -mr-3 text-gray-700 z-30"
                >
                  <span
                    title="Supprimer"
                    style="font-size: 16px"
                    class="material-icons-outlined cursor-pointer rounded-full bg-white hover:bg-gray-300 text-red-700 border hover:from-transparent"
                    >cancel</span
                  >
                </div>
              </button>

              <!-- GROUPE DE REMISE POUR DIVERS -->
              <div
                v-if="
                  comptoirSessionActive[0].Client[0]?._id.startsWith('inconnu')
                "
                class="my-auto border focus:outline-2 outline-sky-300 rounded-md shadow"
              >
                <select
                  v-model="comptoirSessionActive[0].Client[0].Group.Name"
                  @change="saveGroupClient(comptoirSessionActive[0])"
                  class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-transparent rounded-md"
                >
                  <option
                    :value="cond.Name"
                    v-for="(cond, indexC) in configurationConditionList"
                    :key="indexC"
                  >
                    {{ cond.Name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <!-- Bouton pour modifier la session -->
          <button
            class="ml-2"
            title="Changer de client"
            v-if="session.actif"
            @click="
              (showSessionTab = true),
                (modifPro = comptoirSessionActive[0].Client[0].professionnel),
                getFavori(comptoirSessionActive[0].Client[0].professionnel)
            "
          >
            <span class="material-icons-outlined text-cyan-600 text-md">
              edit
            </span>
          </button>
        </div>
      </div>

      <!-- MODIFIER LA SESSION -->
      <div v-if="showSessionTab" class="absolute z-20 flex mt-11">
        <div
          class="absolute z-20 bg-white flex space-x-2 shadow-lg rounded-md p-1 border-4 border-sky-300"
        >
          <div class="flex flex-col bg-white">
            <button
              @click="(inputClient = ''), (modifPro = true), getFavori(true)"
              :class="
                modifPro
                  ? 'bg-cyan-600 border-double border-4 border-orange-600 text-white rounded-b-md'
                  : 'bg-slate-300 border-[1.5px] border-cyan-600 text-cyan-600 rounded-b-md '
              "
              class="px-1 text-center shadow rounded-md cursor-pointer z-20"
              style="height: 30px; max-height: 30px"
            >
              Pro
            </button>
            <button
              @click="(inputClient = ''), (modifPro = false), getFavori(false)"
              :class="
                modifPro
                  ? 'bg-slate-300 border-[1.5px] border-cyan-600 text-cyan-600 rounded-b-md '
                  : 'bg-cyan-600 border-double border-4 border-orange-600 text-white rounded-b-md'
              "
              class="px-1 text-center shadow rounded-md cursor-pointer z-20 mt-1"
              style="height: 30px; max-height: 30px"
            >
              Par
            </button>
          </div>

          <div
            :class="[
              modifPro
                ? 'grid grid-cols-[25rem_25rem]'
                : 'grid grid-cols-[25rem_25rem]',
              'gap-x-4',
            ]"
          >
            <div class="w-[50rem] h-[50rem] flex flex-col space-y-2">
              <div class="relative w-full">
                <input
                  list="brow1"
                  @input="searchClientFav(inputClient, modifPro)"
                  @focus="inputClient = ''"
                  v-model="inputClient"
                  @keydown.enter="selectFirstClient('update')"
                  placeholder="Modifier la session"
                  class="my-1 w-full h-10 pl-1 pr-12 text-black border-2 border-gray-500 rounded-md focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 hover:bg-white focus:outline-2 outline-sky-300"
                />
                <button
                  v-if="!modifPro"
                  class="absolute text-xs font-bold right-2 top-1/2 transform -translate-y-1/2 bg-bluevk text-white px-2 h-8 rounded-md shadow-md hover:bg-blue-500 focus:outline-none"
                  @click="showNewClient = true"
                >
                  Nouveau
                </button>
                <addClientParticulier
                  v-if="showNewClient"
                  :clientInput="inputClient"
                  @close="showNewClient ? (showNewClient = false) : ''"
                  @valid="
                    showNewClient
                      ? ((newInputClient = $event.Name),
                        (showNewClient = false),
                        handleClientClick($event))
                      : ''
                  "
                />
              </div>

              <div
                class="w-[50rem] rounded-md p-1 flex flex-col h-[60rem] max-h-[60rem] overflow-y-auto border border-gray-300"
              >
                <div
                  v-show="Clients.length > 0"
                  class="cursor-pointer rounded-sm hover:bg-gray-200 flex items-center justify-between p-2"
                  @click="handleClientClick(cl)"
                  v-for="(cl, indexCL) in Clients"
                  :key="indexCL"
                >
                  <div>
                    <p class="font-semibold text-orange-600">{{ cl.Name }}</p>
                    <p class="text-xs font-semibold text-gray-700">
                          {{ cl.NumeroClient ? cl.NumeroClient + " - " : "" }}
                          {{ cl.Adresse ? cl.Adresse : "" }}
                          {{ cl.Complement ? ", " + cl.Complement : "" }}
                          {{ cl.PostalCode ? ", " + cl.PostalCode : "" }}
                          <span v-if="cl.City"
                            >,
                            <span class="font-bold text-black">{{ cl.City }}</span></span
                          >
                        </p>
                  </div>
                  <!-- Right Select, Edit ve Delete -->
                  <div v-if="!modifPro" class="flex space-x-4">
                    <!-- Select Dropdown -->
                    <div>
                      <select
                        @click.stop="saveGroupBeforeEdit(cl, indexCond)"
                        v-model="cl.Group.Name"
                        @change="confirmGroupChange(cl, indexCond)"
                        class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-2 border-gray-100 rounded-md cursor-pointer hover:border-2 hover:border-sky-500"
                      >
                        <option
                          :value="cl.Name"
                          v-for="(cl, indexCond) in configurationConditionList"
                          :key="indexCond"
                        >
                          {{ cl.Name }}
                        </option>
                      </select>
                    </div>

                    <!-- Delete Button -->
                    <div class="relative mt-2">
                      <button
                        class="text-red-600 hover:text-red-400 transition delay-150 duration-400 ease-in-out hover:-translate-y-1 hover:scale-110"
                        @click.stop="suppClient(cl, indexCL)"
                      >
                        <span class="material-icons-outlined">delete</span>
                      </button>
                    </div>

                    <!-- Edit Button -->
                    <button
                      class="text-blue-600 hover:text-blue-400 transition delay-150 duration-400 ease-in-out hover:-translate-y-1 hover:scale-110"
                      @click.stop="editClient(cl)"
                    >
                      <span class="material-icons-outlined">edit</span>
                    </button>
                  </div>
                  <!-- <div v-if="stateToAdd == false" class="flex space-x-2">
                    <div class="relative">
                      <button
                        class="text-red-600 hover:text-red-400"
                        @click.stop="suppClient(cl)"
                      >
                        <span class="material-icons-outlined">delete</span>
                      </button>
                    </div>

                    <button
                      class="text-blue-600 hover:text-gray-400"
                      @click.stop="editClient(cl)"
                    >
                      <span class="material-icons-outlined">edit</span>
                    </button>
                  </div> -->
                </div>
              </div>
            </div>

            <!-- <div
              v-if="stateToAdd == false"
              class="w-[15rem] flex flex-col space-y-2"
            >
              <div class="flex justify-start">
                <button
                  class="bg-bluevk hover:bg-opacity-60 text-white px-7 py-1 shadow rounded-md w-full"
                  @click="
                    (newInputClient = 'Particulier'),
                      createSession(true, true, null, null)
                  "
                >
                  Particulier
                </button>
              </div>
              <div
                v-if="configurationConditionList"
                class="w-full rounded-md p-1 flex flex-col h-48 max-h-48 overflow-y-auto border border-gray-300"
              >
                <div
                  v-for="(cond, indexC) in configurationConditionList"
                  :key="indexC"
                  class="cursor-pointer rounded-sm hover:bg-gray-200 p-2"
                  @click="
                    (newInputClient = 'Particulier'),
                      createSession(true, false, cond, null)
                  "
                >
                  <p class="font-medium text-sky-600">{{ cond.Name }}</p>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>

      <!-- CREER LA SESSION -->
      <div v-if="showNewInput" class="absolute z-20 flex mt-11">
        <div
          class="absolute z-20 bg-white flex flex-col shadow-lg rounded-md p-1 border-4 border-sky-300"
        >
          <div
            :class="[
              stateToAdd == true
                ? 'grid grid-cols-[25rem_25rem]'
                : 'grid grid-cols-[50rem_21rem]',
              'gap-x-4',
            ]"
          >
            <div class="w-[51rem] h-[60rem] flex flex-col space-y-2">
              <div class="relative w-full">
                <input
                  list="brow2"
                  @input="searchClientFav(newInputClient, cdtnClient)"
                  @focus="newInputClient = ''"
                  @keydown.enter="selectFirstClient('create')"
                  v-model="newInputClient"
                  placeholder="Nouveau client"
                  class="my-1 w-full h-10 pl-1 pr-12 text-black border-2 border-gray-500 rounded-md focus:bg-gray-300 focus:placeholder-gray-600 focus:text-gray-700 hover:bg-white focus:outline-2 outline-sky-300"
                />
                <button
                  v-if="stateToAdd == false"
                  class="absolute text-xs font-bold right-2 top-1/2 transform -translate-y-1/2 bg-bluevk text-white px-2 h-8 rounded-md shadow-md hover:bg-blue-500 focus:outline-none"
                  @click="showNewClient = true"
                >
                  Nouveau
                </button>
                <addClientParticulier
                  v-if="showNewClient"
                  :clientInput="inputClient"
                  @close="showNewClient ? (showNewClient = false) : ''"
                  @valid="
                    showNewClient
                      ? ((newInputClient = $event.Name),
                        (showNewClient = false),
                        createSession(false, null, null, $event))
                      : ''
                  "
                />
              </div>
              <div
                class="w-full rounded-md p-1 flex flex-col h-[60rem] max-h-[60rem] overflow-y-auto border border-gray-300"
              >
                <div
                  v-show="Clients.length > 0"
                  class="cursor-pointer rounded-sm hover:bg-gray-200 flex items-center justify-between p-2"
                  @click="
                    (newInputClient = cl.Name),
                      createSession(false, null, null, cl)
                  "
                  v-for="(cl, indexCL) in Clients"
                  :key="indexCL"
                >
                  <!-- left Client  -->
                  <div>
                    <p class="font-semibold text-orange-600">{{ cl.Name }}</p>
                    <p class="text-xs font-semibold text-gray-700">
                          {{ cl.NumeroClient ? cl.NumeroClient + " - " : "" }}
                          {{ cl.Adresse ? cl.Adresse : "" }}
                          {{ cl.Complement ? ", " + cl.Complement : "" }}
                          {{ cl.PostalCode ? ", " + cl.PostalCode : "" }}
                          <span v-if="cl.City"
                            >,
                            <span class="font-bold text-black">{{ cl.City }}</span></span
                          >
                        </p>
                  </div>

                  <!-- right Select, Edit ve Delete -->
                  <div
                    v-if="
                      stateToAdd == false &&
                      configurationConditionList.length > 0
                    "
                    class="flex space-x-4"
                  >
                    <div v-if="cl.Group">
                      <select
                        @click.stop="saveGroupBeforeEdit(cl, indexCL)"
                        v-model="cl.Group.Name"
                        @change="confirmGroupChange(cl, indexCL)"
                        class="w-full focus:outline-2 outline-sky-300 text-black p-2 border-2 border-gray-100 rounded-md cursor-pointer hover:border-2 hover:border-sky-500"
                      >
                        <option
                          :value="cond.Name"
                          v-for="(
                            cond, indexCond
                          ) in configurationConditionList"
                          :key="indexCond"
                        >
                          {{ cond.Name }}
                        </option>
                      </select>
                    </div>

                    <!-- Delete Button -->
                    <div class="relative mt-2">
                      <button
                        class="text-red-600 hover:text-red-400 transition delay-150 duration-400 ease-in-out hover:-translate-y-1 hover:scale-110"
                        @click.stop="suppClient(cl, indexCL)"
                      >
                        <span class="material-icons-outlined">delete</span>
                      </button>
                    </div>

                    <!-- Edit Button -->
                    <button
                      class="text-blue-600 hover:text-blue-400 transition delay-150 duration-400 ease-in-out hover:-translate-y-1 hover:scale-110"
                      @click.stop="editClient(cl)"
                    >
                      <span class="material-icons-outlined">edit</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="stateToAdd == false"
              class="w-[20rem] flex flex-col space-y-2 ml-3"
            >
              <div class="flex justify-start mt-1">
                <button
                  class="bg-bluevk hover:bg-opacity-60 font-medium text-white px-7 py-1 h-10 shadow-md rounded-md w-full"
                  @click="
                    (newInputClient = 'Particulier'),
                      createSession(true, true, null, null)
                  "
                >
                  Particulier
                </button>
              </div>
              <div
                v-if="configurationConditionList"
                class="w-full rounded-md p-1 flex flex-col h-[57rem] max-h-[57rem] overflow-y-auto border border-gray-300"
              >
                <div
                  v-for="(cond, indexC) in configurationConditionList"
                  :key="indexC"
                  class="cursor-pointer rounded-sm hover:bg-gray-200 p-2"
                  @click="
                    (newInputClient = 'Particulier'),
                      createSession(true, false, cond, null)
                  "
                >
                  <p class="font-medium text-sky-600">{{ cond.Name }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- LISTE SESSIONS -->
      <div
        v-if="
          comptoirSessionActive &&
          comptoirSessionActive.length > 0 &&
          comptoirSessionActive[0].Client &&
          comptoirSessionActive[0].Client.length > 0
        "
        class="relative flex flex-col items-center"
      >
        <!-- Button -->
        <button
          @click="openSessionList()"
          class="relative flex flex-col m-auto h-fit ml-2 cursor-pointer bg-cyan-600 hover:bg-cyan-600 rounded-md text-xs"
          style="
            height: 28px;
            max-height: 28px;
            width: 3.5rem;
            max-width: 3.5rem;
          "
        >
          <span
            class="m-auto align-center items-center material-icons-outlined text-white"
          >
            more_horiz
          </span>
        </button>

        <div
          v-show="showOutilsSession"
          class="absolute w-[40rem] h-[35rem] m-auto py-1 mt-10 ml-36 bg-white text-black shadow-lg rounded-md border-2 border-sky-300 overflow-auto z-20"
        >
          <!-- Arrow -->
          <div class="absolute top-[-8px] left-1/2 transform -translate-x-1/2">
            <!-- <div
              class="w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-b-8 border-b-white"
            ></div> -->
          </div>

          <table
            class="table-auto w-full border-collapse border border-gray-100"
          >
            <thead>
              <tr class="bg-gray-100 text-black">
                <!-- <th class="border border-gray-100 p-2 text-left">Nom</th>
                <th class="border border-gray-100 p-2 text-left" colspan="2">
                  Articles en cours
                </th> -->
              </tr>
            </thead>
            <tbody v-if="comptoirSession.length > 0">
              <tr
                v-for="(session, index) in comptoirSession"
                :key="index"
                class="hover:bg-gray-200 cursor-pointer"
                :class="
                  session?._id == comptoirSessionActive[0]._id
                    ? 'bg-blue-100 border-2 border-blue-200'
                    : ''
                "
                @click="selectSession(session)"
              >
                <td class="border text-[14px] border-gray-100 p-2">
                  <div class="flex flex-col">
                    <p class="font-medium text-orange-600">
                      {{ session?.Client[0].Name }}
                    </p>
                    <p class="text-xs">
                      {{
                        session?.Client[0].NumeroClient
                          ? session?.Client[0].NumeroClient + " - "
                          : ""
                      }}
                      {{
                        session?.Client[0].Adresse
                          ? session?.Client[0].Adresse
                          : ""
                      }}
                      {{
                        session?.Client[0].Complement
                          ? ", " + session?.Client[0].Complement
                          : ""
                      }}
                      {{
                        session?.Client[0].PostalCode
                          ? ", " + session?.Client[0].PostalCode
                          : ""
                      }}
                      {{
                        session?.Client[0].City
                          ? ", " + session?.Client[0].City
                          : ""
                      }}
                    </p>
                  </div>
                </td>

                <td class="w-20 border text-[14px] border-gray-100 p-2">
                  {{ session?.Client[0].Group.Name }}
                </td>

                <td class="border border-gray-100 p-2" colspan="2">
                  <div class="flex items-center justify-between my-auto">
                    <span class="text-blue-600 font-medium">{{
                      session?.actionArticle.length
                    }}</span>
                    <span class="ml-4 text-[15px]">Articles en cours</span>
                    <span
                      class="material-icons-outlined text-blue-600 cursor-pointer hover:text-blue-400 ml-4 text-[20px]"
                      @click.stop="OpenShowDetail(session)"
                      title="Détail des articles"
                    >
                      info
                    </span>
                    <span
                      class="material-icons-round text-red-500 cursor-pointer hover:text-red-400 mr-2 text-[20px]"
                      @click.stop="removecomptoirsession(session)"
                      title="Supprimer la session"
                    >
                      cancel
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <!-- Aucune session active
      <div class="pr-3 m-auto" v-if="comptoirSession.length == 0">
        <router-link to="/comptoirSession">
          <button
            style="height: 28px; width: auto"
            class="bg-red-300 px-1 hover:bg-red-700 text-white shadow rounded-md cursor-pointer z-10 border border-red-300"
          >
            Aucune session active
          </button>
        </router-link>
      </div> -->

      <!-- Montant commande de la journée -->
      <!-- <div
        class="pl-2 relative hidden 2xl:block"
        v-if="comptoirSessionActive.length > 0"
      >
        <button @click="dropdownOpen = !dropdownOpen">
          <span
            class="material-icons-outlined text-cyan-600 text-md shadow rounded-md cursor-pointer z-10"
          >
            inventory
          </span>
        </button>
        <div
          v-show="dropdownOpen"
          @click="dropdownOpen = false"
          class="fixed inset-0 h-full w-full z-10"
        ></div>
        <div
          v-show="dropdownOpen"
          class="absolute right-50 z-20 w-48 py-5 mt-2 bg-white rounded-lg shadow-xl text-center"
        >
          Commande d'aujourd'hui :
          <span class="font-semibold" v-if="comptoirSessionActive[0]"
            >{{
              parseFloat(comptoirSessionActive[0].totalPanier).toFixed(2)
            }}
            €</span
          >
        </div>
      </div> -->
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
import { useToast } from "vue-toastification";
// import { getPanierTransport } from "@/hooks/transport/panierTransport.ts";
import { getFrDate } from "@/hooks/tools/date.ts";
import addClientParticulier from "../Comptoir/addClientParticulier.vue";
import modifierClientParticulier from "../Comptoir/modifierClientParticulier.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      telephoneParticulier: "",
      emailParticulier: "",
      paysParticulier: "FRANCE",
      villeParticulier: "",
      codepostalParticulier: "",
      complementParticulier: "",
      adresseParticulier: "",
      groupeClient: "",
      nomParticulier: "",
      professionnelParticulier: false,
      showClientPar: false,
      showNewClient: false,

      historique: [],
      historiqueShow: false,
      showOutilsClient: false,
      showNameHistClient: false,
      tabFilterHistorique: [],
      checkboxDevis: false,
      checkboxFacturation: false,

      showOutilsSession: false,
      showNewInput: false,
      newInputClient: "",
      inputClient: "",
      showSessionTab: false,
      clientChosed: "",
      qtyTab: [],
      Clients: [],
      isColumn: false,
      fournisseurList: [],
      noteDocument: "",
      ClientsPar: [],
      cdtnClient: false,
      dropdownOpen: false,

      panierTransport: {},
      modifPro: true,
      devisRadio: true,
      commandeRadio: true,
      livraisonRadio: false,
      livraisonAvoirRadio: false,
      factureRadio: true,
      retourRadio: false,
      showDetail: false,
      detailTab: {},

      stateToAdd: false,

      configurationConditionList: [],
      showDeleteClientPar: false,
      showEditClientPar: false,
      clientSelectedToModify: null,
      indexSelectedToModify: null,
      showInformationsGenerales: false,
      showModifierParticulier: true,

      debounceTimer: null,
      showConfirmationModal: false,
      selectedClient: null,
      selectedClientTMP: null,
      originalGroupState: null,
      clickedIndexes: [],
    };
  },
  computed: {
    ...mapGetters([
      "clientFocus",
      "comptoirSession",
      "actionArticle",
      "user",
      "comptoirSessionActive",
      "refClient",
      "dataConsigne",
      "dataNonConsigne",
      "dsvCheck",
      "plateform",
      "Ref_fournisseur_search",
    ]),
    setTotal() {
      var total = 0.0;
      var totalRemise = 0.0;
      var totalRemiseGen = 0.0;
      var totaldebut = 0.0;

      if (this.detailTab.actionArticle.length > 0) {
        this.detailTab.actionArticle.forEach((element, index) => {
          if (element.PrixFournisseurType == "prixnet") {
            totalRemise = parseFloat(element.Prix_vente);
          } else {
            totalRemise =
              parseFloat(element.Prix_euro) -
              parseFloat(element.Prix_euro) *
                (this.detailTab.actionArticle[index].remise / 100);
          }
          totaldebut =
            totaldebut +
            totalRemise * this.detailTab.actionArticle[index].quantity;
          this.totalDebutFrais = totaldebut;
        });

        if (
          this.detailTab.Client[0].professionnel == true &&
          (this.detailTab.documentType === "Devis" ||
            this.detailTab.documentType === "Facture")
        ) {
          if (parseFloat(this.detailTab.francodeport) >= 0) {
            if (parseFloat(this.detailTab.totalPanier) > 0.0) {
              if (
                parseFloat(this.detailTab.totalPanier) + totaldebut <
                parseFloat(this.detailTab.francodeport)
              ) {
                this.setfraisdeport({
                  value: this.detailTab.fraisdeportsave,
                });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              }
            } else {
              if (
                parseFloat(totaldebut) < parseFloat(this.detailTab.francodeport)
              ) {
                this.setfraisdeport({
                  value: this.detailTab.fraisdeportsave,
                });
                total = totaldebut + parseFloat(this.detailTab.fraisdeportsave);
              } else {
                this.setfraisdeport({ value: "0" });
                total += totaldebut;
              }
            }
          } else {
            total = totaldebut;
          }
        } else {
          total = totaldebut;
        }
      } else {
        total += totaldebut;
      }

      if (
        this.detailTab.documentType === "Devis" ||
        this.detailTab.documentType === "Facture"
      ) {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) * (this.detailTab.remisegenerale / 100) +
          parseFloat(this.detailTab.fraisdeportsave);
      } else {
        totalRemiseGen =
          parseFloat(totaldebut) -
          parseFloat(totaldebut) * (this.detailTab.remisegenerale / 100);
      }

      if (totaldebut >= 0) {
        if (
          this.detailTab.remisegenerale &&
          this.detailTab.remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          return total;
        }
      } else {
        if (
          this.detailTab.remisegenerale &&
          this.detailTab.remisegenerale != 0
        ) {
          return totalRemiseGen;
        } else {
          this.setfraisdeport({ value: "0" });
          return totaldebut;
        }
      }
    },
    setTotalTTC() {
      return this.setTotal + this.setTotal * 0.2;
    },
  },
  props: ["clientsInput"],
  components: { addClientParticulier, modifierClientParticulier },
  methods: {
    ...mapActions([
      "updateactionArticle",
      "getallcomptoirsession",
      "createcomptoirsession",
      "setcomptoirsession",
      "removecomptoirsession",
      "savecomptoirsession",
      "setrefclient",
      "getclientchoosedtab",
      "updaterefclient",
      "setdsvcheck",
      "updateactionarticlesession",
      "sendToRestoreTransportCart",
      "searchRefFournisseur",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    searchClientFav: function (searchTerm, pro) {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (searchTerm) {
          axios
            .post(`${process.env.VUE_APP_API}/client/searchClientFav`, {
              plateform: this.user.proprietaire,
              searchTerm: searchTerm,
              pro: pro,
              archived: false,
            })
            .then((res) => {
              this.Clients = res.data;
              this.clickedIndexes = [];
              this.originalGroupState = null;
            });
        } else {
          this.getFavori(this.cdtnClient);
        }
      }, 300); // 300ms
    },
    async selectFirstClient(param) {
      if (this.newInputClient !== "" || this.inputClient !== "") {
        if (this.Clients.length > 0) {
          if (param == "create") {
            this.newInputClient = this.Clients[0].Name;
            await this.createSession(false, null, null, this.Clients[0]);
          } else {
            this.inputClient = this.Clients[0].Name;
            await this.updateSessionClient(this.Clients[0]);
          }
        } else {
          if (param == "create") {
            this.nomParticulier = this.newInputClient;
          } else {
            this.nomParticulier = this.inputClient;
          }
          this.showClientPar = true;
        }
      }
      // }
    },
    resetClientFields() {
      this.nomParticulier = "";
      this.adresseParticulier = "";
      this.telephoneParticulier = "";
      this.emailParticulier = "";
    },

    closeModal(clearFields = false) {
      this.showClientPar = false;
      if (clearFields) {
        this.resetClientFields();
      }
    },
    filterClients(cdtn) {
      if (this.newInputClient != "") {
        if (cdtn) {
          this.getClientsPro();
        } else {
          this.getClientsPar();
        }
        const searchTerm = this.newInputClient.trim().toLowerCase();
        this.Clients = this.Clients.filter((client) => {
          return client.Name.toLowerCase().includes(searchTerm);
        });
      } else {
        this.getFavori(cdtn);
      }
    },
    filterModifClients(cdtn) {
      if (this.inputClient != "") {
        if (cdtn) {
          this.getClientsPro();
        } else {
          this.getClientsPar();
        }
        const searchTerm = this.inputClient.trim().toLowerCase();
        this.Clients = this.Clients.filter((client) => {
          return client.Name.toLowerCase().includes(searchTerm);
        });
      } else {
        this.getFavori(cdtn);
      }
    },
    filterSessionClients() {
      if (this.inputClient == "") {
        this.getFavori(this.comptoirSessionActive[0].Client[0].professionnel);
      } else {
        this.getClient(this.comptoirSessionActive[0].Client[0].professionnel);
        const searchTerm = this.inputClient.trim().toLowerCase();
        this.Clients = this.Clients.filter((client) => {
          return client.Name.toLowerCase().includes(searchTerm);
        });
      }
    },
    getHistorique() {
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getone`, {
          uid: this.comptoirSessionActive[0].Client[0].Name,
        })
        .then((response) => {
          this.historique = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    filterHistoriqueFile(value, state) {
      if (state == true) {
        this.tabFilterHistorique.push(value);
        this.fetchHistoriqueFiltred();
      } else {
        var indexD = this.tabFilterHistorique.indexOf(value);

        if (indexD > -1) {
          this.tabFilterHistorique.splice(indexD, 1);
        }
        this.fetchHistoriqueFiltred();
      }
    },
    fetchHistoriqueFiltred() {
      axios
        .post(`${process.env.VUE_APP_API}/comptoir/getType`, {
          uid: this.comptoirSessionActive[0].Client[0].Name,
          Type: this.tabFilterHistorique,
        })
        .then((response) => {
          this.historique = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setHistoriqueFile(historiquedata) {
      this.updateactionArticle(historiquedata);
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
    async postPartClients() {
      await axios.post(`${process.env.VUE_APP_API}/client`, {
        professionnel: false,
        plateform: this.user.proprietaire,
        Name: this.nomParticulier,
        Group: {
          Name: this.groupeClient,
        },
        Adresse: this.adresseParticulier,
        Complement: this.complementParticulier,
        PostalCode: this.codepostalParticulier,
        City: this.villeParticulier,
        Country: this.paysParticulier,
        email: this.emailParticulier,
        telephone: this.telephoneParticulier,
        archived: false,
        counter: {
          devis: this.devisRadio,
          bondecommande: this.commandeRadio,
          bondelivraison: this.livraisonRadio,
          bondelivraisonavoir: this.livraisonAvoirRadio,
          facture: this.factureRadio,
          retour: this.retourRadio,
        },
      });
      this.getClientsPar();
      let response = await axios.post(
        `${process.env.VUE_APP_API}/client/getOne/${this.nomParticulier}`,
        {
          plateform: this.user.proprietaire,
        }
      );
      await this.createcomptoirsession({
        clientFocus: response.data,
        refClient: "",
        consigneData: [],
        consigneNonData: [],
        dsvCheck: false,
        remisegenerale: 0,
      });

      this.closeNewInputClient();
      this.closeInputClient();

      this.toast.success("Client ajouté !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    getFournisseur() {
      axios
        .post(`${process.env.VUE_APP_API}/fournisseur/getall`, {
          plateform: this.user.proprietaire,
        })
        .then((response) => {
          this.fournisseurList = response.data;
        });
    },
    chooseClient() {
      this.getclientchoosedtab(this.comptoirSessionActive[0].Client[0]);
      this.$router.push("/detailClient");
    },
    async getClient(pro) {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      if (pro) {
        await axios
          .get(`${process.env.VUE_APP_API}/client/${plateform_mere}`)
          .then((response) => {
            this.Clients = response.data;
          });
      } else {
        axios
          .get(
            `${process.env.VUE_APP_API}/client/clientParticulier/${plateform_mere}`
          )
          .then((response) => {
            this.Clients = response.data;
          });
      }
    },
    async getClientsPro() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .get(`${process.env.VUE_APP_API}/client/${plateform_mere}`)
        .then((response) => {
          this.Clients = response.data;
          this.cdtnClient = true;
        });
    },
    async getClientsPar() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      axios
        .get(
          `${process.env.VUE_APP_API}/client/clientParticulier/${plateform_mere}`
        )
        .then((response) => {
          this.Clients = response.data;
          this.cdtnClient = false;
        });
    },
    getFavori(cdtnCl) {
      this.stateToAdd = cdtnCl;
      axios
        .post(`${process.env.VUE_APP_API}/client/favoriClient/get`, {
          plateform: this.user.proprietaire,
          pro: cdtnCl,
        })
        .then((res) => {
          this.Clients = res.data;
          this.configurationConditionList.length > 0
            ? ""
            : this.getConfigurationCondition();
          console.log(this.Clients);
        });
    },
    async createSession(
      createParticulier,
      defaultCond,
      conditionRemise,
      client
    ) {
      if (this.newInputClient != "") {
        let plateform_mere = this.user.proprietaire;
        if (this.plateform.plateform_mere) {
          plateform_mere = this.plateform.plateform_mere;
        }

        let response = {};
        if (createParticulier) {
          await this.getConfigurationCondition();
          let defaultOption;
          if (defaultCond) {
            defaultOption = this.configurationConditionList.find(
              (cond) => cond.default
            );
          } else {
            defaultOption = conditionRemise;
          }

          response.data = [];
          response.data = [
            {
              _id: "inconnu" + Math.floor(Math.random() * 1000).toString(),
              plateform: this.user.proprietaire,
              Name: "Particulier",
              Group: {
                Name: defaultOption ? defaultOption.Name : "T3",
              },
              professionnel: false,
              editionRemise: true,
              counter: {
                devis: true,
                bondecommande: true,
                facture: true,
                bondelivraison: false,
                bondelivraisonavoir: false,
                retour: false,
              },
            },
          ];
        } else {
          const clientInf = await axios.post(
            `${process.env.VUE_APP_API}/client/getByID`,
            client
          );
          response.data = [];
          response.data = [clientInf.data];
        }

        if (response.data.length > 0) {
          var docType;
          if (this.cdtnClient) {
            if (response.data[0].counter?.bondelivraison) {
              docType = "Bon de livraison";
            } else {
              docType = "";
            }
          } else {
            if (response.data[0].counter?.facture) {
              docType = "Facturation";
            } else {
              docType = "";
            }
          }
          await this.createcomptoirsession({
            clientFocus: response.data,
            refClient: "",
            consigneData: [],
            consigneNonData: [],
            dsvCheck: false,
            remisegenerale: 0,
            documentType: docType,
          });

          if (!createParticulier) {
            await this.sendToRestoreTransportCart({
              _id: response.data[0]._id,
              TransportCart: true,
            });
          }

          if (
            this.$route.path === "/searchArticleExact" ||
            this.$route.path === "/searchArticleExactBis"
          ) {
            this.searchRefFournisseur({
              Ref_fournisseur: this.Ref_fournisseur_search,
            });
          }
          this.newInputClient = "";
          this.showNewInput = false;
          this.inputClient = "";
          this.showSessionTab = false;
        }
      } else {
        this.getFavori(this.cdtnClient);
        this.newInputClient = "";
        this.showNewInput = false;
        this.inputClient = "";
        this.showSessionTab = false;
      }
    },

    async updateSessionClient(client) {
      if (this.inputClient != "") {
        let response = [];
        const clientInf = await axios.post(
          `${process.env.VUE_APP_API}/client/getByID`,
          client
        );
        response.data = [];
        response.data = [clientInf.data];

        if (response.data.length > 0) {
          var docType;
          if (this.modifPro) {
            docType = "Bon de livraison";
          } else {
            docType = "Facturation";
          }
          await this.savecomptoirsession({
            clientFocus: response.data,
            refClient: this.comptoirSessionActive[0].refClient,
            consigneData: this.comptoirSessionActive[0].consigneData,
            consigneNonData: this.comptoirSessionActive[0].consigneNonData,
            dsvCheck: this.comptoirSessionActive[0].dsvCheck,
            documentType: docType,
          });
          await this.updateactionarticlesession({
            Name: this.comptoirSessionActive[0].Client[0].Name,
            Group: this.comptoirSessionActive[0].Client[0].Group.Name,
            _id: this.comptoirSessionActive[0].Client[0]._id,
            TransportCart: true,
          });
          if (this.$route.path === "/searchArticleExact") {
            this.searchRefFournisseur({
              Ref_fournisseur: this.Ref_fournisseur_search,
            });
          }
          this.inputClient = "";
          this.showSessionTab = false;
        }
      } else {
        this.getFavori(this.cdtnClient);
        this.inputClient = "";
        this.showSessionTab = false;
      }
    },

    openSessionList() {
      this.showOutilsSession = !this.showOutilsSession;
    },
    selectSession(session) {
      this.openComptoirSession(session);
      this.showOutilsSession = false;
    },
    showSession(session) {
      this.inputClient = session.Client[0].Name;
    },
    clientPush() {
      this.$router.push("/actionClient");
    },
    async chooseSession(session) {
      this.setcomptoirsession(session);
      // await this.getPanierTransportClient();
      this.savecomptoirsession({
        clientFocus: session.Client,
      });
      this.sendToRestoreTransportCart({
        _id: session.Client[0]._id,
        TransportCart: true,
      });
    },
    closeNewInputClient() {
      this.showNewInput = false;
      this.newInputClient = "";
    },
    closeInputClient() {
      this.showSessionTab = false;
      this.inputClient = "";
    },
    async removeSession() {
      await this.removecomptoirsession(this.comptoirSessionActive[0]);
      this.showSessionTab = false;
    },
    openComptoirSession(session) {
      this.chooseSession(session);
    },
    OpenShowDetail(session) {
      this.detailTab = session;
      this.showDetail = true;
    },
    CloseShowDetail() {
      this.showDetail = false;
    },
    async getConfigurationCondition() {
      const condition = await axios.get(
        `${process.env.VUE_APP_API}/configurationCondition/${this.user.proprietaire}`
      );
      this.configurationConditionList = condition.data;
    },

    // Sauvegarder l'état du groupe avant modification
    saveGroupBeforeEdit(client, index) {
      if (!this.clickedIndexes.some((item) => item.index === index)) {
        this.originalGroupState = JSON.parse(JSON.stringify(this.Clients));
        this.clickedIndexes.push({
          index: index,
          Group: client.Group.Name,
        });
      }
    },
    confirmGroupChange(client, index) {
      this.selectedClient = client;
      this.showConfirmationModal = true;
    },
    async saveModifierGroupClient() {
      const updatedData = {
        Group: {
          Name: this.selectedClient.Group.Name,
        },
        updated_by: this.user.username,
      };
      axios
        .put(
          `${process.env.VUE_APP_API}/client/modifyClient/${this.selectedClient._id}`,
          {
            data: updatedData,
          }
        )
        .then(() => {
          this.toast.success("Modifié !", {
            position: "bottom-right",
            timeout: 1000,
          });
          this.clickedIndexes = [];
          this.originalGroupState = null;
          this.showConfirmationModal = false;
        })
        .catch((error) => {
          this.toast.error("Erreur lors de la modification !", {
            position: "bottom-right",
          });
          console.error("erreur :", error);
          this.showConfirmationModal = false;
        });
    },
    cancelGroupChange() {
      this.showConfirmationModal = false;
      this.Clients = this.originalGroupState;
      this.clickedIndexes = [];
      this.originalGroupState = null;
      this.selectedClient = null;
    },
    async saveGroupClient(tab) {
      await this.savecomptoirsession({
        clientFocus: tab.Client,
        refClient: tab.refClient,
        consigneData: tab.consigneData,
        consigneNonData: tab.consigneNonData,
        dsvCheck: tab.dsvCheck,
      });
      await this.updateactionarticlesession({
        Name: tab.Client[0].Name,
        Group: tab.Client[0].Group.Name,
        _id: tab.Client[0]._id,
        TransportCart: true,
      });
    },
    suppClient(client, index) {
      this.clientSelectedToModify = client;
      this.indexSelectedToModify = index;
      this.showDeleteClientPar = true;
    },
    confirmDelete() {
      this.deleteClient();
      this.showDeleteClientPar = false;
    },
    deleteClient() {
      axios
        .put(
          `${process.env.VUE_APP_API}/client/removeClient/${this.clientSelectedToModify._id}`,
          this.clientSelectedToModify
        )
        .then(() => {
          this.Clients.splice(this.indexSelectedToModify, 1);
          // this.getFavori(false);
          this.toast.success("Client supprimé !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    editClient(client) {
      this.clientSelectedToModify = client;
      this.showEditClientPar = true;
    },
    saveChangeClient() {
      this.clientSelectedToModify.updated_by = this.user.username;
      axios
        .put(
          `${process.env.VUE_APP_API}/client/modifyClient/${this.clientSelectedToModify._id}`,
          {
            data: this.clientSelectedToModify,
          }
        )
        .then(() => {
          this.showEditClientPar = false;
          this.toast.success("Client modifié !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 1,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    handleClientClick(cl) {
      this.inputClient = cl.Name;
      this.updateSessionClient(cl);
    },
  },
  async beforeMount() {
    if (this.comptoirSessionActive?.length > 0) {
      this.getallcomptoirsession();
      this.getConfigurationCondition();
    }
  },
  async mounted() {
    this.getFournisseur();
    if (this.comptoirSessionActive?.length > 0) {
      // await this.getPanierTransportClient();
      // await this.savecomptoirsession({
      //   clientFocus: this.comptoirSessionActive[0].Client,
      // });
      if (this.comptoirSessionActive[0].Client[0]._id.startsWith("inconnu")) {
        await this.getConfigurationCondition();
      } else {
        await this.sendToRestoreTransportCart({
          _id: this.comptoirSessionActive[0].Client[0]._id,
          TransportCart: true,
        });
      }
    }
  },
};
</script>
<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.overflow-container {
  overflow-x: hidden;
  cursor: pointer;
}

.overflow-container::-webkit-scrollbar {
  width: 8px;
}

.overflow-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

.overflow-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
