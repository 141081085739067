<template>
  <div class="flex justify-between items-center">
    <button
      v-if="security_file"
      @click="openPDF(security_file)"
      class="flex flex-row space-x-8 items-center border border-bluevk text-bluevk p-1 rounded hover:bg-bluevk hover:text-white"
    >
      <span>Fiche de sécurité</span>
      <span class="material-symbols-rounded"> picture_as_pdf </span>
    </button>
    <button
      v-if="information_file"
      @click="openPDF(information_file)"
      class="flex flex-row space-x-8 items-center border border-bluevk text-bluevk p-1 rounded hover:bg-bluevk hover:text-white"
    >
      <span>Fiche d'information</span>
      <span class="material-symbols-rounded"> picture_as_pdf </span>
    </button>
  </div>
  <div class="flex mt-2">
    <div
      class="flex flex-col space-x-2 overflow-y-auto max-h-64"
      v-if="photo.length > 0 && photo[0].imageURL400"
    >
      <img
        v-for="(ph, indexPh) in photo"
        :key="indexPh"
        :src="ph.imageURL200"
        @click="selectPhoto(ph.imageURL400)"
        class="w-16 h-16 cursor-pointer"
      />
    </div>
    <div
      class="flex flex-col space-x-2 overflow-y-auto max-h-64"
      v-else-if="photo.length > 0"
    >
      <img
        v-for="(ph, indexPh) in photo"
        :key="indexPh"
        :src="ph"
        @click="selectPhoto(ph)"
        class="w-16 h-16 cursor-pointer"
      />
    </div>
    <img
      v-if="photoSelected"
      :src="photoSelected"
      class="mx-auto w-9/12 border"
    />
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      photo: [],
      security_file: "",
      information_file: "",
      photoSelected: "",
    };
  },
  props: ["Ref_fournisseur", "Code_marque", "Prefixe_tarif", "plateform"],
  methods: {
    async getPhotoArticle() {
      const get_tecdoc_photo = await axios.post(
        `${process.env.VUE_APP_API}/infoArticle/photoArticle`,
        {
          Ref_fournisseur: this.Ref_fournisseur,
          Code_marque: this.Code_marque,
        }
      );

      if (get_tecdoc_photo.data.length > 0) {
        this.photo = get_tecdoc_photo.data;
        this.photoSelected = get_tecdoc_photo.data[0].imageURL400;
      } else {
        const image = await axios.post(
          `${process.env.VUE_APP_API}/article/getImage`,
          {
            Ref_fournisseur: this.Ref_fournisseur,
            Code_marque: this.Code_marque,
            Prefixe_tarif: this.Prefixe_tarif,
            plateform: this.plateform,
          }
        );
        if (image.data) {
          this.photo = image.data.img_url;
          this.photoSelected = image.data.img_url[0];
          this.security_file = image.data.security_file_url;
          this.information_file = image.data.information_file_url;
        }
      }
    },
    selectPhoto(url) {
      this.photoSelected = url;
    },
    openPDF(url) {
      if (url) {
        window.open(url, "_blank");
      }
    },
  },
  beforeMount() {
    this.getPhotoArticle();
  },
};
</script>

<style scoped></style>
