<template>
  <div
    v-if="openImage && Images.length > 0"
    :class="`modal ${
      !openImage && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="openImage = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 68vw; height: 70vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content h-full">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="openImage = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-row items-center h-4/5 space-x-2 justify-center">
          <div class="flex flex-col space-y-1 max-h-[85%] overflow-y-auto">
            <div
              v-for="(img, indexImg) in Images"
              :key="indexImg"
              class="bg-orange-100 w-20 cursor-pointer"
            >
              <img
                v-if="img.imageURL200"
                :src="img.imageURL200"
                alt="image"
                class="w-full"
                @click="selectedImage = img.imageURL800"
              /><img
                v-else
                :src="img"
                alt="image"
                class="w-full"
                @click="selectedImage = img"
              />
            </div>
          </div>
          <div class="w-3/4 h-full">
            <img :src="selectedImage" alt="image" class="mx-auto h-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    v-if="showContenance"
    :class="`modal ${
      !showContenance && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showContenance = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 80vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Détails de la contenance</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showContenance = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div v-show="showContenance" class="flex flex-col">
          <div
            v-for="(PLT, indexPLT) in partsListTab"
            :key="indexPLT"
            class="flex flex-row mt-2 border border-gray-300 rounded-sm"
          >
            <div class="flex flex-col space-y-2 w-full">
              <div
                v-for="(PL, indexPL) in PLT"
                :key="indexPL"
                class="flex flex-row items-center w-full space-x-2 text-sm border border-gray-100 rounded p-1"
              >
                <div class="w-1/6">
                  <img
                    v-if="
                      PL.tdArticle.images.length > 0 &&
                      PL.tdArticle.images[0].imageURL200
                    "
                    :src="PL.tdArticle.images[0].imageURL200"
                    alt="image"
                    @click="openImages()"
                    class="max-h-full mx-auto h-16 md:h-24 lg:h-34 cursor-pointer"
                  />
                  <img
                    v-else
                    src="@/assets/not-found.png"
                    alt="image"
                    class="w-full mx-auto max-h-full w-16 h-16 md:w-24 md:h-24 lg:w-40 lg:h-34"
                  />
                </div>
                <div class="w-1/6">
                  <button
                    class="font-bold text-blue-500 mx-auto"
                    @click="
                      searchRefFournisseur({
                        Ref_fournisseur: PL.tdArticle.articleNumber,
                      }),
                        $emit('checkCross')
                    "
                  >
                    {{ PL.tdArticle.articleNumber }}
                  </button>
                </div>

                <div class="w-1/6">
                  <div class="mx-auto w-fit">
                    {{
                      PL.tdArticle.genericArticles[0].genericArticleDescription
                    }}
                  </div>
                </div>
                <div v-if="PL.db" class="w-1/6">
                  <stockvue
                    class="mx-auto w-fit"
                    :Article="PL.dbArticle"
                    quantity="1"
                    :key="PL.dbArticle._id + reloadKeyStock"
                    @fraisdeportfournisseur="setFraisdePort"
                    @stockstate="setStateStock"
                  />
                </div>
                <div v-else>
                  <span class="text-orange-600"
                    >Article non enregistré en base de donnée</span
                  >
                </div>
                <div v-if="PL.db" class="w-1/6">
                  <paniervue
                    class="mx-auto w-fit"
                    :article="PL.dbArticle"
                    :prixData="tmp_prix"
                    quantity="1"
                    :savedSearch="savedSearch"
                  />
                </div>
                <div v-if="PL.db" class="w-1/6">
                  <prixvue
                    class="mx-auto"
                    :article="PL.dbArticle"
                    :Prix="Prix"
                    :key="tmp_article._id + reloadKeyPrix"
                  />
                </div>
              </div>
            </div>

            <button
              class="material-icons-round text-red-500"
              @click="$emit('deletePartsList', indexPLT)"
            >
              delete_forever
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    :class="
      tmp_article.Prefixe_tarif == 'Vhi'
        ? 'flex flex-col space-y-2 p-2 bg-blue-50 rounded shadow w-full h-fit'
        : 'flex flex-col space-y-2 p-2 bg-white rounded shadow w-full h-fit'
    "
  >
    <div class="flex flex-row">
      <div class="hidden md:flex my-auto w-6">
        <input
          type="checkbox"
          disabled
          v-if="
            compareTabLength > 3 && compareTabLength <= 4 && !checkbox_value
          "
          v-model="checkbox_value"
          @change="$emit('checkedArticle')"
        />
        <input
          type="checkbox"
          v-else
          v-model="checkbox_value"
          @change="$emit('checkedArticle')"
        />
      </div>
      <div class="w-1/12">
        <img
          v-if="Images.length > 0 && Images[0].imageURL200"
          :src="Images[0].imageURL200"
          alt="image"
          @click="openImages()"
          class="max-h-full mx-auto h-16 md:h-24 lg:h-34 cursor-pointer"
        />
        <img
          v-else-if="Images.length > 0 && Images[0].imageURL400"
          :src="Images[0].imageURL400"
          alt="image"
          @click="openImages()"
          class="max-h-full mx-auto h-16 md:h-24 lg:h-34 cursor-pointer"
        />
        <img
          v-else-if="Images.length > 0"
          :src="Images[0]"
          alt="image"
          @click="openImages()"
          class="max-h-full mx-auto h-16 md:h-24 lg:h-34 cursor-pointer"
        />
        <img
          v-else
          src="@/assets/not-found.png"
          alt="image"
          class="w-full max-h-full mx-auto w-16 h-16 md:w-24 md:h-24 lg:w-40 lg:h-34"
        />
      </div>
      <div class="flex flex-col space-y-2 w-2/5 md:w-2/12 text-sm md:text-base">
        <div class="flex flex-row items-center mx-auto">
          <div
            class="w-fit text-blue-500 cursor-pointer flex flex-row items-center space-x-2"
            @click="$emit('infoArticle')"
          >
            <span>{{ Ref_fournisseur }}</span>
            <span class="material-icons-round"> infos </span>
          </div>
          <span
            class="material-icons-round text-blue-500 cursor-pointer"
            v-if="Code_marque == 'MEYLE'"
            @click="
              searchRefFournisseurGen({
                Ref_fournisseur: tmp_article.Ref_complementaire,
                genArt: genericArticleId,
              }),
                $emit('checkCross')
            "
          >
            sync
          </span>
          <span
            class="material-icons-round text-blue-500 cursor-pointer"
            v-else
            @click="
              searchRefFournisseurGen({
                Ref_fournisseur: Ref_fournisseur,
                genArt: genericArticleId,
              }),
                $emit('checkCross')
            "
          >
            sync
          </span>
          <div
            class="flex flex-row items-center cursor-pointer"
            v-if="tmp_article.Prefixe_tarif == 'Vhi'"
            @click="
              searchRefFournisseurGen({
                Ref_fournisseur: Ref_fournisseur,
                genArt: genericArticleId,
                Prefixe_tarif: 'Vhi',
              }),
                $emit('checkCross')
            "
          >
            <span class="material-icons-round text-orange-500 text-sm">
              sync
            </span>
          </div>
        </div>

        <div
          v-if="tmp_article.Ref_complementaire"
          class="text-blue-500 mx-auto text-sm cursor-pointer"
          @click="
            searchRefFournisseur({
              Ref_fournisseur: tmp_article.Ref_complementaire,
            }),
              $emit('checkCross')
          "
        >
          {{ tmp_article.Ref_complementaire }}
        </div>
        <!-- <div class="flex flex-row mx-auto">
          <div
            class="flex flex-row items-center cursor-pointer"
            v-if="article.Prefixe_tarif == 'Vhi'"
            @click="
              searchRefFournisseurGen({
                Ref_fournisseur: Ref_fournisseur,
                genArt: genericArticleId,
                Prefixe_tarif: 'Vhi',
              }),
                $emit('checkCross')
            "
          >
            <span class="material-icons-round text-orange-500 text-xs">
              sync </span
            ><span class="text-xs text-orange-500">vh</span>
          </div>

          <span
            class="material-icons-round text-blue-500 cursor-pointer"
            @click="
              searchRefFournisseurGen({
                Ref_fournisseur: Ref_fournisseur,
                genArt: genericArticleId,
              }),
                $emit('checkCross')
            "
          >
            sync
          </span>
        </div> -->

        <div class="flex lg:hidden">{{ Code_marque }}</div>

        <div class="flex flex-row mx-auto" v-if="Prix.promo">
          <span class="px-1 bg-orange-500 text-sm text-white rounded shadow">
            Promo
          </span>
          <!-- <span class="material-icons-round" >
          query_stats
        </span> -->
        </div>
        <!-- <div class="flex md:hidden">
          <stockvue
            :Article="tmp_article"
            :quantity="tmp_quantity"
            :key="tmp_article._id + reloadKeyStock"
            @fraisdeportfournisseur="setFraisdePort"
            @stockstate="setStateStock"
          />
        </div> -->
      </div>
      <div class="w-1/12 hidden lg:flex lg:flex-col">
        <div class="font-bold">{{ Code_marque }}</div>
        <!-- <div>{{ Fournisseur }}</div> -->
      </div>
      <div class="w-2/12 hidden lg:flex">{{ Description }}</div>
      <div
        class="w-2/12 lg:1/12 hidden md:flex md:flex-col md:space-y-2 mx-auto"
      >
        <span class="w-full">{{ systemeFreinage }}</span>
        <span class="w-full">{{ coteAssemblage }}</span>
        <span class="w-full">{{ typeMontage }}</span>
      </div>
      <div class="w-2/12 hidden md:flex">
        <stockvue
          :Article="tmp_article"
          :quantity="tmp_quantity"
          :key="tmp_article._id + reloadKeyStock"
          @fraisdeportfournisseur="setFraisdePort"
          @stockstate="setStateStock"
        />
      </div>
      <div class="w-1/12 lg:1/12 hidden md:flex">
        <div class="m-auto">
          <paniervue
            :article="tmp_article"
            :prixData="tmp_prix"
            :quantity="quantity"
            @updateQuantity="checkQuantity"
            :savedSearch="savedSearch"
          />
        </div>
      </div>
      <div class="flex flex-col w-2/5 md:w-1/12 text-sm md:text-base p-1">
        <div class="m-auto">
          <prixvue
            :article="tmp_article"
            :Prix="Prix"
            @priorityChecked="priorityChecked"
            :key="tmp_article._id + reloadKeyPrix"
          />
        </div>
        <div class="flex md:hidden m-auto">
          <paniervue
            :article="tmp_article"
            :prixData="tmp_prix"
            :quantity="quantity"
            @updateQuantity="checkQuantity"
            :savedSearch="savedSearch"
          />
        </div>
      </div>
    </div>
    <div v-if="partsList.length > 0">
      <button class="text-sm text-bluevk" @click="displayPartsList()">
        Détails de contenance
      </button>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import stockvue from "@/components/Article/stock.vue";
import prixvue from "@/components/Article/prix.vue";
import paniervue from "@/components/Article/panier.vue";
import { checkStock } from "@/hooks/stock/stock.ts";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "articleCatalogue",
  data() {
    return {
      tmp_stock: {},
      tmp_quantity: 1,
      tmp_article: {},
      tmp_prix: {},
      checkbox_value: false,
      showContenance: false,
      openImage: false,
      selectedImage: "",
      systemeFreinage: "",
      coteAssemblage: "",
      typeMontage: "",
      reloadKeyStock: 0,
      reloadKeyPrix: 0,
    };
  },
  props: {
    article: { type: Object, default: () => ({}) },
    tdArticle: { type: Object, default: () => ({}) },
    Ref_fournisseur: String,
    Description: String,
    Code_marque: String,
    Fournisseur: String,
    genericArticleId: Number,
    Images: Array,
    Location: Object,
    Systeme: Object,
    Stock: {
      type: Object,
      default: () => ({
        state: "En rupture",
        stock: 0,
        stockFournisseur: 0,
        adresse: "",
        min: 0,
        max: 0,
        filliale: [],
      }),
    },
    Prix: {
      type: Object,
      default: () => ({
        promo: false,
        prix_achat: 0.0,
        prix_brut: 0.0,
        prix_net: 0.0,
      }),
    },
    quantity: Number,
    compareTabLength: {
      type: Number,
      default: 0,
    },
    partsList: {
      type: Array,
      default: () => [],
    },
    partsListTab: {
      type: Array,
      default: () => [],
    },
    savedSearch: {
      type: String,
      default: "",
    },
  },
  components: {
    stockvue,
    prixvue,
    paniervue,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["searchRefFournisseurGen", "searchRefFournisseur"]),
    async checkQuantity(n) {
      this.tmp_quantity = n;
      this.reloadKeyStock++;
    },
    async displayPartsList() {
      this.showContenance = true;
      const res = await await axios.post(
        `${process.env.VUE_APP_API}/searchEngine/getPartsListInfos`,
        {
          Code_marque: this.Code_marque,
          partsList: this.partsList,
          plateform: this.user.proprietaire,
        }
      );
      this.$emit("displayPartsList", res.data);
    },
    async setFraisdePort(value) {
      this.$emit("setFraisdePort", value);
      this.tmp_article["fraisdeport"] = value;
    },
    async setStateStock(value) {
      this.$emit("setStateStock", value);
    },
    openImages() {
      this.openImage = true;
      if (this.Images.length > 0 && this.Images[0].imageURL800) {
        this.selectedImage = this.Images[0].imageURL800;
      } else if (this.Images.length > 0) {
        this.selectedImage = this.Images[0];
      }
    },
    priorityChecked(article) {
      let fraisdeport = 0;
      if (this.tmp_article.fraisdeport) {
        fraisdeport = this.tmp_article.fraisdeport;
      }
      this.tmp_article = article.art;
      this.tmp_article["fraisdeport"] = fraisdeport;
      this.tmp_prix = article.prix;
    },
  },
  beforeMount() {
    this.tmp_quantity = this.quantity;
    this.tmp_article = this.article;
  },
  mounted() {
    if (this.tdArticle != {} && this.tdArticle.articleCriteria) {
      for (const criteria of this.tdArticle.articleCriteria) {
        if (criteria.criteriaDescription === "Système de freinage") {
          this.systemeFreinage = criteria.formattedValue;
        }
        if (criteria.criteriaDescription === "Côté d'assemblage") {
          this.coteAssemblage = criteria.formattedValue;
        }
        if (criteria.criteriaDescription === "Type de montage") {
          this.typeMontage = criteria.formattedValue;
        }
      }
    }
  },
};
</script>
<style></style>
