<template>
  <div>
    <!-- Modal PDF avec lien -->
    <div
      v-if="openModalPDF"
      class="fixed inset-0 z-50 flex items-center justify-center"
    >
      <!-- Overlay -->
      <div
        class="absolute inset-0 bg-gray-700 opacity-75"
        @click="openModalPDF = false"
      ></div>
      <div
        @click="openModalPDF = false"
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
      </div>

      <!-- Modal Content -->
      <div
        class="relative mx-64 rounded-lg shadow-lg w-full h-full overflow-hidden flex flex-col"
      >
        <!-- Body -->
        <div class="flex-1 overflow-auto">
          <iframe
            :src="factureLink"
            class="w-full h-full"
            frameborder="0"
          ></iframe>
        </div>
      </div>
    </div>

    <!-- Modal Detail Facture -->
    <div
      v-if="showDetailFacture"
      :class="`modal ${
        !showDetailFacture && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showDetailFacture = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 70rem"
        class="z-50 w-11/12 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">Récapitulatif de la facture</p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showDetailFacture = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>
          <div class="flex justify-between">
            <div class="w-1/3 ml-2">
              Référence client:
              {{ detailFacture.Note }}
            </div>

            <!--  -->
            <div
              class="flex flex-col w-14 h-8 justify-end items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="
                  detailFacture.link && detailFacture.link != ''
                    ? openOneFacture(detailFacture.link)
                    : createPDF(detailFacture)
                "
              >
                download
              </span>
              <div>PDF</div>
            </div>
          </div>

          <!--Body-->
          <table class="min-w-full mt-4">
            <div class="overflow-y-auto max-h-[25rem]">
              <thead class="tableHEADTR">
                <tr>
                  <th
                    class="px-6 w-44 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Réf.
                  </th>
                  <th
                    class="mx-20 w-80 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Description
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Qté
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    P.V.
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Remise
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Px final
                  </th>
                  <th
                    class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  >
                    Total
                  </th>
                </tr>
              </thead>

              <tbody
                v-if="detailFacture.Bdls.length > 0"
                class="bg-white w-fit"
              >
                <div
                  v-for="(bdl, ind) in detailPdf"
                  :key="ind"
                  class="tableHEADTR"
                >
                  <br />
                  <div class="bg-gray-100">BL n°{{ bdl.Numero }}</div>

                  <tr
                    v-for="(r, index) in bdl.Articles"
                    :key="index"
                    class="tableHEADTR"
                  >
                    <td
                      class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="flex flex-row items-center">
                        <span class="ml-4 text-sm leading-5 text-gray-900">
                          {{ r.Ref_fournisseur }}
                        </span>
                      </div>
                    </td>

                    <td
                      class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                      style="overflow: none"
                    >
                      <div class="text-sm leading-5 text-gray-500">
                        {{ r.Description }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div class="text-sm leading-5">
                        {{ r.quantity }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{ parseFloat(r.Prix_euro).toFixed(2) }}
                      €
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      <div v-if="r.PrixFournisseurType != 'prixnet'">
                        {{ r.remise }} %
                      </div>
                      <div v-else>prix net</div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        r.PrixFournisseurType === "prixnet"
                          ? parseFloat(r.Prix_vente).toFixed(2)
                          : parseFloat(
                              r.Prix_euro - r.Prix_euro * (r.remise / 100)
                            ).toFixed(2)
                      }}
                      €
                    </td>
                    <td
                      class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                    >
                      {{
                        r.PrixFournisseurType === "prixnet"
                          ? parseFloat(r.Prix_vente * r.quantity).toFixed(2)
                          : parseFloat(
                              (r.Prix_euro - r.Prix_euro * (r.remise / 100)) *
                                r.quantity
                            ).toFixed(2)
                      }}€
                    </td>
                  </tr>
                </div>
              </tbody>

              <!-- 2 eme  -->
              <tbody v-else class="bg-white w-fit">
                <tr
                  v-for="(r, index) in detailPdf"
                  :key="index"
                  class="tableHEADTR"
                >
                  <td
                    class="px-6 w-44 py-8 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="flex flex-row items-center">
                      <span class="ml-4 text-sm leading-5 text-gray-900">
                        {{ r.Ref_fournisseur }}
                      </span>
                    </div>
                  </td>

                  <td
                    class="px-6 w-80 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                    style="overflow: none"
                  >
                    <div class="text-sm leading-5 text-gray-500">
                      {{ r.Description }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div class="text-sm leading-5">
                      {{ r.quantity }}
                    </div>
                  </td>

                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{ parseFloat(r.Prix_euro).toFixed(2) }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    <div v-if="r.PrixFournisseurType != 'prixnet'">
                      {{ r.remise }} %
                    </div>
                    <div v-else>prix net</div>
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      r.PrixFournisseurType === "prixnet"
                        ? parseFloat(r.Prix_vente).toFixed(2)
                        : parseFloat(
                            r.Prix_euro - r.Prix_euro * (r.remise / 100)
                          ).toFixed(2)
                    }}
                    €
                  </td>
                  <td
                    class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                  >
                    {{
                      r.PrixFournisseurType === "prixnet"
                        ? parseFloat(r.Prix_vente * r.quantity).toFixed(2)
                        : parseFloat(
                            (r.Prix_euro - r.Prix_euro * (r.remise / 100)) *
                              r.quantity
                          ).toFixed(2)
                    }}€
                  </td>
                </tr>
              </tbody>
            </div>
            <thead class="tableHEADTR mt-12 border-t">
              <tr class="tableHEADTR">
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
                <th
                  class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    Total HT : {{ parseFloat(tmpTotalPrice).toFixed(2) }} €
                  </div>
                  <div class="text-sm leading-5 text-gray-500">
                    Total TTC : {{ parseFloat(tmpTotalPriceTTC).toFixed(2) }} €
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                ></th>
              </tr>
            </thead>
          </table>

          <!--Footer-->
          <!-- <div class="flex justify-end pt-2">
          <button
            @click="showDetailFacture = false"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
         </div> -->
        </div>
      </div>
    </div>
    <!-- Fin modal Detail Facture -->
    <div
      v-if="showModifFacture"
      :class="`modal ${
        !showModifFacture && 'opacity-0 pointer-events-none'
      } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
    >
      <div
        @click="showModifFacture = false"
        class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
      ></div>

      <div
        style="max-width: 80rem; max-height: 75rem; overflow: auto"
        class="z-50 w-11/12 mx-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      >
        <div
          class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
        >
          <svg
            class="text-white fill-current"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 18 18"
          >
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </svg>
          <span class="text-sm">(Esc)</span>
        </div>

        <!-- Add margin if you want to see some of the overlay behind the modal-->
        <div class="px-6 py-4 text-left modal-content">
          <!--Title-->
          <div class="flex items-center justify-between pb-3">
            <p class="text-2xl font-bold">
              Mode de règlement facture n°{{ tabModifFacture.Numero }}
            </p>
            <div
              class="z-50 cursor-pointer modal-close"
              @click="showModifFacture = false"
            >
              <svg
                class="text-black fill-current"
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
              >
                <path
                  d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
                />
              </svg>
            </div>
          </div>

          <div class="flex space-x-4">
            <p class="text-[17px] font-bold text-black">Créée par:</p>
            <p class="text-[16px] font-semibold text-black">
              {{ tabModifFacture.Name }}
            </p>
          </div>

          <div
            class="m-auto mt-2 border border-gray-100 bg-gray-50 p-2 rounded shadow-lg z-20"
          >
            <div class="flex justify-between mb-1 mt-4">
              <div class="relative">
                <div
                  id="client_info_display"
                  class="block px-2.5 pb-2.5 pt-4 w-[26rem] h-auto text-gray-900 bg-white rounded-lg border-2 border-sky-100 appearance-none focus:outline-none focus:ring-0 focus:border-sky-300 resize-none"
                >
                  <p class="text-[16px] font-semibold text-gray-900">
                    {{ tabModifFacture.clientInfo[0].Name }}
                  </p>
                  <p class="text-[12px] text-gray-800">
                    {{
                      tabModifFacture.clientInfo[0].NumeroClient
                        ? tabModifFacture.clientInfo[0].NumeroClient + " - "
                        : ""
                    }}
                    {{
                      tabModifFacture.clientInfo[0].Adresse
                        ? tabModifFacture.clientInfo[0].Adresse
                        : ""
                    }}
                    {{
                      tabModifFacture.clientInfo[0].Complement
                        ? ", " + tabModifFacture.clientInfo[0].Complement
                        : ""
                    }}
                    {{
                      tabModifFacture.clientInfo[0].PostalCode
                        ? ", " + tabModifFacture.clientInfo[0].PostalCode
                        : ""
                    }}
                    <span class="text-base font-medium text-gray-900">
                      {{
                        tabModifFacture.clientInfo[0].City
                          ? ", " + tabModifFacture.clientInfo[0].City
                          : ""
                      }}
                    </span>
                  </p>
                </div>
                <label
                  for="client_info_display"
                  class="ml-1 absolute text-base font-bold text-gray-600 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-gray-50 px-2 peer-focus:px-2 peer-focus:text-blue-600"
                  >Client</label
                >
              </div>
            </div>
          </div>
          <div
            class="my-auto px-5 py-2 text-gray-700 bg-gray-50 shadow-lg border"
          >
            <div class="flex justify-between">
              <label class="text-lg font-bold">MODE DE REGLEMENT</label>
              <div class="relative">
                <label class="text-xm mt-6 font-medium text-gray-500"
                  >Montant à payer :
                </label>
                <label class="text-xl font-bold"
                  >{{ financial(setTotalTTC) }} €</label
                >
              </div>
            </div>
            <div class="relative flex justify-center space-x-8 p-1">
              <div v-for="(moyen, ind) in moyenPaiement" :key="ind">
                <div class="flex flex-col">
                  <img
                    class="w-[5rem] border-2 border-sky-300 rounded-md m-auto cursor-pointer hover:shadow hover:bg-gray-200 focus:outline-2 outline-sky-300 shadow-lg"
                    :src="moyen.image"
                    :class="{
                      'pointer-events-none opacity-50':
                        parseFloat(setRendu) > 0,
                    }"
                    @click="setReglement(moyen.abbrev)"
                  />
                  <span
                    style="font-size: 15px"
                    class="m-auto mt-1 font-medium text-black"
                  >
                    {{ moyen.type }}
                  </span>
                </div>
              </div>
            </div>

            <div
              class="relative mt-2 rounded-md shadow-sm"
              v-for="(reglement, index) in tabModifFacture.reglement"
              :key="index"
            >
              <span
                class="absolute inset-y-0 left-0 flex items-center pl-3 text-gray-600"
              >
                <img class="w-9 h-9 m-auto rounded-md" :src="reglement.img" />
              </span>

              <input
                type="number"
                :placeholder="reglement.placeholder"
                :disabled="
                  tabModifFacture.reglement[index].disabled ||
                  reglement.placeholder === 'Reste dû'
                "
                v-model="tabModifFacture.reglement[index].model"
                class="w-2/5 focus:outline-2 outline-sky-300 pl-16 pr-2 py-2 border border-gray-400 text-black font-semibold rounded-md appearance-none"
                @input="checkMaxAmount(index)"
              />
              <input
                @change="errorEncaissement = false"
                type="date"
                :disabled="
                  tabModifFacture.reglement?.[index]?.disabled ||
                  tabModifFacture.reglement?.[index]?.placeholder === 'Reste dû'
                "
                v-model="tabModifFacture.reglement[index].date"
                :min="todayDate"
                class="ml-2 focus:outline-2 outline-sky-300 w-2/5 px-5 py-2 border border-gray-400 rounded-md appearance-none text-black font-semibold"
              />
              <span
                v-if="!reglement.disabled"
                class="w-1/5 px-5 py-2 border-transparent rounded-md appearance-none"
              >
                <span
                  class="mt-2 material-icons-outlined text-red-600 cursor-pointer"
                  @click="removeReglement(index)"
                >
                  clear
                </span>
              </span>
            </div>

            <div class="relative mt-3" v-show="errorPaymentMethod">
              <label class="text-xs font-medium text-red-500"
                >Cette méthode de paiement n'existe pas</label
              >
            </div>
            <div class="relative mt-3" v-show="errorEncaissement">
              <label class="text-xs font-medium text-red-500"
                >Veuillez encaisser la facture</label
              >
            </div>
            <div class="relative mt-3">
              <label class="text-xl font-medium"
                >Reste dû : {{ financial(setRestant) }} €</label
              >
              <label class="text-xs"> - </label>
              <label class="text-xl font-medium"
                >A rendre : {{ financial(setRendu) }} €</label
              >
            </div>
            <!-- <div class="relative mt-8 rounded-md">
              <textarea
                placeholder="Commentaire interne"
                class="block px-2.5 pb-2.5 w-full h-12 max-h-28 text-gray-900 bg-white rounded-lg border-2 border-gray-300 appearance-none shadow-md focus:outline-none focus:ring-0 focus:border-sky-300 resize-none"
                v-model="titreDocument"
              ></textarea>
            </div> -->
          </div>

          <!-- Footer -->
          <div class="flex justify-end">
            <button
              class="mt-4 p-2 bg-blue-500 hover:bg-opacity-25 text-white rounded shadow"
              @click="checkPaymentAndProceed()"
            >
              Modifier
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Fin Modif Mode Reglement -->

    <div class="flex justify-between">
      <searchInput
        placeholder="Rechercher dans factures"
        @searchWord="search(1, 20, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <titrePages :title="'Factures'" />

      <div
        class="flex space-x-6 bg-gray-100 rounded-md shadow mb-2 p-2 w-fit"
        v-if="selectedFacture.length > 1"
      >
        <div class="flex flex-col">
          <label for="allStatus" class="text-sm"
            >Statut de la séléction :</label
          >
          <select
            id="allStatus"
            v-model="allStatus"
            class="rounded-md shadow focus:outline-2 outline-sky-300"
          >
            <option class="text-blue-400" value="Éditée">Éditée</option>
            <option class="text-blue-500" value="Émise">Émise</option>
            <option class="text-red-600" value="Rejetée">Rejetée</option>
            <option class="text-blue-600" value="Reçue">Reçue</option>
            <option class="text-red-600" value="Refusée">Refusée</option>
            <option class="text-orange-600" value="Suspendue">Suspendue</option>
            <option class="text-green-400" value="Paiement en cours">
              Paiement en cours
            </option>
            <option class="text-green-500" value="Encaissée">Encaissée</option>
            <option class="text-green-600" value="Comptabilisée">
              Comptabilisée
            </option>
            <option class="text-cyan-600" value="Archivée">Archivée</option>
          </select>
        </div>
        <button
          v-if="loading"
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          <span class="material-icons-outlined text-xl mr-2 animate-spin">
            refresh
          </span>
          <span>En cours de chargement</span>
        </button>
        <button
          v-else
          class="bg-bluevk text-white hover:bg-blue-900 border border-blue-900 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto border border-bluevk hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="changeStatusSelection()"
        >
          <span class="material-icons-outlined text-xl mr-2"> forward </span>
          <span>Valider</span>
        </button>
      </div>

      <div>
        <button
          v-if="!loadingExport"
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
          @click="exportExcel()"
        >
          <span class="material-icons-outlined text-xl mr-2"> download </span>
          <span>Exporter en Excel</span>
        </button>
        <button
          v-else
          class="bg-green-600 text-white hover:bg-green-700 border border-green-600 shadow-md font-semibold hover:text-white py-1 px-2 h-fit m-auto hover:border-transparent rounded mr-1 flex justify-center items-center"
        >
          <PulseLoader color="#ffff" class="mr-2" />
          <span>Export en cours </span>
        </button>
      </div>
    </div>

    <div class="flex flex-col mt-2">
      <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
        <div
          class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
        >
          <div class="flex">
            <tabsFilter
              :search="professionState"
              @click="searchActive = false"
              @filter="getInfosFacture(1, 20, $event, FdmFilter)"
            />
            <div class="relative mt-2 ml-4 rounded-md">
              <select
                v-model="FdmFilter"
                @click="searchActive = false"
                @change="getInfosFacture(1, 20, professionState, FdmFilter)"
                class="h-10 w-full focus:outline-2 outline-sky-300 text-black p-2 border border-gray-300 rounded-md"
              >
                <option :value="null">Tout</option>
                <option :value="true">Facturation fin de mois</option>
                <option :value="false">Autre</option>
              </select>
            </div>
          </div>

          <table class="min-w-full">
            <thead>
              <tr>
                <th
                  style="width: 1rem"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-600 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <div class="flex space-x-0.5">
                    <input
                      type="checkbox"
                      class="focus:outline-2 outline-sky-300"
                      v-model="selectAll"
                      @change="selectAllFact"
                    />
                    <span>Sélect.</span>
                  </div>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Statut</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Réf client</span>
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Date</span>
                  <span
                    v-if="showSortDate"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByDate(),
                        (showReverseDate = true),
                        (showSortDate = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseDate"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByDate(),
                        (showSortDate = true),
                        (showReverseDate = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Numéro</span>
                  <span
                    v-if="showSortNumero"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByNumero(),
                        (showReverseNumero = true),
                        (showSortNumero = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseNumero"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByNumero(),
                        (showSortNumero = true),
                        (showReverseNumero = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Client</span>
                  <span
                    v-if="showSortAlpha"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByAlpha(),
                        (showReverseAlpha = true),
                        (showSortAlpha = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseAlpha"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByAlpha(),
                        (showSortAlpha = true),
                        (showReverseAlpha = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>Créé par</span>
                  <span
                    v-if="showSortUsername"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByUsername(),
                        (showReverseUsername = true),
                        (showSortUsername = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseUsername"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByUsername(),
                        (showSortUsername = true),
                        (showReverseUsername = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>HT</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  <span>TTC</span>
                  <span
                    v-if="showSortTotalPrice"
                    class="material-icons-outlined text-orange-500 cursor-pointer ml-2 text-sm"
                    @click="
                      sortByTotalPrice(),
                        (showReverseTotalPrice = true),
                        (showSortTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                  <span
                    v-if="showReverseTotalPrice"
                    class="material-icons-outlined text-blue-500 cursor-pointer ml-2 text-sm"
                    @click="
                      reverseByTotalPrice(),
                        (showSortTotalPrice = true),
                        (showReverseTotalPrice = false)
                    "
                    >filter_alt</span
                  >
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  PDF
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                >
                  ACTION
                </th>
                <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
              </tr>
            </thead>

            <tbody class="bg-white">
              <tr
                v-for="(u, index) in infosFacture"
                :key="index"
                class="cursor-pointer hover:bg-gray-100"
              >
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                  style="width: 1rem"
                >
                  <input
                    type="checkbox"
                    class="focus:outline-2 outline-sky-300"
                    :value="u"
                    v-model="selectedFacture"
                    :checked="selectAll"
                  />
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <select
                    v-model="u.status"
                    class="rounded-md shadow focus:outline-2 outline-sky-300"
                    @change="saveStatus(u._id, u)"
                  >
                    <option class="text-blue-400" value="Éditée">Éditée</option>
                    <option class="text-blue-500" value="Émise">Émise</option>
                    <option class="text-red-600" value="Rejetée">
                      Rejetée
                    </option>
                    <option class="text-blue-600" value="Reçue">Reçue</option>
                    <option class="text-red-600" value="Refusée">
                      Refusée
                    </option>
                    <option class="text-orange-600" value="Suspendue">
                      Suspendue
                    </option>
                    <option class="text-green-400" value="Paiement en cours">
                      Paiement en cours
                    </option>
                    <option class="text-green-500" value="Encaissée">
                      Encaissée
                    </option>
                    <option class="text-green-600" value="Comptabilisée">
                      Comptabilisée
                    </option>
                    <option class="text-cyan-600" value="Archivée">
                      Archivée
                    </option>
                  </select>
                </td>
                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap overflow-x-auto"
                >
                  <p class="max-w-[12rem]">{{ u.Note }}</p>
                </td>
                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex items-center">
                    <!-- <div class="flex-shrink-0 w-10 h-10">
                      <img
                        class="w-10 h-10 rounded-full"
                        src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                        alt
                      />
                    </div>-->

                    <div class="ml-4">
                      <div class="text-sm font-medium leading-5 text-gray-900">
                        {{ getFRDate(u.Date) }}
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="text-sm leading-5 text-gray-900">
                    {{ u.Numero }}
                    <span
                      @click="openModalDetail(u._id, u)"
                      title="Plus d'informations"
                      class="material-icons-outlined text-base text-cyan-600 cursor-pointer"
                    >
                      info
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Client }}
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  {{ u.Name }}
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice).toFixed(2) }} €
                </td>

                <td
                  v-if="u.TotalPriceTTC"
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPriceTTC).toFixed(2) }} €
                </td>
                <td
                  v-else
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  {{ parseFloat(u.TotalPrice + u.TotalPrice * 0.2).toFixed(2) }}
                  €
                </td>

                <td
                  class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                >
                  <!-- <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                    v-if="u.IsProcessed === true"
                    ><span class="material-icons-outlined"> done </span></span
                  >
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-red-800 bg-red-100 rounded-full"
                    v-else
                    ><span class="material-icons-outlined">close</span></span
                  > -->
                  <div class="flex">
                    <div class="flex flex-row relative">
                      <span
                        class="material-icons-outlined mr-4 cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="openFacture(u)"
                      >
                        visibility
                      </span>
                      <span
                        class="material-icons-outlined cursor-pointer text-blue-600 hover:text-opacity-25"
                        @click="setEmails(u, index)"
                      >
                        email
                      </span>
                      <modalFacture
                        v-if="showEmail[index]"
                        :showEmail="showEmail[index]"
                        :ind="index"
                        :objet="u"
                        :tabFacture="infosFacture[index].clientInfo[0]"
                        :tabTempInfosFacture="infosFacture"
                        @close="showEmail[index] = false"
                      />
                    </div>

                    <span
                      class="material-icons-round cursor-pointer text-blue-600 hover:text-opacity-25"
                      @click="
                        u.link && u.link != ''
                          ? openOneFacture(u.link)
                          : createPDF(u)
                      "
                    >
                      download
                    </span>
                    <div
                      v-if="loadingPDF"
                      class="absolute top-0 left-0 w-full h-full flex items-center justify-center bg-gray-100 bg-opacity-20"
                    >
                      <div class="bg-white py-5 px-20 rounded border shadow">
                        <!-- <svg
                          class="animate-spin h-10 w-10 text-blue-500 mx-auto"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                        >
                          <path stroke="none" d="M0 0h24v24H0z"></path>
                          <circle cx="12" cy="12" r="1"></circle>
                          <circle cx="12" cy="5" r="1"></circle>
                          <circle cx="12" cy="19" r="1"></circle>
                        </svg> -->
                        <div class="loader"></div>
                        <p class="text-center mt-2">Chargement...</p>
                      </div>
                    </div>
                  </div>
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                >
                  <div class="flex" @click="showModalModifFacture(u)">
                    <span
                      class="material-icons-outlined mr-4 cursor-pointer"
                      :class="
                        setRestantPerLine(u) > 0.0
                          ? ' text-orangevk'
                          : 'text-green-400'
                      "
                      style="font-size: 26px"
                    >
                      edit
                    </span>
                    <span
                      class="material-icons-round mt-4 -ml-7 cursor-pointer"
                      :class="
                        setRestantPerLine(u) > 0.0
                          ? 'animate-bounce text-orangevk'
                          : 'text-green-400'
                      "
                      style="font-size: 20px"
                    >
                      payments
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>

          <pageNumber
            v-if="!searchActive"
            :pageStart="pageStart"
            :pageEnd="20"
            :pageNext="pageNext"
            :tabList="infosFactureLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(infosFactureLength / 20)"
            :clickFunction="
              (arg1, arg2) => {
                getInfosFacture(arg1, arg2, professionState, FdmFilter);
              }
            "
            :currentPage="currentPage"
            :typeList="'factures'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />

          <pageNumber
            v-if="searchActive"
            :pageStart="pageStart"
            :pageEnd="20"
            :pageNext="pageNext"
            :tabList="searchLength"
            :pageTab="pageTab"
            :pageTotal="Math.ceil(searchLength / 20)"
            :clickFunction="
              (arg1, arg2) => {
                search(arg1, arg2, searchTerm, professionState);
              }
            "
            :currentPage="currentPage"
            :typeList="'factures'"
            @update:start="pageStart = $event"
            @update:tab="pageTab = $event"
            @update:next="pageNext = $event"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import * as XLSX from "xlsx";
import { mapActions, mapGetters } from "vuex";
// import {sendemailfacture} from "@/hooks/email/emailFacture";
// import {sendemailfacturepro} from "@/hooks/email/emailGroupebdl";
import { createfacture } from "@/hooks/pdf/facture";
import { createfacturepro } from "@/hooks/pdf/groupebdl";
import { useToast } from "vue-toastification";
import modalFacture from "@/components/Email/modalFacture";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import titrePages from "@/components/Text/grandeTitre.vue";
import tabsFilter from "@/components/Tables/tabsFilter.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  name: "MyFacture",
  data: () => ({
    Factures: [],
    infosFacture: [],
    infosFactureTmp: [],
    tabModifFacture: null,
    detailPdf: [],
    showModifFacture: false,
    showDetailFacture: false,
    showSortDate: true,
    showReverseDate: false,
    showSortNumero: true,
    showReverseNumero: false,
    showSortAlpha: true,
    showReverseAlpha: false,
    showSortUsername: true,
    showReverseUsername: false,
    showSortTotalPrice: true,
    showReverseTotalPrice: false,
    loadingExport: false,
    index: 0,
    indexMode: 0,
    inputFilter: "",
    regPaye: "",
    moyenPaiement: [
      {
        type: "Espèce",
        abbrev: "es",
        image: require("@/assets/icons_payments/ESP.png"),
      },
      {
        type: "Carte Bancaire",
        abbrev: "cb",
        image: require("@/assets/icons_payments/CB.png"),
      },
      {
        type: "Chèque Comptant",
        abbrev: "cc",
        image: require("@/assets/icons_payments/CHQ.png"),
      },
      {
        type: "LCR",
        abbrev: "lc",
        image: require("@/assets/icons_payments/LCR.png"),
      },
      {
        type: "Virement",
        abbrev: "vi",
        image: require("@/assets/icons_payments/Virement.png"),
      },
      {
        type: "Reste dû",
        abbrev: "rd",
        image: require("@/assets/icons_payments/resteDu.png"),
      },
    ],
    errorPaymentMethod: false,

    showEmail: [],
    errors: [],

    infoInFacture: false,
    detailFacture: [],
    selectAll: [],
    selectedFacture: [],
    allStatus: "",
    loading: false,
    loadingPDF: false,

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    infosFactureLength: 0,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    openModalPDF: false,
    factureLink: "",
    professionState: null,
    selectedFilter: "all",
    FdmFilter: null,

    reglement_reel: [],
  }),
  components: {
    modalFacture,
    pageNumber,
    searchInput,
    PulseLoader,
    titrePages,
    tabsFilter,
  },
  computed: {
    ...mapGetters(["user", "plateform", "searchFilters"]),
    setTotalTTC() {
      return (
        this.tabModifFacture.TotalPrice + this.tabModifFacture.TotalPrice * 0.2
      );
    },
    setRestant() {
      var restant = 0;
      var paye = 0;

      for (const element of this.tabModifFacture.reglement) {
        if (
          element.placeholder !== "Reste dû" &&
          element.placeholder !== "Rendu"
        ) {
          paye += parseFloat(element.model);
        }
      }

      restant = parseFloat(this.setTotalTTC) - parseFloat(paye);

      if (restant < 0) {
        restant = 0;
      }

      return parseFloat(restant).toFixed(2);
    },
    setRendu() {
      var rendu = 0;
      var paye = 0;
      this.tabModifFacture.reglement.forEach((element) => {
        if (
          element.placeholder !== "Reste dû" &&
          element.placeholder !== "Rendu"
        ) {
          paye = paye + parseFloat(element.model);
        }
      });
      if (paye > this.setTotalTTC) {
        rendu = Math.abs(paye - this.setTotalTTC);
      }
      return rendu;
    },
  },
  methods: {
    ...mapActions(["setfactureselected", "setSearchFilters"]),
    getFRDate(date) {
      return getFrDate(date);
    },
    setTotalTTCPerLine(tab) {
      return tab?.TotalPrice + tab?.TotalPrice * 0.2;
    },
    setRestantPerLine(tab) {
      var paye = 0;
      var restant = 0;
      tab?.reglement.forEach((element) => {
        if (element.placeholder !== "Reste dû") {
          paye = paye + parseFloat(element.model);
        }
      });
      restant = parseFloat(this.setTotalTTCPerLine(tab)) - parseFloat(paye);
      if (restant < 0) {
        restant = 0;
      }
      return parseFloat(restant).toFixed(2);
    },
    openFacture(facture) {
      this.setfactureselected(facture);
      this.$router.push("/detailFacture");
    },
    getDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    getDatePaiement() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 2).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();

      return dd + "/" + mm + "/" + yyyy;
    },
    getInfosFacture(skipValue, limitValue, pro, fdm) {
      this.currentPage = skipValue;
      this.professionState = pro;
      this.fdmState = fdm;
      axios
        .post(`${process.env.VUE_APP_API}/facture/getByOwner/getpage`, {
          plateform: this.user.proprietaire,
          skip: (skipValue - 1) * 20,
          limit: limitValue,
          pro: pro,
          fdm: fdm,
        })
        .then((response) => {
          console.log(response.data);
          this.infosFacture = response.data;
          this.getInfosFactureLength();
          this.setSearchFilters({
            keyword: "",
            filter: this.professionState,
            filter_plus: this.FdmFilter,
            url: "/facture",
            pagination: this.currentPage,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getInfosFactureLength() {
      axios
        .post(`${process.env.VUE_APP_API}/facture/getbyowner/count`, {
          plateform: this.user.proprietaire,
          pro: this.professionState,
          fdm: this.fdmState,
        })
        .then((response) => {
          this.infosFactureLength = response.data;
          console.log(response.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async openModalDetail(idFacture, article) {
      let data;
      if (
        (article.Bdls && article.Bdls.length > 0) ||
        (article.Articles && article.Articles.length > 0)
      ) {
        data = article;
      } else {
        await axios
          .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
            id: idFacture,
          })
          .then((response) => {
            data = response.data;
          })
          .catch((error) => {
            console.error("Error fetching facture details:", error);
          });
      }
      this.detailFacture = data;

      if (data.Bdls && data.Bdls.length > 0) {
        this.detailPdf = data.Bdls;
      } else {
        this.detailPdf = data.Articles;
      }

      this.tmpTotalPrice = data.TotalPrice;
      this.tmpTotalPriceTTC = data.TotalPrice + data.TotalPrice * 0.2;

      this.showDetailFacture = true;
    },

    setReglement(method) {
      const today = new Date();
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0");
      const day = today.getDate().toString().padStart(2, "0");

      const resteDuIndex = this.tabModifFacture.reglement.findIndex(
        (p) => p.placeholder === "Reste dû"
      );
      if (resteDuIndex !== -1) {
        this.tabModifFacture.reglement.splice(resteDuIndex, 1);
      }

      let montant = 0.0;
      if (this.setTotalTTC >= 0.0) {
        montant =
          this.setRestant > 0.0 ? parseFloat(this.setRestant).toFixed(2) : 0.0;
      } else if (this.setTotalTTC < 0.0) {
        montant =
          this.setRendu > 0.0
            ? -parseFloat(this.setRendu).toFixed(2)
            : parseFloat(this.setRestant).toFixed(2);
      } else {
        montant = parseFloat(this.setTotalTTC).toFixed(2);
      }

      switch (method) {
        case "es":
          this.tabModifFacture.reglement.push({
            img: require("@/assets/icons_payments/ESP.png"),
            icon: "payments",
            placeholder: "Espèce",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cb":
          this.tabModifFacture.reglement.push({
            img: require("@/assets/icons_payments/CB.png"),
            icon: "credit_card",
            placeholder: "Carte Bancaire",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "cc":
          this.tabModifFacture.reglement.push({
            img: require("@/assets/icons_payments/CHQ.png"),
            icon: "money",
            placeholder: "Chèque Comptant",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "vi":
          this.tabModifFacture.reglement.push({
            img: require("@/assets/icons_payments/Virement.png"),
            icon: "account_balance",
            placeholder: "Virement",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "lc":
          this.tabModifFacture.reglement.push({
            img: require("@/assets/icons_payments/LCR.png"),
            icon: "account_balance",
            placeholder: "LCR",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        case "rd":
          this.tabModifFacture.reglement.push({
            img: require("@/assets/icons_payments/resteDu.png"),
            icon: "account_balance",
            placeholder: "Reste dû",
            model: montant,
            date: `${year}-${month}-${day}`,
            disabled: false,
            user: this.user.username,
          });
          this.errorPaymentMethod = false;
          break;
        default:
          this.errorPaymentMethod = true;
          break;
      }
    },
    removeReglement(index) {
      this.tabModifFacture.reglement.splice(index, 1);

      const resteDuIndex = this.tabModifFacture.reglement.findIndex(
        (p) => p.placeholder === "Reste dû"
      );

      if (resteDuIndex !== -1) {
        this.tabModifFacture.reglement[resteDuIndex].model = this.setRestant;
      }
    },
    checkPaymentAndProceed() {
      const hasSelectedPaymentMethod =
        this.tabModifFacture.reglement.length > 0;

      if (hasSelectedPaymentMethod) {
        this.tabModifFacture.reglement = this.tabModifFacture.reglement.filter(
          (p) => p.placeholder === "Reste dû" || parseFloat(p.model) !== 0
        );

        const resteDuIndex = this.tabModifFacture.reglement.findIndex(
          (p) => p.placeholder === "Reste dû"
        );

        if (resteDuIndex !== -1) {
          const restant = parseFloat(this.setRestant);
          if (restant > 0) {
            this.tabModifFacture.reglement[resteDuIndex].model =
              restant.toFixed(2);
            this.updateFacture();
          } else if (restant === 0) {
            this.tabModifFacture.reglement[resteDuIndex].model =
              restant.toFixed(2);
            this.updateFacture();
          } else if (
            parseFloat(this.tabModifFacture.reglement[resteDuIndex].model) === 0
          ) {
            this.toast.warning(" Reste dù ne peut pas être 0 !", {
              position: "bottom-right",
              timeout: 2000,
            });
          } else {
            this.updateFacture();
          }
        } else {
          this.updateFacture();
        }
      } else {
        this.toast.warning("Veuillez sélectionner un mode de paiement !", {
          position: "bottom-right",
          timeout: 2000,
        });
      }
    },
    checkMaxAmount(index) {
      let maxAmount = parseFloat(this.setTotalTTC) || 0;
      let payments = this.tabModifFacture.reglement;
      let enteredAmount = parseFloat(payments[index].model) || 0;

      let sumPayments = payments.reduce(
        (acc, p) =>
          acc + (p.placeholder !== "Reste dû" ? parseFloat(p.model) || 0 : 0),
        0
      );

      if (payments[index].placeholder === "Espèce") {
        if (sumPayments > maxAmount) {
          this.setRendu = (sumPayments - maxAmount).toFixed(2);
        } else {
          this.setRendu = "0.00";
        }
      } else {
        if (maxAmount >= 0) {
          if (enteredAmount < 0) payments[index].model = "0.00";
          if (sumPayments > maxAmount) {
            payments[index].model = (
              enteredAmount -
              (sumPayments - maxAmount)
            ).toFixed(2);
          }
        } else {
          if (enteredAmount > 0) payments[index].model = "0.00";
          if (sumPayments < maxAmount) {
            payments[index].model = (
              enteredAmount -
              (sumPayments - maxAmount)
            ).toFixed(2);
          }
          if (enteredAmount < maxAmount) {
            payments[index].model = maxAmount.toFixed(2);
          }
          if (enteredAmount < maxAmount) {
            payments[index].model = maxAmount.toFixed(2);
          }
        }

        if (index > 0) {
          let previousPayments = payments
            .slice(0, index)
            .reduce(
              (acc, p) =>
                acc +
                (p.placeholder !== "Reste dû" ? parseFloat(p.model) || 0 : 0),
              0
            );
          let remainingAmount = maxAmount - previousPayments;

          if (maxAmount >= 0 && enteredAmount > remainingAmount) {
            payments[index].model = remainingAmount.toFixed(2);
          }
          if (maxAmount < 0 && enteredAmount < remainingAmount) {
            payments[index].model = remainingAmount.toFixed(2);
          }
        }
      }

      if (maxAmount >= 0 && parseFloat(payments[index].model) < 0) {
        payments[index].model = "0.00";
      }
      if (maxAmount < 0) {
        let currentValue = parseFloat(payments[index].model);

        if (currentValue < maxAmount) {
          payments[index].model = maxAmount.toFixed(2);
        } else if (currentValue > 0) {
          payments[index].model = "0.00";
        }
      }

      const resteDuIndex = this.tabModifFacture.reglement.findIndex(
        (p) => p.placeholder === "Reste dû"
      );

      if (resteDuIndex !== -1) {
        this.tabModifFacture.reglement[resteDuIndex].model = this.setRestant;
      }

      this.$forceUpdate();
    },

    setEmails(objet, index) {
      if (
        objet.clientInfo[0] &&
        objet.clientInfo[0].Emails &&
        objet.clientInfo[0].Emails.length > 0
      ) {
        objet.clientInfo[0].Emails.forEach((element, index) => {
          if (element.facture == false) {
            objet.clientInfo[0].Emails.splice(index, 1);
          } else {
            this.checkForm(this.plateform.Email, element.address);
          }
        });
        this.showEmail[index] = !this.showEmail[index];
      } else {
        this.checkForm(this.plateform.Email, objet.clientInfo[0].email);
        this.showEmail[index] = !this.showEmail[index];
      }
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    checkForm(emailFrom, emailTo) {
      console.log(emailTo);
      this.errors = [];
      if (!emailFrom || !emailTo) {
        this.errors = [];
        this.errors.push("E-mail obligatoire.");
      } else if (!this.validEmail(emailFrom) || !this.validEmail(emailTo)) {
        this.errors = [];
        this.errors.push("E-mail incorrect.");
      } else {
        this.errors = [];
      }
    },
    // sendEmail(objet, index, emailFrom, emailTo) {
    //   if (this.errors.length > 0) {
    //     this.toast.info("Vérifiez l'adresse mail !", {
    //       position: "bottom-right",
    //       timeout: 1000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: false,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //   } else {
    //   if (objet.Bdls.length>0) {
    //     sendemailfacturepro(objet, emailFrom, emailTo);
    //   } else {
    //     sendemailfacture(objet, emailFrom, emailTo);
    //   }
    //     this.toast.success("Facture envoyée !", {
    //       position: "bottom-right",
    //       timeout: 1000,
    //       closeOnClick: true,
    //       pauseOnFocusLoss: true,
    //       pauseOnHover: true,
    //       draggable: true,
    //       draggablePercent: 0.6,
    //       showCloseButtonOnHover: false,
    //       hideProgressBar: false,
    //       closeButton: "button",
    //       icon: true,
    //       rtl: false,
    //     });
    //     this.showEmail[index] = false;
    //   }
    // },
    async createPDF(objet) {
      this.loadingPDF = true;
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet.Date);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (objet.regroupe) {
        await this.createPDFfacture(objet._id, dateedition, datepdf);
      } else {
        if (dateedition == datepdf) {
          if (objet.TotalPrice >= 0) {
            createfacture(objet, false, false);
          } else {
            createfacture(objet, false, true);
          }
        } else if (objet.TotalPrice >= 0) {
          createfacture(objet, true, false);
        } else {
          createfacture(objet, true, true);
        }
      }
      this.loadingPDF = false;
    },
    async createPDFfacture(id, dateedition, datepdf) {
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getAllBdlsById`, {
          id: id,
        })
        .then((objet) => {
          console.log(objet);
          if (dateedition == datepdf) {
            if (objet.data.TotalPrice >= 0) {
              createfacturepro(objet.data, false, false);
            } else {
              createfacturepro(objet.data, false, true);
            }
          } else if (objet.data.TotalPrice >= 0) {
            createfacturepro(objet.data, true, false);
          } else {
            createfacturepro(objet.data, true, true);
          }
        })
        .catch((error) => {
          console.log(error);
          this.loadingPDF = false;
        });
    },
    openOneFacture(link) {
      this.showDetailFacture = false;
      this.openModalPDF = true;
      this.factureLink = link;
    },
    showModalModifFacture(Facture) {
      this.tabModifFacture = Facture;
      this.showModifFacture = true;
    },
    updateFacture() {
      const resteDuIndex = this.tabModifFacture.reglement.findIndex(
        (p) => p.placeholder === "Reste dû"
      );
      if (resteDuIndex === -1) {
        const today = new Date();
        const year = today.getFullYear();
        const month = (today.getMonth() + 1).toString().padStart(2, "0");
        const day = today.getDate().toString().padStart(2, "0");

        this.tabModifFacture.reglement.push({
          img: require("@/assets/icons_payments/resteDu.png"),
          icon: "account_balance",
          placeholder: "Reste dû",
          model: this.setRestant,
          date: `${year}-${month}-${day}`,
          disabled: false,
          user: this.user.username,
        });
      }
      let hasValidPayment = this.tabModifFacture.reglement.some((reg) => {
        let amount = parseFloat(reg.model) || 0;
        return amount > 0 || amount < 0;
      });

      if (hasValidPayment) {
        this.tabModifFacture.reglement = this.tabModifFacture.reglement.filter(
          (reg) => {
            let amount = parseFloat(reg.model) || 0;
            let isResteDu = reg.placeholder === "Reste dû";
            let keep = isResteDu || amount !== 0;

            return keep;
          }
        );
      }

      let reglement_reel_tmp = this.tabModifFacture.reglement.map((item) => ({
        ...item,
      }));

      // Vérification et ajout d'un élément "Rendu" dans reglement_reel_tmp
      if (
        reglement_reel_tmp.filter((e) => e.placeholder == "Rendu").length == 0
      ) {
        reglement_reel_tmp.push({
          icon: "cash",
          placeholder: "Rendu",
          model: parseFloat(this.setRendu).toFixed(2),
          date: new Date(),
          disabled: true,
          user: this.user.username,
        });
      }

      this.reglement_reel = reglement_reel_tmp;

      // Si plus d'un paiement en espèces est trouvé, on ajuste la valeur du dernier paiement en espèces
      const cashPayments = this.tabModifFacture.reglement
        .map((item, index) => ({ item, index }))
        .filter((entry) => entry.item.placeholder === "Espèce");

      if (cashPayments.length > 0) {
        const lastCashIndex = cashPayments[cashPayments.length - 1].index;
        const currentCashValue = parseFloat(
          this.tabModifFacture.reglement[lastCashIndex].model
        );
        const renduValue = parseFloat(this.setRendu);
        const newCashValue = (currentCashValue - renduValue).toFixed(2);

        this.tabModifFacture.reglement[lastCashIndex].model = newCashValue;
      }
      axios
        .put(
          `${process.env.VUE_APP_API}/facture/${this.tabModifFacture._id}`,
          this.tabModifFacture
        )
        .then(() => {
          console.log(" Update confirme !");
          this.toast.success("Les modifications ont été enregistrées", {
            position: "bottom-right",
            timeout: 2000,
          });
          this.showModifFacture = false;
        })
        .catch((error) => {
          console.error(" API update erreur:", error);
        });
      this.showModifFacture = false;
    },
    sortByDate() {
      this.infosFacture.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    reverseByDate() {
      this.sortDate = this.infosFacture.sort((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
      this.sortDate.reverse((a, b) => {
        if (a.Date < b.Date) {
          return -1;
        }
        if (a.Date > b.Date) {
          return 1;
        }
        return 0;
      });
    },
    sortByNumero() {
      this.infosFacture.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
    },
    reverseByNumero() {
      this.sortNumero = this.infosFacture.sort((a, b) => {
        if (a.Numero < b.Numero) {
          return -1;
        }
        if (a.Numero > b.Numero) {
          return 1;
        }
        return 0;
      });
      this.sortNumero.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByAlpha() {
      this.infosFacture.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    reverseByAlpha() {
      this.sortAlpha = this.infosFacture.sort((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
      this.sortAlpha.reverse((a, b) => {
        if (a.Client < b.Client) {
          return -1;
        }
        if (a.Client > b.Client) {
          return 1;
        }
        return 0;
      });
    },
    sortByUsername() {
      this.infosFacture.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    reverseByUsername() {
      this.sortUsername = this.infosFacture.sort((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
      this.sortUsername.reverse((a, b) => {
        if (a.username < b.username) {
          return -1;
        }
        if (a.username > b.username) {
          return 1;
        }
        return 0;
      });
    },
    sortByTotalPrice() {
      this.infosFacture.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    reverseByTotalPrice() {
      this.sortTotalPrice = this.infosFacture.sort((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
      this.sortTotalPrice.reverse((a, b) => {
        if (a.TotalPrice < b.TotalPrice) {
          return -1;
        }
        if (a.TotalPrice > b.TotalPrice) {
          return 1;
        }
        return 0;
      });
    },
    filterByValue(value) {
      var filterTab = [];
      if (value != "") {
        this.infosFacture = this.infosFactureTmp;
        this.infosFacture.filter((obj) => {
          //loop through each object
          for (const key in obj) {
            if (
              obj[key].toString().toLowerCase().includes(value.toLowerCase())
            ) {
              filterTab.push(obj);
              break;
            }
          }
        });
        this.infosFacture = filterTab;
      }
    },
    saveStatus(id, data) {
      axios.put(`${process.env.VUE_APP_API}/facture/${id}`, data).then(() => {
        this.toast.success("Statut enregistré !", {
          position: "bottom-right",
          timeout: 1000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      });
    },
    async changeStatusSelection() {
      this.loading = true;
      for (const [index, line] of this.selectedFacture.entries()) {
        await axios.put(`${process.env.VUE_APP_API}/facture/${line._id}`, {
          status: this.allStatus,
        });
      }
      this.loading = false;
      this.toast.success("Statuts enregistrés !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
      this.selectedFacture = [];
      this.selectAll = [];
      this.getInfosFacture(
        this.currentPage,
        20,
        this.professionState,
        this.FdmFilter
      );
    },
    selectAllFact() {
      for (const [index, line] of this.infosFacture.entries()) {
        if (this.selectAll == true) {
          this.selectedFacture[index] = line;
        } else {
          this.selectedFacture = [];
        }
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      this.professionState = null;
      this.FdmFilter = null;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosFacture(
          skipValue,
          limitValue,
          this.professionState,
          this.FdmFilter
        );
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/facture/searchInPlateform`, {
            plateform: this.user.proprietaire,
            searchTerm: searchTerm,
            skip: (skipValue - 1) * 20,
            limit: limitValue,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.infosFacture = response.data.results;
            this.searchLength = response.data.count;
            this.setSearchFilters({
              keyword: this.searchTerm,
              filter: null,
              filter_plus: null,
              url: "/facture",
              pagination: this.currentPage,
            });
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    async getFactures() {
      let plateform_mere = this.user.proprietaire;
      if (this.plateform.plateform_mere) {
        plateform_mere = this.plateform.plateform_mere;
      }
      await axios
        .post(`${process.env.VUE_APP_API}/facture/getbyowner`, {
          uid: plateform_mere,
        })
        .then((response) => {
          this.Factures = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async exportExcel() {
      this.loadingExport = true;
      await this.getFactures();
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(this.Factures);
      XLSX.utils.book_append_sheet(wb, ws, "Factures");

      XLSX.writeFile(wb, "Liste_Factures" + this.user.proprietaire + ".xlsx");
      this.loadingExport = false;

      // this.success("Le fichier a été téléchargé");
    },
    updateResteDuModel(value, index) {
      this.tabModifFacture.reglement[index] = {
        ...this.tabModifFacture.reglement[index],
        model: parseFloat(value).toFixed(2),
      };
    },
    financial(x) {
      return parseFloat(x).toFixed(2);
    },
  },
  mounted() {
    if (this.searchFilters.url == "/facture") {
      this.searchTerm = this.searchFilters?.keyword;
      this.currentPage = this.searchFilters.pagination;
      this.professionState = this.searchFilters.filter;
      this.FdmFilter = this.searchFilters.filter_plus;
    }

    if (this.searchTerm != "") {
      this.search(this.currentPage, 20, this.searchTerm);
    } else {
      this.getInfosFacture(
        this.currentPage,
        20,
        this.professionState,
        this.FdmFilter
      );
    }
    this.pageSwitch();
  },
};
</script>

<style>
.modal {
  transition: opacity 0.25s ease;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 100px;
  height: 100px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
