<template>
  <div
    class="flex overflow-x-auto whitespace-nowrap border-b-2 border-gray-100"
  >
    <button
      class="inline-flex items-center h-12 px-4 py-2 text-sm text-center border-b border-gray-400 sm:text-base dark:border-gray-300 whitespace-nowrap focus:outline-none hover:border-gray-400 dark:hover:border-gray-400"
      :class="{
        'bg-gray-100 text-blue-600 border border-b-0 rounded-t-md':
          selectedFilter === null,
      }"
      @click="
        selectedFilter = null;
        $emit('filter', null);
      "
    >
      Tout
    </button>

    <button
      class="inline-flex items-center h-12 px-4 py-2 text-sm text-center border-b border-gray-400 sm:text-base dark:border-gray-300 whitespace-nowrap focus:outline-none hover:border-gray-400 dark:hover:border-gray-400"
      :class="{
        'bg-gray-100 text-blue-600 border border-b-0 rounded-t-md':
          selectedFilter === true,
      }"
      @click="
        selectedFilter = true;
        $emit('filter', true);
      "
    >
      Professionnel
    </button>

    <button
      class="inline-flex items-center h-12 px-4 py-2 text-sm text-center border-b border-gray-400 sm:text-base dark:border-gray-300 whitespace-nowrap focus:outline-none hover:border-gray-400 dark:hover:border-gray-400"
      :class="{
        'bg-gray-100 text-blue-600 border border-b-0 rounded-t-md':
          selectedFilter === false,
      }"
      @click="
        selectedFilter = false;
        $emit('filter', false);
      "
    >
      Particulier
    </button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedFilter: null,
    };
  },
  props: { search: null },
  watch: {
    search(newValue) {
      this.selectedFilter = newValue;
    },
  },
};
</script>

<style></style>
