<template>
  <div
    v-if="showAddArticles"
    :class="`modal ${
      !showAddArticles && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="showAddArticles = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-full mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-width: 70vw; max-height: 80vh"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 pt-4 text-left modal-content">
        <div class="flex items-center justify-end pb-3">
          <!-- <p class="text-2xl font-bold">Titre</p> -->
          <div
            class="z-50 cursor-pointer modal-close"
            @click="showAddArticles = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex flex-col space-y-2 p-4">
          <div class="flex flex-row space-x-2 items-center">
            <div>
              <input
                v-model="search_Code_marque"
                type="text"
                placeholder="Marque"
                class="p-1 rounded border border-gray-300"
              />
            </div>
            <div>
              <input
                v-model="search_Description"
                type="text"
                placeholder="Description"
                class="p-1 rounded border border-gray-300"
              />
            </div>
            <div>
              <input
                v-model="search_Ref_fournisseur"
                type="text"
                placeholder="Ref_fournisseur"
                class="p-1 rounded border border-gray-300"
              />
            </div>

            <div>
              <input
                v-model="search_Prefixe_tarif"
                type="text"
                placeholder="Prefixe_tarif"
                class="p-1 rounded border border-gray-300"
              />
            </div>
            <div>
              <input
                v-model="search_Code_remise"
                type="text"
                placeholder="Code_remise"
                class="p-1 rounded border border-gray-300"
              />
            </div>
            <div>
              <button
                v-if="
                  search_Code_marque &&
                  (search_Description ||
                    search_Ref_fournisseur ||
                    search_Prefixe_tarif ||
                    search_Code_remise)
                "
                @click="searchArticle()"
                class="p-1 bg-blue-500 text-white rounded shadow"
              >
                Rechercher
              </button>
            </div>
          </div>
          <div
            v-if="articleList.length > 0"
            class="flex flex-row space-x-1 items-center text-sm"
          >
            <button
              class="p-1 bg-green-500 text-white rounded shadow"
              @click="selectAll()"
            >
              tout selectionner
            </button>
            <button
              class="p-1 bg-red-500 text-white rounded shadow"
              @click="unSelectAll()"
            >
              tout deselectionner
            </button>
          </div>
          <div class="grid grid-cols-6 gap-3 max-h-[40vh] overflow-y-auto">
            <div
              v-if="loadingSearchArticle"
              class="flex flex-col space-y-1 p-1 rounded border border-gray-300 text-xs"
            >
              <div
                class="w-32 h-4 rounded border border-gray-200 bg-gray-100 animate-pulse"
              >
                Recherche...
              </div>
              <div
                class="w-32 h-4 rounded border border-gray-200 bg-gray-100 animate-pulse"
              ></div>
              <div
                class="w-32 h-4 rounded border border-gray-200 bg-gray-100 animate-pulse"
              ></div>
              <div
                class="w-32 h-4 rounded border border-gray-200 bg-gray-100 animate-pulse"
              ></div>
              <div
                class="w-32 h-4 rounded border border-gray-200 bg-gray-100 animate-pulse"
              ></div>
            </div>
            <div
              :class="[
                'flex flex-col space-y-1 p-1 rounded border border-gray-300 text-xs cursor-pointer',
                article.selected ? 'bg-blue-100' : '',
              ]"
              v-for="(article, indexArticle) in articleList"
              :key="indexArticle"
              @click="article.selected = !article.selected"
            >
              <div class="text-sm">{{ article.Ref_fournisseur }}</div>
              <div>{{ article.Code_marque }}</div>
              <div>{{ article.Description }}</div>
              <div>{{ article.Prefixe_tarif }}</div>
              <div>{{ article.Code_remise }}</div>
              <div
                class="flex text-gray-400 justify-end"
                v-if="article.selected"
              >
                selectionné
              </div>
            </div>
          </div>
          <div class="flex flex-row space-x-1 items-center justify-end">
            <button
              @click="addToCat()"
              :disabled="loadingAddArticles"
              class="p-1 bg-blue-500 text-white rounded shadow"
            >
              Ajouter
            </button>
            <button
              @click="showAddArticles = false"
              class="p-1 bg-red-500 text-white rounded shadow"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-between">
    <backButton />
    <div class="flex flex-row space-x-2 items-center">
      <button
        @click="openAddArticle()"
        class="p-1 bg-blue-500 text-white rounded shadow"
      >
        Ajouter des articles
      </button>
      <button
        class="bg-gray-500 text-white text-base p-1 rounded shadow"
        @click="exportExcel()"
      >
        Template excel
      </button>
      <label for="dropzone-file">
        <span
          class="p-1.5 bg-green-500 text-white rounded shadow cursor-pointer"
        >
          Importer excel
        </span>
        <input
          id="dropzone-file"
          type="file"
          class="hidden"
          accept=".xlsx, .xls"
          @change="readExcel()"
        />
      </label>
    </div>
  </div>
  <div class="mt-2">
    <div class="text-xl">
      Famille Universelle : {{ sousCategoryUniverselleSelected.name }}
    </div>
  </div>
  <div class="flex flex-col mt-3 sm:flex-row">
    <searchInput
      placeholder="Rechercher des articles"
      @searchWord="search(1, 10, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
      class="mt-2"
    />
  </div>
  <div class="flex flex-row justify-between">
    <div class="flex flex-row items-center space-x-2 mt-2">
      <div
        :class="[
          selectedCount > 0
            ? 'p-1 text-sm text-blue-800 rounded-lg bg-blue-100'
            : 'p-1 text-sm text-gray-800 rounded-lg bg-gray-100',
        ]"
      >
        {{ selectedCount }} sel.
      </div>
      <button
        @click="selectAllArticles()"
        class="p-1 text-sm bg-green-500 text-white rounded"
      >
        Tout sel.
      </button>
      <button
        @click="unselectAllArticles()"
        class="p-1 text-sm bg-red-500 text-white rounded"
      >
        Tout desel.
      </button>
    </div>
    <div>
      <button
        @click="deleteSelection()"
        v-if="selectedCount > 0"
        class="p-1 bg-red-500 text-white text-sm rounded"
      >
        Supprimer la selection
      </button>
    </div>
  </div>
  <div class="flex flex-col mt-2">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                sel.
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Ref
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Marque
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Description
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Segment
              </th>
            </tr>
          </thead>

          <tbody class="bg-white">
            <tr
              v-for="(a, index) in articles"
              :key="index"
              :class="['', a.selected ? 'bg-blue-100' : 'hover:bg-gray-50']"
            >
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <input
                  type="checkbox"
                  class="form-checkbox"
                  v-model="a.selected"
                  @change="saveSelection(a._id, a.selected)"
                />
              </td>
              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                <div class="flex items-center">
                  <div class="ml-4">
                    <div class="text-sm font-medium leading-5 text-gray-900">
                      <span>{{ a.Ref_fournisseur }}</span>
                      <span
                        class="p-1 ml-4 bg-blue-500 text-white rounded shadow"
                      >
                        {{ a.Prefixe_tarif }}</span
                      >
                    </div>
                  </div>
                </div>
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ a.Code_marque }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ a.Description }}
              </td>

              <td class="px-6 py-4 border-b border-gray-200 whitespace-nowrap">
                {{ a.segment }}
              </td>
            </tr>
          </tbody>
        </table>

        <pageNumber
          v-if="!searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="articlesLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(articlesLength / 10)"
          :clickFunction="getArticles"
          :currentPage="currentPage"
          :typeList="'articles'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
        <pageNumber
          v-if="searchActive"
          :pageStart="pageStart"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 10)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, searchTerm)"
          :currentPage="currentPage"
          :typeList="'articles'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import backButton from "@/components/Button/back.vue";
import axios from "axios";
import pageNumber from "../components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import readXlsxFile from "read-excel-file";
import * as XLSX from "xlsx";
export default {
  data() {
    return {
      showAddArticles: false,
      search_Code_marque: "",
      search_Ref_fournisseur: "",
      search_Description: "",
      search_Prefixe_tarif: "",
      search_Code_remise: "",
      loadingSearchArticle: false,
      loadingAddArticles: false,
      articleList: [],
      articles: [],
      selectedCount: 0,

      pageTab: [],
      pageNext: 11,
      pageStart: 1,
      currentPage: 1,
      articlesLength: 0,

      searchActive: false,
      searchTerm: "",
      searchLength: 0,
      searchInProgress: false,
      searchCatchError: false,

      readFile: [],
      formattedFile: [],
    };
  },
  components: {
    backButton,
    pageNumber,
    searchInput,
  },
  computed: {
    ...mapGetters(["user", "sousCategoryUniverselleSelected"]),
  },
  methods: {
    ...mapActions([""]),
    openAddArticle() {
      this.showAddArticles = true;
    },
    async searchArticle() {
      this.articleList = [];
      this.loadingSearchArticle = true;
      let articles = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/searchArticle`,
        {
          plateform: this.user.proprietaire,
          Code_marque: this.search_Code_marque,
          Description: this.search_Description,
          Ref_fournisseur: this.search_Ref_fournisseur,
          Prefixe_tarif: this.search_Prefixe_tarif,
          Code_remise: this.search_Code_remise,
        }
      );
      this.loadingSearchArticle = false;
      this.articleList = articles.data;
    },
    selectAll() {
      this.articleList.forEach((article) => {
        article.selected = true;
      });
    },
    unSelectAll() {
      this.articleList.forEach((article) => {
        article.selected = false;
      });
    },
    async addToCat() {
      this.loadingAddArticles = true;
      let selectedArticles = this.articleList.filter(
        (article) => article.selected
      );

      let articles = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/addArticleToSousCat`,
        {
          plateform: this.user.proprietaire,
          sousCategoryUniverselleId: this.sousCategoryUniverselleSelected._id,
          sousCategoryUniverselleName:
            this.sousCategoryUniverselleSelected.name,
          categoryUniverselleId:
            this.sousCategoryUniverselleSelected.categoryUniverselleId,
          categoryUniverselleName:
            this.sousCategoryUniverselleSelected.categoryUniverselleName,
          username: this.user.username,
          Articles: selectedArticles,
        }
      );
      this.loadingAddArticles = false;
      this.articleList = [];
      this.showAddArticles = false;
      this.getArticles(this.currentPage, 10);
    },
    async getArticles(skipValue, limitValue) {
      this.currentPage = skipValue;
      let getArt = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/getArticles`,
        {
          plateform: this.user.proprietaire,
          sousCategoryUniverselleId: this.sousCategoryUniverselleSelected._id,
          skip: (skipValue - 1) * 10,
          limit: limitValue,
          sort_by: "_id",
          sort_order: -1,
        }
      );
      this.articles = getArt.data.articles;
      this.articlesLength = getArt.data.count;
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getArticles(skipValue, limitValue);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(`${process.env.VUE_APP_API}/configurationUniversel/search`, {
            plateform: this.user.proprietaire,
            search: searchTerm,
            skip: (skipValue - 1) * 10,
            limit: limitValue,
            sort_by: "_id",
            sort_order: -1,
          })
          .then((response) => {
            this.searchInProgress = false;
            this.articles = response.data.articles;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    async saveSelection(_id, value) {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/saveSelection`,
        {
          _id: _id,
          value: value,
        }
      );

      await this.countSelected();
    },
    async countSelected() {
      let countS = await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/countSelected`,
        {
          plateform: this.user.proprietaire,
          sousCategoryUniverselleId: this.sousCategoryUniverselleSelected._id,
        }
      );
      this.selectedCount = countS.data;
    },
    async deleteSelection() {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/deleteSelection`,
        {
          plateform: this.user.proprietaire,
          sousCategoryUniverselleId: this.sousCategoryUniverselleSelected._id,
        }
      );
      this.countSelected();
      this.getArticles(this.currentPage, 10);
    },
    async selectAllArticles() {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/selectAllArticles`,
        {
          plateform: this.user.proprietaire,
          sousCategoryUniverselleId: this.sousCategoryUniverselleSelected._id,
        }
      );
      this.countSelected();
      this.getArticles(this.currentPage, 10);
    },
    async unselectAllArticles() {
      await axios.post(
        `${process.env.VUE_APP_API}/configurationUniversel/unselectAllArticles`,
        {
          plateform: this.user.proprietaire,
          sousCategoryUniverselleId: this.sousCategoryUniverselleSelected._id,
        }
      );
      this.countSelected();
      this.getArticles(this.currentPage, 10);
    },
    async exportExcel() {
      let infos = [
        {
          Ref_fournisseur: "",
          Code_marque: "",
          Prefixe_tarif: "",
          segment: "",
        },
      ];
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(infos);
      XLSX.utils.book_append_sheet(wb, ws, "Template");

      XLSX.writeFile(
        wb,
        "Template_integration_" + this.user.proprietaire + ".xlsx"
      );
    },
    async readExcel() {
      let file = event.target.files ? event.target.files[0] : null;
      if (file.size < 1000000) {
        this.readFile = await readXlsxFile(file);
        this.readFile.splice(0, 1);
        const importExcelArticles = await axios.post(
          `${process.env.VUE_APP_API}/configurationUniversel/importExcelArticles`,
          {
            plateform: this.user.proprietaire,
            data: this.readFile,
            username: this.user.username,
            sousCategoryUniverselleId: this.sousCategoryUniverselleSelected._id,
            sousCategoryUniverselleName:
              this.sousCategoryUniverselleSelected.name,
          }
        );
        // this.formattedFile

        this.getArticles(1, 10);
      }
    },
  },
  beforeMount() {
    if (this.$store.state.user.accountType != "Administrateur") {
      this.$router.push("/dashboard");
    }
  },
  async mounted() {
    await this.getArticles(1, 10);
    this.countSelected();
    this.pageSwitch();
  },
};
</script>
<style></style>
