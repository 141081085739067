<template>
  <modalPanierInst
    v-if="openPanierInst"
    :tab_racine="comptoirSessionActive[0]"
    :client_tab="comptoirSessionActive[0]?.Client[0]"
    :fournisseur="supplier_cart"
    :bc_id="numDoc"
    :prefix_tarif="prefix_tarif_cart"
    :comment="comptoirSessionActive[0]?.Client[0].Name"
    @closeModalLKQ="openPanierInst = $event"
  />

  <div>
    <div
      style="width: 2rem"
      class="flex flex-col space-y-4 bg-white bg-opacity-50 rounded-md py-1 px-0.5 h-fit"
    >
      <div
        v-for="supplier in suppliers"
        :key="supplier.name"
        class="flex space-x-7 m-auto relative"
        @click="
          supplier.cartType == 'Vhi' || supplier.cartType == 'Dsv'
            ? openSupplierCart(supplier.cartType, supplier.name)
            : ''
        "
        @dblclick="openSupplierLink(supplier.link)"
      >
        <img
          :src="supplier.image"
          @mouseover="showName = supplier.name"
          @mouseleave="showName = null"
          class="relative bg-gray-100 hover:bg-gray-200 border border-gray-300 shadow rounded-md px-0.5 cursor-pointer"
          style="width: 28px; height: 24px"
        />

        <!-- Badge panier -->
        <div
          v-if="comptoirSessionActive[0]?.[supplier.cartKey]"
          class="absolute flex justify-end rounded-full w-6 h-5 bg-red-700 text-white"
          style="font-size: 10px; margin-left: -15px"
        >
          <span class="m-auto">{{
            comptoirSessionActive[0]?.[supplier.cartKey]
          }}</span>
        </div>

        <!-- Menu déroulant -->
        <!-- <div
          v-show="showName === supplier.name"
          class="absolute rounded-md m-auto px-1 mt-1 bg-white text-black"
        >
          <img :src="supplier.image" style="width: 300px; height: 250px" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import modalPanierInst from "@/components/Comptoir/modalPanierInst.vue";

export default {
  data() {
    return {
      openPanierInst: false,
      supplier_cart: "",
      prefix_tarif_cart: "",
      numDoc: "",

      showName: null,
      suppliers: [
        {
          name: "LKQ",
          link: "https://www.partsnet.fr/pno.aspx",
          image: require("@/assets/Fournisseurs/LKQ.jpg"),
          cartKey: "vhi_cart_item",
          cartType: "Vhi",
        },
        {
          name: "DASILVA",
          link: "https://www.groupe-dasilva.com/",
          image: require("@/assets/Fournisseurs/DaSilva.jpeg"),
          cartKey: "dsv_cart_item",
          cartType: "Dsv",
        },
        {
          name: "DOYEN",
          link: "https://www.doyen4u.net/",
          image: require("@/assets/Fournisseurs/Doyen.png"),
        },
        {
          name: "IDLP",
          link: "https://www.hubidl.com/",
          image: require("@/assets/Fournisseurs/idlp.jpeg"),
        },
        {
          name: "AUTOPARTNER",
          link: "https://apcat.eu/",
          image: require("@/assets/Fournisseurs/AutoPartner.gif"),
        },
        {
          name: "MEYLE",
          link: "https://www.meyle.com/fr/centre-des-services/catalogue-en-ligne/",
          image: require("@/assets/Fournisseurs/Meyle.jpg"),
        },
        {
          name: "PROCODIS",
          link: "https://catalogue.procodisfrance.com/",
          image: require("@/assets/Fournisseurs/Procodis.png"),
        },
        {
          name: "VANWEZEL",
          link: "https://vwa.autopartscat.com/",
          image: require("@/assets/Fournisseurs/VanWezel.jpg"),
        },
        {
          name: "SKF",
          link: "https://login.tecalliance.net/",
          image: require("@/assets/Fournisseurs/SKF.jpg"),
        },
        {
          name: "KRAFTWERK",
          link: "https://www.kraftwerktools.fr/fr-fr/",
          image: require("@/assets/Fournisseurs/kraftwerk.png"),
        },
        {
          name: "JBM",
          link: "https://www.jbmcamp.com/fr/",
          image: require("@/assets/Fournisseurs/Jbm.png"),
        },
        {
          name: "IGOL",
          link: "https://www.igol.com/",
          image: require("@/assets/Fournisseurs/Igol.jpg"),
        },
        {
          name: "MOTORPARTS",
          link: "https://myshop.mp-i.eu/Account/",
          image: require("@/assets/Fournisseurs/Motorparts.png"),
        },
      ],
    };
  },
  props: { supplier_prefix_tariff: null, doc_id: { type: Number, default: 0 } },
  watch: {
    supplier_prefix_tariff(newValue) {
      if (newValue != null) {
        this.openSupplierCart(newValue, this.supplier_cart);
      }
    },
    doc_id(value) {
      if (value != null) {
        this.numDoc = value;
      }
    },
  },
  components: {
    modalPanierInst,
  },
  computed: {
    ...mapGetters(["comptoirSessionActive"]),
  },
  methods: {
    openSupplierCart(type, supplier) {
      this.supplier_cart = supplier;
      this.prefix_tarif_cart = type;
      this.openPanierInst = true;
    },
    openSupplierLink(link) {
      if (link) {
        window.open(link, "_blank");
      }
    },
  },
};
</script>
