import axios from "axios";

export async function sendA01(
  article: Array<object>,
  login: string,
  logCode: string,
) {
  try {
    const verifyDispoA01 = await axios.post(
      `${process.env.VUE_APP_API}/vanheck/verifyDispoA01`,
      {
        article: article,
        login: login,
        logCode: logCode,
      }
    );
    return verifyDispoA01.data;
  } catch (error) {
    return "Erreur";
  }
}
export async function sendA05(
  infoCommande: Array<object>,
  plateform: string,
  user: string,
  login: string,
  refCommande: number,
  comCommande: string,
  logCode: string,
) {
  try {
    const commanderA05 = await axios.post(
      `${process.env.VUE_APP_API}/vanheck/commanderA05`,
      {
        infoCommande: infoCommande,
        plateform: plateform,
        user: user,
        login: login,
        refCommande: refCommande,
        comCommande: comCommande,
        logCode: logCode,
      }
    );
    return commanderA05.data;
  } catch (error) {
    return "Erreur";
  }
}

export async function manageSupplierOrder(
  data: Array<object>,
  addOrDelete: boolean
) {
  try {
    const supplier_order = await axios.post(
      `${process.env.VUE_APP_API}/commandeFournisseur/manageSupplierOrder`, {
      data: data,
      addOrDelete: addOrDelete
    }
    );
    return supplier_order.data;
  } catch (error) {
    return "Erreur";
  }
}

export async function getSupplierOrder(
  data: Array<object>,
  oneOrAll: boolean
) {
  try {
    const supplier_order = await axios.post(
      `${process.env.VUE_APP_API}/commandeFournisseur/getSupplierOrder`, {
      data: data,
      oneOrAll: oneOrAll
    }
    );
    return supplier_order.data;
  } catch (error) {
    return "Erreur";
  }
}

export async function deleteSupplierOrder(
  data: Array<object>,
  oneOrAll: boolean
) {
  try {
    const supplier_order = await axios.post(
      `${process.env.VUE_APP_API}/commandeFournisseur/deleteSupplierOrder`, {
      data: data,
      oneOrAll: oneOrAll
    }
    );
    return supplier_order.data;
  } catch (error) {
    return "Erreur";
  }
}

export async function saveSupplierOrder(
  data: Array<object>,
  oneOrAll: boolean
) {
  try {
    const supplier_order = await axios.post(
      `${process.env.VUE_APP_API}/commandeFournisseur/saveSupplierOrder`, {
      data: data,
      oneOrAll: oneOrAll
    }
    );
    return supplier_order.data;
  } catch (error) {
    return "Erreur";
  }
}

export async function getById(
  data: Array<object>,
) {
  try {
    const supplier_order = await axios.post(
      `${process.env.VUE_APP_API}/commandeFournisseur/getById`, {
      data: data,
    }
    );
    return supplier_order.data;
  } catch (error) {
    return "Erreur";
  }
}

export async function deleteDocument(
  id: string,
) {
  try {
    const supplier_order = await axios.post(
      `${process.env.VUE_APP_API}/commandeFournisseur/deleteDocument`, {
      id: id,
    }
    );
    return supplier_order.data;
  } catch (error) {
    return "Erreur";
  }
}