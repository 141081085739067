<template>
  <!-- <div class="flex justify-end">
    <button
      class="px-6 py-2 font-medium tracking-wide text-white bg-blue-500 rounded-md hover:bg-opacity-25 focus:outline-none"
      @click="getCommander()"
    >
      Commander
    </button>
  </div> -->

  <div
    v-if="openCreateReception"
    :class="`modal ${
      !openCreateReception && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openCreateReception = false), ($refs.fileInput.value = '')"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 80rem; max-height: 45rem"
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Création de réception</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="(openCreateReception = false), ($refs.fileInput.value = '')"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>

        <!--Body-->

        <div>
          <div class="flex flex-row items-center space-x-4">
            <div class="flex flex-col">
              <span>Référence de commande :</span>
              <input
                type="text"
                v-model="createName"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div class="flex flex-col">
              <span>Commentaire :</span>
              <input
                type="text"
                v-model="createType"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
            <div class="flex flex-col">
              <span>Fournisseur :</span>
              <input
                type="text"
                v-model="createFournisseur"
                class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              />
            </div>
          </div>
          <!-- Table -->
          <div
            class="py-2 my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8"
          >
            <div
              class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
            >
              <table class="min-w-full">
                <thead>
                  <tr>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Code EAN
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Marque
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Préfixe tarif
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Description
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                      v-if="user.accountType == 'Administrateur'"
                    >
                      Prix d'achat
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Quantité réceptionnée
                    </th>
                    <th
                      class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
                    >
                      Quantité commandée
                    </th>
                    <!-- <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th> -->
                  </tr>
                </thead>

                <tbody class="bg-white">
                  <tr
                    v-for="(rec, index) in formattedFile"
                    :key="index"
                    class="cursor-pointer hover:bg-gray-100"
                  >
                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        <span
                          class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                          >{{ rec.Ref_fournisseur }}</span
                        >
                      </div>
                      <div class="text-sm leading-5 text-gray-900">
                        <span
                          class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                          >{{ rec.Code_EAN }}</span
                        >
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ rec.Code_marque }}
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ rec.Prefixe_tarif }}
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        {{ rec.Description }}
                      </div>
                    </td>

                    <td
                      class="px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                      v-if="user.accountType == 'Administrateur'"
                    >
                      <div class="text-sm leading-5 text-gray-900">
                        <input
                          class="p-1"
                          type="number"
                          v-model="rec.prix_net"
                        />
                        <span> €</span>
                      </div>
                    </td>
                    <td
                      class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ rec.quantity_received }}
                    </td>
                    <td
                      class="px-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap"
                    >
                      {{ rec.quantity_asked }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <!--Footer-->
        <div class="flex space-x-2 justify-end pt-2">
          <!-- Créer -->
          <button
            v-if="!isLoading"
            @click="createCommande('EXCEL')"
            class="p-3 px-6 py-3 text-white bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none"
          >
            Créer
          </button>

          <!-- Spinner -->
          <div v-else class="card flex justify-center">
            <ProgressSpinner
              style="width: 45px; height: 45px; stroke: blue"
              strokeWidth="4"
              fill="transparent"
              animationDuration=".8s"
              aria-label="Custom ProgressSpinner"
            />
          </div>

          <!-- Fermer -->
          <button
            @click="(openCreateReception = false), ($refs.fileInput.value = '')"
            class="p-3 px-6 py-3 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="flex justify-between">
    <div id="retour" class="flex justify-start">
      <backButton :route="'/fournisseurs'" />
    </div>
    <titrePages
      class="flex justify-center flex-1"
      :title="'Réception commande fournisseur'"
    />
    <div class="flex space-x-4 items-center">
      <button
        class="bg-orange-600 text-white p-1.5 rounded-md shadow-lg cursor-pointer hover:bg-orange-300 flex items-center"
        @click="exportExcel()"
      >
        <span class="material-icons-outlined text-xl mr-2"> download </span>
        <span>Template Excel</span>
      </button>

      <div
        class="bg-green-600 text-white text-center rounded-md shadow-lg cursor-pointer hover:bg-green-400 flex items-center justify-center py-1 px-1"
      >
        <label class="mt-1 text-base flex items-center">
          <span class="material-icons-outlined text-xl mr-2"> upload </span>
          <span>Importer Excel</span>
          <input
            ref="fileInput"
            id="dropzone-file"
            type="file"
            class="hidden"
            accept=".xlsx, .xls, .csv"
            @change="readExcel($event)"
          />
        </label>
      </div>
    </div>
  </div>

  <div class="flex space-x-6 mt-8 items-center">
    <searchInput
      placeholder="Rechercher dans réception fournisseur"
      @searchWord="search(1, 20, $event)"
      @catchFalse="searchCatchError = $event"
      :searchStarted="searchInProgress"
      :catchError="searchCatchError"
      class="my-auto"
    />

    <div class="my-auto grid grid-cols-4 gap-y-1 gap-x-2">
      <!-- Type -->
      <select
        v-model="search_type"
        @change="getSupplierOrders(1, 20)"
        class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
      >
        <option :value="null" class="hidden">Type de commande</option>
        <option
          v-for="type in tab_search_type"
          :key="type.value"
          :value="type.value"
        >
          {{ type.label }}
        </option>
      </select>

      <!-- Statut -->
      <select
        v-model="search_status"
        @change="getSupplierOrders(1, 20)"
        class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
      >
        <option :value="null" class="hidden">Statut</option>
        <option
          v-for="status in tab_search_status"
          :key="status.value"
          :value="status.value"
        >
          {{ status.label }}
        </option>
      </select>

      <!-- Fournisseur -->
      <select
        v-model="search_supplier"
        @change="getSupplierOrders(1, 20)"
        class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
      >
        <option :value="null" class="hidden">Fournisseur</option>
        <option
          v-for="supplier in tab_search_supplier"
          :key="supplier.value"
          :value="supplier.value"
        >
          {{ supplier.label }}
        </option>
      </select>

      <!-- Archivé -->
      <select
        v-model="search_archived"
        @change="getSupplierOrders(1, 20)"
        class="focus:outline-2 outline-sky-300 p-2 text-sm shadow-sm border border-gray-500 rounded-md"
      >
        <option :value="null" class="hidden">Tout</option>
        <option
          v-for="supplier in tab_search_archived"
          :key="supplier.value"
          :value="supplier.value"
        >
          {{ supplier.label }}
        </option>
      </select>

      <!-- Affichage des filtres sélectionnés -->
      <div
        v-if="search_type"
        class="flex justify-between px-2 py-1 text-blue-500 rounded-md shadow border border-blue-500"
      >
        {{ getLabel(tab_search_type, search_type) }}
        <button
          class="hover:text-sky-400 font-medium"
          @click="clearFilter('search_type')"
        >
          ✕
        </button>
      </div>

      <div
        v-if="search_status"
        class="flex justify-between px-2 py-1 text-blue-500 rounded-md shadow border border-blue-500"
      >
        {{ getLabel(tab_search_status, search_status) }}
        <button
          class="hover:text-sky-400 font-medium"
          @click="clearFilter('search_status')"
        >
          ✕
        </button>
      </div>

      <div
        v-if="search_supplier"
        class="flex justify-between px-2 py-1 text-blue-500 rounded-md shadow border border-blue-500"
      >
        {{ getLabel(tab_search_supplier, search_supplier) }}
        <button
          class="hover:text-sky-400 font-medium"
          @click="clearFilter('search_supplier')"
        >
          ✕
        </button>
      </div>

      <div
        v-if="search_archived"
        class="flex justify-between px-2 py-1 text-blue-500 rounded-md shadow border border-blue-500"
      >
        {{ getLabel(tab_search_archived, search_archived) }}
        <button
          class="hover:text-sky-400 font-medium"
          @click="clearFilter('search_archived')"
        >
          ✕
        </button>
      </div>
    </div>
  </div>

  <div class="">
    <table class="min-w-full mt-3">
      <thead class="bg-gray-50 tableHEADTR">
        <tr>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Type
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Date
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Référence externe
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Référence interne
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Statut
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Fournisseur
          </th>
          <th
            class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
          >
            Document
          </th>
        </tr>
      </thead>
      <tbody class="text-sm bg-white">
        <tr
          v-for="(cmd, index) in orders"
          :key="index"
          @click="goToDetail(cmd)"
          class="tableHEADTR cursor-pointer hover:bg-gray-100"
        >
          <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH">
            <p>
              {{
                cmd.order_type == "INST"
                  ? "Instantanée"
                  : cmd.order_type == "BULK"
                  ? "Stock"
                  : "Manuel"
              }}
            </p>
            <p class="font-medium text-orangevk">N°{{ cmd.order_number }}</p>
            <p v-if="cmd?.remainder?.principal_num" class="text-orangevk">
              Reliquat du n°{{ cmd?.remainder?.principal_num }}
            </p>
          </td>

          <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH">
            <p>{{ getFRDate(cmd.created_at) }}</p>
          </td>

          <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH">
            <p>{{ cmd.supplier_order_reference }}</p>
          </td>

          <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH">
            <p>Réf. interne : {{ cmd.order_reference }}</p>
            <p>Commentaire : {{ cmd.comment }}</p>
          </td>

          <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH">
            <progressBar
              :status="cmd.status"
              :progress="cmd.percentage_status"
            />
          </td>

          <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH">
            <p>{{ cmd.supplier }}</p>
            <p class="text-bluevk">{{ cmd.supplier_prefix_tariff }}</p>
          </td>

          <td class="px-6 border-b border-gray-200 whitespace-nowrap tableTDTH">
            <p v-if="cmd.doc_number" class="">
              {{ cmd.doc_type + " n°" + cmd.doc_number }}
            </p>
            <p v-else class="italic text-gray-600">
              {{
                cmd.doc_number
                  ? cmd.doc_type + " n°" + cmd.doc_number
                  : "Non créé"
              }}
            </p>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Pagination -->
    <pageNumber
      :pageStart="pageStart"
      :pageNext="pageNext"
      :pageEnd="20"
      :tabList="length_orders"
      :pageTab="pageTab"
      :pageTotal="Math.ceil(length_orders / 20)"
      :clickFunction="(arg1, arg2) => getSupplierOrders(arg1, arg2)"
      :currentPage="currentPage"
      :typeList="'commandes'"
      @update:start="pageStart = $event"
      @update:tab="pageTab = $event"
      @update:next="pageNext = $event"
    />
  </div>
</template>
<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import backButton from "@/components/Button/back.vue";
import * as XLSX from "xlsx";
import readXlsxFile from "read-excel-file";
import searchInput from "@/components/searchInput.vue";
import pageNumber from "@/components/pageNumber.vue";
import { backorderStock } from "@/hooks/stock/stock.ts";
import ProgressSpinner from "primevue/progressspinner";
import { success, warning, error } from "@/hooks/tools/toast.ts";
import titrePages from "@/components/Text/grandeTitre.vue";

import {
  createSupplierOrder,
  getAll,
} from "@/hooks/commandeFournisseur/commandeFournisseur.ts";
import { getFrDate } from "@/hooks/tools/date.ts";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "MyCommandes",
  data: () => ({
    orders: [],
    isLoading: false,
    state: "waiting",

    // EXCEL
    openCreateReception: false,
    createName: null,
    createType: null,
    createFournisseur: "",
    readFile: null,
    file: null,
    formattedFile: [],

    // SEARCH
    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    // PAGINATION
    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    length_orders: 0,

    search_type: null,
    tab_search_type: [
      { label: "Instantanée", value: "INST" },
      { label: "Stock", value: "BULK" },
      { label: "Manuel", value: "MANUAL" },
    ],
    search_status: null,
    tab_search_status: [
      { label: "Brouillon", value: 10 },
      { label: "Validée", value: 33 },
      { label: "En attente", value: "PENDING" },
      { label: "En attente de réception", value: 66 },
      { label: "Réceptionnée", value: 100 },
    ],
    search_supplier: null,
    tab_search_supplier: [
      { label: "VAN HECK", value: "Vhi" },
      { label: "DA SILVA", value: "Dsv" },
    ],
    search_archived: false,
    tab_search_archived: [
      { label: "Actif", value: false },
      { label: "Archivé", value: true },
    ],
  }),
  components: {
    backButton,
    searchInput,
    pageNumber,
    ProgressSpinner,
    titrePages,
    ProgressBar,
  },
  computed: {
    ...mapGetters(["user"]),
  },
  methods: {
    ...mapActions(["addcommandereception"]),
    getFRDate(date) {
      return getFrDate(date);
    },

    async getSupplierOrders(skipValue, limitValue) {
      const skip = (skipValue - 1) * 20;
      const limit = limitValue;
      this.currentPage = skipValue;

      let filters = {};
      if (this.search_type) {
        filters.search_type = this.search_type;
      }
      if (this.search_status) {
        filters.search_status = this.search_status;
      }
      if (this.search_supplier) {
        filters.search_supplier = this.search_supplier;
      }
      if (this.search_archived) {
        filters.search_archived = this.search_archived;
      }

      const supplierDoc = await getAll(
        this.user.proprietaire,
        filters,
        true,
        skip,
        limit
      );
      if (supplierDoc && supplierDoc.success) {
        this.orders = supplierDoc.supplier_orders;
        this.length_orders = supplierDoc.totalCount;
      } else {
        this.orders = [];
        this.length_orders = 0;
        error(supplierDoc?.message);
      }
    },
    goToDetail(order) {
      const encodedId = btoa(order._id);
      this.$router.push({
        path: "/receptionFournisseur",
        query: { id: encodedId },
      });
    },

    // getAllCmd(state, skipValue, limitValue) {
    //   this.state = state;
    //   this.currentPage = skipValue;
    //   axios
    //     .post(`${process.env.VUE_APP_API}/commandeFournisseur/getByFilter`, {
    //       plateform: this.user.proprietaire,
    //       state: state,
    //       skip: (skipValue - 1) * 10,
    //       limit: limitValue,
    //     })
    //     .then((response) => {
    //       console.log(response.data);
    //       this.Commandes = response.data.data;
    //       this.CommandesLength = response.data.length;
    //     });
    // },
    // getCommandes() {
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_API}/commandeFournisseur/${this.user.proprietaire}`
    //     )
    //     .then((response) => {
    //       this.Commandes = response.data;
    //     });
    // },
    // getCommandesReceived() {
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_API}/commandeFournisseur/receivedCommand/${this.user.proprietaire}`
    //     )
    //     .then((response) => {
    //       this.Commandes = response.data;
    //     });
    // },
    // getCommandesWaiting() {
    //   axios
    //     .get(
    //       `${process.env.VUE_APP_API}/commandeFournisseur/waitingCommand/${this.user.proprietaire}`
    //     )
    //     .then((response) => {
    //       this.Commandes = response.data;
    //     });
    // },
    // setReception(cmd) {
    //   this.addcommandereception(cmd);
    //   this.$router.push("/receptionFournisseur");
    // },

    async exportExcel() {
      let infos = [
        {
          Ref_fournisseur: "",
          Code_marque: "",
          Prefixe_tarif: "",
          fournisseur: "",
          quantity_asked: "",
          prix_net: "",
        },
      ];
      let wb = XLSX.utils.book_new();

      let ws = XLSX.utils.json_to_sheet(infos);
      XLSX.utils.book_append_sheet(wb, ws, "Reception");

      XLSX.writeFile(wb, "Reception_" + this.user.proprietaire + ".xlsx");
    },
    async readExcel(event) {
      const file = event.target.files ? event.target.files[0] : null;

      if (!file) {
        error("Aucun fichier sélectionné");
        return;
      }

      if (file.size >= 1000000) {
        error("Le fichier est trop volumineux (max : 1 Mo)");
        return;
      }

      try {
        this.readFile = await readXlsxFile(file);
        this.readFile = this.readFile.slice(1);
        this.openCreateReception = true;

        const res = await axios.post(
          `${process.env.VUE_APP_API}/commandeFournisseur/formatReceptionExcel`,
          { plateform: this.user.proprietaire, file: this.readFile }
        );

        this.formattedFile = res.data;
      } catch (err) {
        console.error("Erreur lors de l'importation du fichier :", err);
        this.resetExcel();
        error("Une erreur est survenue lors de l'importation du fichier.");
      }
    },
    resetExcel() {
      this.openCreateReception = false;
      this.$refs.fileInput.value = "";
      this.createName = "";
      this.createType = "";
      this.createFournisseur = "";
      this.formattedFile = [];
      this.readFile = null;
      this.file = null;
    },

    async createCommande(creation_type) {
      this.isLoading = true;

      try {
        if (!this.createName || !this.createType || !this.createFournisseur) {
          warning("Veuillez renseigner les champs obligatoires !");
          return;
        }

        const supplier_order = {
          order_type: "MANUAL",
          platform: this.user.proprietaire,
          order_articles: this.formattedFile,
          status: "CONFIRMED",
          percentage_status: 33,
          archived: false,
          order_reference: this.createType,
          comment: this.createName,
          supplier: this.createFournisseur,
          supplier_prefix_tariff: this.formattedFile[0]?.Prefixe_tarif,
          creation_type: creation_type,
          created_by: this.user.username,
          created_at: new Date(),
        };
        const supplierDoc = await createSupplierOrder(supplier_order);

        for (const art of this.formattedFile) {
          await backorderStock(
            this.user._id,
            this.user.proprietaire,
            art._id,
            art.Ref_fournisseur,
            art.Code_marque,
            art.Code_EAN,
            0,
            0,
            10,
            "",
            "Commande chez " + this.createFournisseur,
            art.Prefixe_tarif,
            this.user.proprietaire,
            this.createName,
            "Commande Fournisseur",
            "Commande Fournisseur",
            art.quantity_asked
          );
        }

        if (supplierDoc && supplierDoc.success) {
          success(supplierDoc.message);
          this.resetExcel();
        } else {
          error(supplierDoc.message);
        }

        this.getSupplierOrders(this.currentPage, 20);
      } finally {
        this.isLoading = false;
      }
    },

    search(skipValue, limitValue, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getSupplierOrders(this.currentPage, 20);
        this.pageSwitch();
      } else {
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandeFournisseur/searchInPlateform`,
            {
              plateform: this.user.proprietaire,
              state: this.state,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 10,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.Commandes = response.data.results;
            this.CommandesLength = response.data.count;
          })
          .catch((error) => {
            console.log(error);
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    getLabel(list, value) {
      const item = list.find((item) => item.value === value);
      return item ? item.label : null;
    },
    clearFilter(filter) {
      this[filter] = filter == "search_archived" ? false : null;
      this.getSupplierOrders(1, 20);
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
  },
  mounted() {
    this.getSupplierOrders(this.currentPage, 20);
    this.pageSwitch();
  },
};
</script>
<style scoped></style>
