<template>
  <titrePages class="flex flex-1 justify-center mb-10" :title="'Commandes'" />
  <!-- modal pour annuler ou reprendre -->
  <div
    v-if="openCancelCmd || openDesarchivedCmd"
    :class="`modal ${
      !openCancelCmd && !openDesarchivedCmd && 'opacity-0 pointer-events-none'
    } z-50 fixed   w-full h-full top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="(openCancelCmd = false), (openDesarchivedCmd = false)"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      class="z-50 w-11/12 mx-auto overflow-y-auto bg-white rounded shadow-lg modal-container md:max-w-md"
      style="max-height: 50rem; max-width: 38rem"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <div class="px-6 py-4 modal-content">
        <div class="flex justify-between pb-3">
          <div v-if="dataToChangeStatus">
            <p class="text-xl" v-if="openCancelCmd == true">
              Confirmation d'annulation de commande
            </p>
            <p class="text-xl" v-if="openDesarchivedCmd == true">
              Confirmation pour reprendre la commande
            </p>
          </div>
        </div>

        <div v-if="dataToChangeStatus">
          <div v-if="openCancelCmd == true">
            <h1>
              Êtes-vous sûr(e) de vouloir annuler définitivement la commande
              n°{{ dataToChangeStatus[ORDER_KEYS.BC_ID] }}
              ?
            </h1>
            <p class="mt-2">Veuillez indiquer la raison de l'annulation :</p>
            <h2>
              <textarea
                required
                rows="2"
                cols="40"
                class="w-full border border-2 px-1 py-0.5 focus:outline-2 outline-sky-300"
                placeholder="Ex. : Rupture de stock"
                v-model="cancel_reason"
              ></textarea>
            </h2>
            <!-- v-model="dataToChangeStatus.cancellation.reason" -->
            <p class="text-xs" style="font-style: italic; color: #888">
              * Ce message sera visible par le client.
            </p>
          </div>
          <div v-if="openDesarchivedCmd == true">
            Êtes-vous sûr(e) de vouloir reprendre la commande n°{{
              dataToChangeStatus[ORDER_KEYS.BC_ID]
            }}
            ?
          </div>
        </div>

        <div class="flex justify-end pt-2">
          <button
            @click="(openCancelCmd = false), (openDesarchivedCmd = false)"
            class="p-3 px-6 py-3 mr-2 text-blue-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-blue-400 focus:outline-none"
          >
            Fermer
          </button>
          <div v-if="openCancelCmd == true && openDesarchivedCmd == false">
            <button
              @click="validateCancel()"
              v-if="!loadingArchiver"
              class="p-3 px-6 py-3 mr-2 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-red-400 focus:outline-none"
            >
              Valider pour annuler
            </button>
            <button
              v-else-if="loadingArchiver"
              disabled
              class="p-3 px-6 py-3 mr-2 text-gray-500 bg-transparent rounded-lg focus:outline-none animate-pulse"
            >
              Valider pour annuler
            </button>
          </div>

          <button
            v-if="openCancelCmd == false && openDesarchivedCmd == true"
            @click="validateResume()"
            class="p-3 px-6 py-3 mr-2 text-red-500 bg-transparent rounded-lg hover:bg-gray-100 hover:text-red-400 focus:outline-none"
          >
            Valider pour reprendre
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- fin modal pour annuler ou reprendre -->

  <!-- modal détail cmd -->
  <div
    v-if="infoInCmd"
    :class="`modal ${
      !infoInCmd && 'opacity-0 pointer-events-none'
    } z-50 fixed w-full h-screen top-0 left-0 flex items-center justify-center`"
  >
    <div
      @click="infoInCmd = false"
      class="absolute w-full h-full bg-gray-900 opacity-50 modal-overlay"
    ></div>

    <div
      style="max-width: 100rem; width: 100rem"
      class="z-50 mx-auto max-h-[40rem] bg-white rounded shadow-lg modal-container md:max-w-md"
    >
      <div
        class="absolute top-0 right-0 z-50 flex flex-col items-center mt-4 mr-4 text-sm text-white cursor-pointer modal-close"
      >
        <svg
          class="text-white fill-current"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
        >
          <path
            d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
          />
        </svg>
        <span class="text-sm">(Esc)</span>
      </div>

      <!-- Add margin if you want to see some of the overlay behind the modal-->
      <div class="px-6 py-4 text-left modal-content">
        <!--Title-->
        <div class="flex items-center justify-between pb-3">
          <p class="text-2xl font-bold">Récapitulatif de la commande</p>
          <div
            class="z-50 cursor-pointer modal-close"
            @click="infoInCmd = false"
          >
            <svg
              class="text-black fill-current"
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 18 18"
            >
              <path
                d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
              />
            </svg>
          </div>
        </div>
        <div class="flex justify-between">
          <div class="w-1/3 ml-2">
            Référence client:
            {{ detailCommande[ORDER_KEYS.CLIENT_NOTE] }}
            <div>
              <p>BC n° : {{ detailCommande[ORDER_KEYS.BC_ID] }}</p>
            </div>
          </div>

          <div class="flex space-x-2">
            <div
              v-if="detailCommande[ORDER_KEYS.PARENT_ORDER_ID]"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBC(detailCommande)"
              >
                download
              </span>
              <div>BC n° : {{ detailCommande[ORDER_KEYS.BC_ID] }}</div>
            </div>
            <div
              v-if="detailCommande[ORDER_KEYS.BL_ID]"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBL(detailCommande)"
              >
                download
              </span>
              <div>BL n° {{ detailCommande[ORDER_KEYS.BL_ID] }}</div>
            </div>
            <div
              v-if="detailCommande[ORDER_KEYS.BL_ID]"
              class="flex flex-col my-auto items-center ml-auto mx-10"
            >
              <span
                class="material-icons-round cursor-pointer shadow-lg rounded-full p-1 m-auto"
                style="color: blue"
                @click="openBT(detailCommande)"
              >
                download
              </span>
              <div>BT n° {{ detailCommande[ORDER_KEYS.BL_ID] }}</div>
            </div>
          </div>
        </div>

        <table class="min-w-full mt-4">
          <div class="overflow-y-auto max-h-[25rem]">
            <thead class="tableHEADTR">
              <tr>
                <th
                  class="w-44 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Réf.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  colspan="2"
                >
                  Description
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Qté
                </th>
                <th
                  class="w-54 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                  colspan="4"
                >
                  Stock
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  P.V.
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Remise
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Px final
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Total
                </th>
                <th
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
                >
                  Transfert
                </th>
              </tr>
            </thead>
            <tbody class="bg-white w-fit">
              <tr
                v-for="(cmd, index) in detailCommande[ORDER_KEYS.ARTICLES]"
                :key="index"
                class="tableHEADTR"
              >
                <td
                  class="w-44 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="flex flex-row items-center">
                    <span class="ml-4 text-sm leading-5 text-gray-900">
                      {{ cmd.Ref_fournisseur }}
                    </span>
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                  colspan="2"
                >
                  <div class="text-sm leading-5 text-gray-500 overflow-y-auto">
                    {{ cmd.Description }}
                  </div>
                </td>

                <td
                  class="px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div class="text-sm leading-5">
                    {{ cmd.quantity }}
                  </div>
                </td>
                <td
                  class="w-54 px-6 py-4 border-b border-gray-200 whitespace-nowrap tableTDTH"
                  style="overflow: none"
                  colspan="4"
                >
                  <stockvue
                    :quantity="cmd.quantity"
                    :Article="cmd"
                    :key="cmd.Code_EAN + reloadKeyStock"
                  />
                </td>

                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  {{ parseFloat(cmd.Prix_euro).toFixed(2) }}
                  €
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <div v-if="cmd.PrixFournisseurType != 'prixnet'">
                    {{ cmd.remise }} %
                  </div>
                  <div v-else>prix net</div>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="cmd.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(cmd.Prix_vente).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        cmd.Prix_euro - (cmd.Prix_euro * cmd.remise) / 100
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH"
                >
                  <span v-if="cmd.PrixFournisseurType == 'prixnet'">
                    {{ parseFloat(cmd.Prix_vente * cmd.quantity).toFixed(2) }}
                    €
                  </span>
                  <span v-else>
                    {{
                      parseFloat(
                        (cmd.Prix_euro - (cmd.Prix_euro * cmd.remise) / 100) *
                          cmd.quantity
                      ).toFixed(2)
                    }}
                    €
                  </span>
                </td>
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap tableTDTH overflow-y-auto"
                >
                  <div
                    v-if="
                      cmd.Ref_fournisseur &&
                      !cmd.Ref_fournisseur.startsWith('C :')
                    "
                  >
                    <div
                      v-if="cmd.toCommandGroupement"
                      class="flex flex-row items-center space-x-1 bg-blue-100 text-blue-800 text-xs font-medium px-1 py-0.5 rounded border border-blue-400"
                    >
                      <span>{{ cmd.toCommandGroupementPlateform }}</span>
                      <span>:</span>
                      <span class="bg-transparent">{{
                        cmd.stockToTransfert
                      }}</span>
                    </div>
                    <div v-else class="flex flex-row space-x-2 items-center">
                      <div>-</div>
                      <div class="flex flex-row space-x-1 items-center">
                        <span
                          class="material-symbols-rounded bg-green-500 rounded-full shadow text-white font-lg p-2"
                        >
                          quick_reorder
                        </span>
                        <div v-if="cmd.directTransfered">
                          <span class="font-bold">{{
                            cmd.directTransferedQty
                          }}</span>
                        </div>
                        <div v-else>
                          <span class="font-bold text-green-500">0</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </div>
          <thead class="tableHEADTR mt-12 border-t">
            <tr class="tableHEADTR">
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              ></th>
              <th
                class="w-64 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 tableTDTH"
              >
                <div class="text-sm leading-5 text-gray-900">
                  Total HT :
                  {{
                    parseFloat(
                      detailCommande[ORDER_KEYS.TOTAL_PRICE_HT]
                    ).toFixed(2)
                  }}
                  €
                </div>
                <div
                  v-if="detailCommande[ORDER_KEYS.TOTAL_PRICE_TTC]"
                  class="text-sm leading-5 text-gray-500"
                >
                  Total TTC :
                  {{
                    parseFloat(
                      detailCommande[ORDER_KEYS.TOTAL_PRICE_TTC]
                    ).toFixed(2)
                  }}
                  €
                </div>
                <div v-else class="text-sm leading-5 text-gray-500">
                  Total TTC :
                  {{
                    parseFloat(
                      detailCommande[ORDER_KEYS.TOTAL_PRICE_HT] +
                        detailCommande[ORDER_KEYS.TOTAL_PRICE_HT] * 0.2
                    ).toFixed(2)
                  }}
                  €
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  </div>
  <!-- fin modal détail cmd -->

  <!-- BODY -->
  <div
    class="flex flex-row justify-between bg-slate-100 rounded-md shadow items-center"
  >
    <div class="flex space-x-2">
      <!-- actif -->
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'actif'"
        @click="getInfosCommandes(1, 20, professionState)"
      >
        <span class="m-auto"> Actif </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          notifications
        </span>
      </button>
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getInfosCommandes(1, 20, professionState)"
      >
        <span class="m-auto"> Actif </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          notifications
        </span>
      </button>

      <!-- preparation -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'preparation'"
        @click="
          getByStatus(1, 20, 'preparation', 'IN_PREPARATION', professionState)
        "
      >
        <span class="m-auto"> En préparation </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="
          getByStatus(1, 20, 'preparation', 'IN_PREPARATION', professionState)
        "
      >
        <span class="m-auto"> En préparation </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory
        </span>
      </button>

      <!-- commandée -->
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'commandée'"
        @click="
          getByStatus(
            1,
            20,
            'commandée',
            'SUBMITTED_SUPPLIER_ORDER',
            professionState
          )
        "
      >
        <span class="m-auto"> Commandée </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          local_shipping
        </span>
      </button>
      <button
        class="hover:shadow-lg flex mr-4 justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="
          getByStatus(
            1,
            20,
            'commandée',
            'SUBMITTED_SUPPLIER_ORDER',
            professionState
          )
        "
      >
        <span class="m-auto"> Commandée </span>
        <span class="material-icons-outlined" style="font-size: 16px">
          local_shipping
        </span>
      </button>

      <!-- prete -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'prete'"
        @click="getByStatus(1, 20, 'prete', 'PREPARED', professionState)"
      >
        <span class="m-auto"> Prête </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          done_all
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 20, 'prete', 'PREPARED', professionState)"
      >
        <span class="m-auto"> Prête </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          done_all
        </span>
      </button>

      <!-- valide -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'valide'"
        @click="getByStatus(1, 20, 'valide', 'COMPLETED', professionState)"
      >
        <span class="m-auto"> Validée </span>
        <span class="material-icons-round" style="font-size: 14px">
          inbox
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 20, 'valide', 'COMPLETED', professionState)"
      >
        <span class="m-auto"> Validée </span>
        <span class="material-icons-round" style="font-size: 14px">
          inbox
        </span>
      </button>

      <!-- attente -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'attente'"
        @click="getByStatus(1, 20, 'attente', 'WAITING', professionState)"
      >
        <span class="m-auto"> En attente </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          hourglass_empty
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 20, 'attente', 'WAITING', professionState)"
      >
        <span class="m-auto"> En attente </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          hourglass_empty
        </span>
      </button>

      <!-- annule -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'annule'"
        @click="getByStatus(1, 20, 'annule', 'CANCELLED', professionState)"
      >
        <span class="m-auto"> Annulée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          cancel
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getByStatus(1, 20, 'annule', 'CANCELLED', professionState)"
      >
        <span class="m-auto"> Annulée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          cancel
        </span>
      </button>

      <!-- archived -->
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-800 p-1 text-white text-sm rounded"
        v-if="tabState == 'archived'"
        @click="getArchived(1, 20, professionState)"
      >
        <span class="m-auto"> Archivée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory_2
        </span>
      </button>
      <button
        class="hover:shadow-lg flex justify-between items-center w-32 h-fit bg-blue-600 p-1 text-white text-sm rounded"
        v-else
        @click="getArchived(1, 20, professionState)"
      >
        <span class="m-auto"> Archivée </span>
        <span class="material-icons-outlined" style="font-size: 14px">
          inventory_2
        </span>
      </button>
    </div>

    <div
      class="flex flex-col text-xs mr-4 text-orange-500 cursor-pointer"
      title="Actualiser"
      @click="reloadPage()"
    >
      <span
        class="material-icons-outlined m-auto border border-white rounded-full hover:bg-orange-400 hover:bg-opacity-25 shadow-sm"
        >refresh</span
      >
    </div>
  </div>

  <div class="flex justify-between mt-2 items-center">
    <div>
      <searchInput
        v-if="tabState == 'actif'"
        placeholder="Rechercher dans commandes"
        @searchWord="search(1, 20, false, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <searchInput
        v-if="tabState == 'archived'"
        placeholder="Rechercher dans commandes archivées"
        @searchWord="search(1, 20, true, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
      <searchInput
        v-if="tabState != 'actif' && tabState != 'archived'"
        placeholder="Rechercher dans commandes"
        @searchWord="searchByStatus(1, 20, tabState, currentStatus, $event)"
        @catchFalse="searchCatchError = $event"
        :searchStarted="searchInProgress"
        :catchError="searchCatchError"
      />
    </div>
    <div
      class="flex flex-row space-x-2 bg-slate-100 rounded-md shadow items-center p-1"
    >
      <button @click="setOrderSource('INTERNAL', null)">
        <img
          :src="plateform.lien_logo"
          alt=""
          :class="{
            'blur-[2px]': shouldBlur('INTERNAL'),
            'border border-green-400 border-8': hasOrderType('INTERNAL'),
          }"
          class="w-14 h-12 rounded"
        />
      </button>
      <button @click="setOrderSource('TRANSFER', null)">
        <img
          src="https://vekteurbucket.s3.amazonaws.com/Logo/Gap.png"
          alt=""
          :class="{
            'blur-[2px]': shouldBlur('TRANSFER'),
            'border border-green-400 border-8': hasOrderType('TRANSFER'),
          }"
          class="w-12 h-12 rounded"
        />
      </button>
      <button @click="setOrderSource('EXTERNAL', 'LKQ')">
        <img
          src="https://vekteurbucket.s3.us-east-1.amazonaws.com/Logo/vanheck.jpeg"
          alt=""
          :class="{
            'blur-[2px]': shouldBlur('EXTERNAL') || shouldBlurSource('LKQ'),
            'border border-green-400 border-8': hasOrderSource('LKQ'),
          }"
          class="w-12 h-12 rounded"
        />
      </button>
      <button @click="setOrderSource('EXTERNAL', 'DA SILVA')">
        <img
          src="https://vekteurbucket.s3.us-east-1.amazonaws.com/Logo/Logo-DaSilva.png"
          alt=""
          :class="{
            'blur-[2px]':
              shouldBlur('EXTERNAL') || shouldBlurSource('DA SILVA'),
            'border border-green-400 border-8': hasOrderSource('DA SILVA'),
          }"
          class="w-12 h-12 rounded"
        />
      </button>
      <button @click="setOrderSource('EXTERNAL', 'FRIGAIR')">
        <img
          src="https://vekteurbucket.s3.us-east-1.amazonaws.com/Logo/Logo_FrigAir.png"
          alt=""
          :class="{
            'blur-[2px]': shouldBlur('EXTERNAL') || shouldBlurSource('FRIGAIR'),
            'border border-green-400 border-8': hasOrderSource('FRIGAIR'),
          }"
          class="w-12 h-12 rounded"
        />
      </button>
    </div>
  </div>
  <div class="flex flex-col mt-2">
    <div class="py-2 -my-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div
        class="inline-block min-w-full overflow-hidden align-middle border-b border-gray-200 shadow sm:rounded-lg"
      >
        <tabsFilter
          :search="professionState"
          @click="searchActive = false"
          @filter="handleFilterPro"
        />
        <!-- <div
            class="flex overflow-x-auto whitespace-nowrap border-b-2 border-gray-100"
          >
            <button
              class="inline-flex items-center  h-12 px-4 py-2 text-sm text-center border-b border-gray-400 sm:text-base dark:border-gray-300  whitespace-nowrap focus:outline-none hover:border-gray-400 dark:hover:border-gray-400"
              :class="{ 'bg-gray-100 text-blue-600 border border-b-0 rounded-t-md': selectedFilter === 'all' }"
              @click="
                selectedFilter = 'all';
                getInfosCommandes(1, 20, null);
              "
            >
              Tout
            </button>

            <button
            class="inline-flex items-center  h-12 px-4 py-2 text-sm text-center  border-b border-gray-400 sm:text-base dark:border-gray-300  whitespace-nowrap focus:outline-none hover:border-gray-400 dark:hover:border-gray-400"
            :class="{ 'bg-gray-100 text-blue-600 border border-b-0 rounded-t-md': selectedFilter === 'pro' }"
              @click="
                selectedFilter = 'pro';
                getInfosCommandes(1, 20, true);
              "
            >
              Professionnel
            </button>

            <button
            class="inline-flex items-center  h-12 px-4 py-2 text-sm text-center   border-b border-gray-400 sm:text-base dark:border-gray-300  whitespace-nowrap focus:outline-none hover:border-gray-400 dark:hover:border-gray-400"
            :class="{ 'bg-gray-100 text-blue-600 border border-b-0 rounded-t-md': selectedFilter === 'particulier' }"
              @click="
                selectedFilter = 'particulier';
                getInfosCommandes(1, 20, false);
              "
            >
              Particulier
            </button>
          </div> -->
        <table class="min-w-full">
          <thead>
            <tr>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Commande
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Date
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                BC Numéro
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Statut
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Réf. Client
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                Commandé par
              </th>
              <th
                class="md:px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50"
              >
                Progression
              </th>
              <th
                class="py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              ></th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                HT
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                TTC
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                BL
              </th>
              <th
                class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase border-b border-gray-200 bg-gray-50 hidden md:table-cell"
              >
                BT
              </th>
            </tr>
          </thead>

          <div
            v-show="loadingFile"
            class="flex justify-center w-full bg-white items-center space-x-2 mt-4"
          >
            <span>En cours de chargement</span>
            <PulseLoader color="#2d74da" />
          </div>

          <tbody class="bg-white" v-show="!loadingFile">
            <tr
              v-for="(u, index) in infosCommandes"
              :key="index"
              style="cursor: pointer"
              :class="
                u[ORDER_KEYS.CLIENT_TYPE] == 'PROFESSIONAL'
                  ? 'hover:bg-gray-100'
                  : 'bg-blue-100 hover:bg-blue-200'
              "
              @click="setTempCommande(u)"
            >
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex justify-between items-center">
                  <div class="flex flex-col">
                    <div class="text-sm font-medium leading-5 text-gray-900">
                      <!-- {{ u[ORDER_KEYS.ORDER_ID] }} -->
                      <div v-if="u[ORDER_KEYS.ORDER_SOURCE] == 'VEKTEUR'">
                        {{ user.proprietaire }}
                      </div>
                      <div v-else-if="u[ORDER_KEYS.ORDER_SOURCE] == 'LKQ'">
                        <img
                          src="https://vekteurbucket.s3.us-east-1.amazonaws.com/Logo/vanheck.jpeg"
                          alt=""
                          class="w-10 h-10 rounded"
                        />
                      </div>
                      <div v-else-if="u[ORDER_KEYS.ORDER_SOURCE] == 'DA SILVA'">
                        <img
                          src="https://vekteurbucket.s3.us-east-1.amazonaws.com/Logo/Logo-DaSilva.png"
                          alt=""
                          class="w-10 h-10 rounded"
                        />
                      </div>
                      <div v-else-if="u[ORDER_KEYS.ORDER_SOURCE] == 'FRIGAIR'">
                        <img
                          src="https://vekteurbucket.s3.us-east-1.amazonaws.com/Logo/Logo_FrigAir.png"
                          alt=""
                          class="w-10 h-10 rounded"
                        />
                      </div>
                    </div>
                    <p
                      v-if="u[ORDER_KEYS.PARENT_ORDER_ID]"
                      class="text-sm text-orangevk font-medium"
                    >
                      R
                    </p>
                  </div>
                  <div
                    v-if="u[ORDER_KEYS.STATUS] == 'CANCELLED'"
                    title="Reprendre la commande"
                  >
                    <button
                      class="material-icons-round text-red-600 items-center bg-gray-100 rounded-full p-1 shadow hover:bg-gray-200"
                      @click.stop="openModalDesarchived(u)"
                    >
                      <span class="material-icons-rounded mt-1 m-auto">
                        unarchive
                      </span>
                    </button>
                  </div>
                  <div v-else>
                    <button
                      class="material-icons-round text-red-600 items-center bg-gray-100 rounded-full p-1 shadow hover:bg-gray-200"
                      @click.stop="openModalCancel(u)"
                    >
                      <span class="material-icons-rounded mt-1 m-auto">
                        highlight_off
                      </span>
                    </button>
                  </div>
                </div>
              </td>

              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                @click="setTempCommande(u)"
              >
                <div class="text-sm leading-5 text-gray-900">
                  {{ getFRDate(u[COMMON_KEYS.CREATED_AT]) }}
                </div>
              </td>
              <td
                class="px-6 py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex justify-between">
                  <p class="leading-5 text-sm text-gray-900">
                    n° {{ u[ORDER_KEYS.BC_ID] }}
                  </p>
                  <button
                    @click.stop="getDetailCmd(u)"
                    title="Plus d'informations"
                    class="material-icons-outlined text-md text-cyan-600 hover:text-cyan-700 cursor-pointer"
                  >
                    info
                  </button>
                </div>
              </td>
              <td
                class="px-3 md:px-6 py-4 border-b border-gray-200 whitespace-nowrap"
                @click="setTempCommande(u)"
              >
                <div class="flex flex-col">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 rounded-full w-fit"
                  >
                    {{ translateStatus(u[ORDER_KEYS.STATUS]) }}
                  </span>
                  <div
                    v-if="u.status == 'Commande annulée' && u.cancellation"
                    class="text-xs rounded-md bg-orange-400 text-white w-fit px-1 text-sm overflow-x-auto"
                    :title="u.cancellation.reason"
                  >
                    <h4>
                      Raison d'annulation:
                      {{
                        u.cancellation.reason.length > 11
                          ? u.cancellation.reason.substr(0, 11) + "..."
                          : u.cancellation.reason
                      }}
                    </h4>
                    <h4>Par: {{ u.cancellation.user }}</h4>
                  </div>
                  <span
                    v-if="u.status == 'SUBMITTED_SUPPLIER_ORDER'"
                    class="rounded-md bg-orange-400 text-white w-fit px-1 text-xs md:text-sm overflow-x-auto"
                    >{{ u[ORDER_KEYS.SUPPLIER_ORDER].user }}:
                    {{ u[ORDER_KEYS.SUPPLIER_ORDER].message }}</span
                  >
                </div>
                <div class="text-xs leading-5 text-gray-900 md:hidden">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u[ORDER_KEYS.CLIENT_NAME] }}</span
                  >
                </div>
              </td>
              <td
                class="pl-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap hidden md:table-cell overflow-x-auto"
              >
                <span
                  class="inline-flex px-2 max-w-[14rem] text-xs font-semibold leading-5 text-black rounded-full"
                  >{{ u[ORDER_KEYS.CLIENT_NOTE] }}</span
                >
              </td>
              <td
                class="pl-6 py-4 text-sm leading-5 text-gray-500 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                @click="setTempCommande(u)"
              >
                <div class="text-sm leading-5 text-gray-900">
                  <span
                    class="inline-flex px-2 text-xs font-semibold leading-5 text-gray-800 bg-gray-100 shadow rounded-full"
                    >{{ u[ORDER_KEYS.CLIENT_NAME] }}</span
                  >
                </div>
                <!-- <span
                  class="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full"
                  >{{ u[COMMON_KEYS.CREATED_BY] }}</span
                > -->
              </td>
              <td
                class="md:pr-6 text-sm font-medium leading-5 text-left border-b border-gray-200 whitespace-nowrap"
                @click="setTempCommande(u)"
              >
                <progressBar
                  v-if="u.status == 'Commande annulée'"
                  :pourcentageStatus="parseInt(u[ORDER_KEYS.STATUS_PERCENTAGE])"
                  :isRed="true"
                  class="hidden md:block"
                />
                <progressBar
                  v-else
                  :pourcentageStatus="parseInt(u[ORDER_KEYS.STATUS_PERCENTAGE])"
                  :isRed="false"
                  class="hidden md:block"
                />
                <span class="md:hidden"
                  >{{ u[ORDER_KEYS.STATUS_PERCENTAGE] }} %</span
                >
              </td>

              <td
                class="py-4 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div
                  v-if="
                    u.status == 'Commande et BL disponibles' &&
                    tabState != 'archived'
                  "
                >
                  <button
                    class="text-orange-600 bg-gray-100 hover:bg-gray-200 items-center rounded-full shadow px-1"
                    @click.stop="archiverTouteCommande(u)"
                    title="Archiver"
                  >
                    <span class="material-icons-outlined mt-1 m-auto"
                      >archive</span
                    >
                  </button>
                </div>

                <div v-else @click="setTempCommande(u)"></div>
              </td>

              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
                @click="setTempCommande(u)"
              >
                {{ parseFloat(u[ORDER_KEYS.TOTAL_PRICE_HT]).toFixed(2) }} €
              </td>

              <td
                @click="setTempCommande(u)"
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                {{ parseFloat(u[ORDER_KEYS.TOTAL_PRICE_TTC]).toFixed(2) }} €
              </td>
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex flex-col w-fit">
                  <span
                    class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                    v-if="u[ORDER_KEYS.BL_ID]"
                    style="color: blue"
                    @click.stop="openBL(u)"
                  >
                    download
                  </span>

                  <span class="m-auto">{{ u[ORDER_KEYS.BL_ID] }}</span>
                </div>
              </td>
              <!--  -->
              <td
                class="px-6 py-4 text-sm leading-5 text-gray-800 border-b border-gray-200 whitespace-nowrap hidden md:table-cell"
              >
                <div class="flex flex-col w-fit">
                  <span
                    class="w-fit z-20 material-icons-round cursor-pointer rounded-full shadow-lg p-1"
                    v-if="u[ORDER_KEYS.BL_ID]"
                    style="color: blue"
                    @click.stop="openBT(u)"
                  >
                    download
                  </span>
                  <span class="m-auto">{{ u[ORDER_KEYS.BL_ID] }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <pageNumber
          v-if="tabState == 'actif' && !searchActive"
          :pageStart="pageStart"
          :pageEnd="20"
          :pageNext="pageNext"
          :tabList="infosCommandesLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(infosCommandesLength / 20)"
          :clickFunction="
            (arg1, arg2) => getInfosCommandes(arg1, arg2, professionState)
          "
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <pageNumber
          v-if="tabState != 'actif' && tabState != 'archived' && !searchActive"
          :pageStart="pageStart"
          :pageEnd="20"
          :pageNext="pageNext"
          :tabList="byStatusLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(byStatusLength / 20)"
          :clickFunction="
            (arg1, arg2) =>
              getByStatus(arg1, arg2, tabState, currentStatus, professionState)
          "
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <pageNumber
          v-if="tabState == 'archived' && !searchActive"
          :pageStart="pageStart"
          :pageEnd="20"
          :pageNext="pageNext"
          :tabList="archivedLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(archivedLength / 20)"
          :clickFunction="
            (arg1, arg2) => getArchived(arg1, arg2, professionState)
          "
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />

        <!-- searchActive -->
        <pageNumber
          v-if="tabState == 'actif' && searchActive"
          :pageStart="pageStart"
          :pageEnd="20"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 20)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, false, searchTerm)"
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
        <pageNumber
          v-if="tabState != 'actif' && tabState != 'archived' && searchActive"
          :pageStart="pageStart"
          :pageEnd="20"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 20)"
          :clickFunction="
            (arg1, arg2) =>
              searchByStatus(arg1, arg2, tabState, currentStatus, searchTerm)
          "
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
        <pageNumber
          v-if="tabState == 'archived' && searchActive"
          :pageStart="pageStart"
          :pageEnd="20"
          :pageNext="pageNext"
          :tabList="searchLength"
          :pageTab="pageTab"
          :pageTotal="Math.ceil(searchLength / 20)"
          :clickFunction="(arg1, arg2) => search(arg1, arg2, true, searchTerm)"
          :currentPage="currentPage"
          :typeList="'commandes'"
          @update:start="pageStart = $event"
          @update:tab="pageTab = $event"
          @update:next="pageNext = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapActions, mapGetters } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import ProgressBar from "@/components/Commandes/progressBar.vue";
import { createcommande } from "./../hooks/pdf/commande.js";
import { createlivraison } from "./../hooks/pdf/livraison.js";
import { createtransport } from "./../hooks/pdf/transport.js";
import pageNumber from "@/components/pageNumber.vue";
import searchInput from "@/components/searchInput.vue";
import { useToast } from "vue-toastification";
import { getFrDate, getFrDateSansH } from "@/hooks/tools/date.ts";
import { cancelOrder, resumeOrder } from "@/hooks/stock/stock.ts";
import {
  cancelCommand,
  resumeCommand,
} from "@/hooks/transport/panierTransport.ts";
import stockvue from "@/components/Article/stock.vue";
import { ORDER_KEYS } from "@/util/OrderSchemaConstants.js";
import { COMMON_KEYS } from "@/util/CommonSchemaConstants.js";
import titrePages from "@/components/Text/grandeTitre.vue";

import tabsFilter from "@/components/Tables/tabsFilter.vue";

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data: () => ({
    ORDER_KEYS,
    COMMON_KEYS,

    infosCommandes: [],
    tempInfosCommandes: [],
    tabState: "actif",
    currentStatus: "",
    scanEAN: "",
    valideCommandestate: true,
    prepareCommandestate: false,
    endCommandestate: false,
    envoiCommandestate: false,
    tabCommandestate: [true, false, false, false, false],
    tempCommande: {
      cmdstate: { 0: true, 1: false, 2: false, 3: false, 4: false },
    },
    tempNBArticles: 0,
    validateTempEAN: [],
    articlePrepState: [],
    numDoc: 0,
    numDocConsigne: 0,
    index: 0,
    tabDasilva: [],
    dsvcheck: false,
    cdtnDsv: false,
    pdfinfo: [],
    showMoreFilter: false,
    loadingFile: false,
    showRefSimilar: [],
    similarRef: [],
    loadingRef: false,
    autoCompleteTab: [],
    showAutoComplete: false,
    openModalSearch: false,
    openModalSearchVanHeck: false,
    foundArticle: [],
    searchInput: "",
    showEdit: false,
    openCancelCmd: false,
    openDesarchivedCmd: false,
    dataToChangeStatus: null,
    cancel_reason: "",

    pageTab: [],
    pageNext: 11,
    pageStart: 1,
    currentPage: 1,
    infosCommandesLength: 0,
    byStatusLength: 0,
    archivedLength: 0,

    searchActive: false,
    searchTerm: "",
    searchLength: 0,
    searchInProgress: false,
    searchCatchError: false,

    infoInCmd: false,
    detailCommande: [],

    loadingArchiver: false,

    reloadKeyStock: 0,

    professionState: null,
    selectedFilter: "all",

    statusTranslations: {
      CREATED: "Créée",
      IN_PREPARATION: "En préparation",
      PREPARED: "Préparée",
      COMPLETED: "Terminée",
      CANCELLED: "Annulée",
      SUBMITTED_SUPPLIER_ORDER: "Commandé chez le fournisseur",
      WAITING: "En attente",
    },

    order_type_list: ["INTERNAL", "TRANSFER", "EXTERNAL"],
    order_source_list: ["LKQ", "DA SILVA", "FRIGAIR"],
  }),
  components: {
    PulseLoader,
    ProgressBar,
    pageNumber,
    searchInput,
    stockvue,
    titrePages,
    tabsFilter,
  },
  computed: {
    ...mapGetters(["user", "plateform", "cmdPreparation", "searchFilters"]),
    setTodayDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var hour = today.getHours();
      var seconds = today.getSeconds();
      var minutes = today.getMinutes();
      today =
        dd + "/" + mm + "/" + yyyy + " " + hour + ":" + minutes + ":" + seconds;
      return today;
    },
  },
  methods: {
    ...mapActions([
      "suppStockArticle",
      "addStockArticle",
      "setMvtStock",
      "searchDirect",
      "searchTranslatedDirect",
      "getCmdPreparation",
      "setSearchFilters",
    ]),
    getFRDate(date) {
      return getFrDate(date);
    },
    handleFilterPro(event) {
      if (this.tabState === "actif") {
        this.getInfosCommandes(1, 20, event);
      } else if (this.tabState === "archived") {
        this.getArchived(1, 20, event);
      } else {
        this.getByStatus(1, 20, this.tabState, this.currentStatus, event);
      }
    },
    shouldBlur(value) {
      return (
        this.order_type_list.length > 0 && !this.order_type_list.includes(value)
      );
    },
    shouldBlurSource(value) {
      return (
        this.order_source_list.length > 0 &&
        !this.order_source_list.includes(value)
      );
    },
    hasOrderType(value) {
      return (
        (this.order_type_list.includes(value) &&
          this.order_type_list.length != 3 &&
          this.order_source_list != 3) ||
        (this.order_type_list.includes(value) &&
          this.order_source_list.length != 3)
      );
    },
    hasOrderSource(value) {
      return (
        (this.order_source_list.includes(value) &&
          this.order_source_list.length != 3) ||
        (this.order_source_list.includes(value) &&
          this.order_type_list.length != 3)
      );
    },
    setOrderSource(type, source) {
      if (type == "INTERNAL") {
        if (this.order_type_list.length === 3) {
          this.order_type_list = ["INTERNAL"];
          this.order_source_list = [];
        } else if (!this.order_type_list.includes("INTERNAL")) {
          this.order_type_list.push("INTERNAL");
        } else {
          this.order_type_list.splice(
            this.order_type_list.indexOf("INTERNAL"),
            1
          );
        }
      } else if (type == "TRANSFER") {
        if (this.order_type_list.length === 3) {
          this.order_type_list = ["TRANSFER"];
          this.order_source_list = [];
        } else if (!this.order_type_list.includes("TRANSFER")) {
          this.order_type_list.push("TRANSFER");
        } else {
          this.order_type_list.splice(
            this.order_type_list.indexOf("TRANSFER"),
            1
          );
        }
      } else if (type == "EXTERNAL") {
        if (this.order_type_list.length === 3) {
          if (this.order_source_list.length === 3) {
            this.order_type_list = ["EXTERNAL"];
            this.order_source_list = [source];
          } else {
            this.order_source_list.push(source);
          }
        } else if (!this.order_type_list.includes("EXTERNAL")) {
          this.order_type_list.push("EXTERNAL");
          this.order_source_list = [source];
        } else {
          if (this.order_type_list.includes("EXTERNAL")) {
            if (this.order_source_list.includes(source)) {
              if (this.order_source_list.length === 1) {
                this.order_type_list.splice(
                  this.order_type_list.indexOf("EXTERNAL"),
                  1
                );
                this.order_source_list = [];
              }
              this.order_source_list.splice(
                this.order_source_list.indexOf(source),
                1
              );
            } else {
              this.order_source_list.push(source);
            }
          } else {
            this.order_type_list.splice(
              this.order_type_list.indexOf("EXTERNAL"),
              1
            );
            this.order_source_list = [];
          }
        }
      }
      if (
        this.order_source_list.length === 0 &&
        this.order_type_list.length === 0
      ) {
        this.order_source_list = ["LKQ", "DA SILVA", "FRIGAIR"];
        this.order_type_list = ["INTERNAL", "TRANSFER", "EXTERNAL"];
      }
      if (this.tabState === "actif") {
        this.getInfosCommandes(1, 20, this.professionState);
      } else if (this.tabState === "archived") {
        this.getArchived(1, 20, this.professionState);
      } else {
        this.getByStatus(
          1,
          20,
          this.tabState,
          this.currentStatus,
          this.professionState
        );
      }
    },
    getInfosCommandes(skipValue, limitValue, pro) {
      this.loadingFile = true;
      this.tabState = "actif";
      this.currentPage = skipValue;
      this.professionState = pro;
      axios
        .post(`${process.env.VUE_APP_API}/commandesClient/getbyowner/getpage`, {
          plateform: this.user.proprietaire,
          order_type_list: this.order_type_list,
          order_source_list: this.order_source_list,
          skip: (skipValue - 1) * 20,
          limit: limitValue,
          pro: pro,
        })
        .then((response) => {
          this.getInfosCommandesLength();
          this.infosCommandes = response.data;
          this.tempInfosCommandes = response.data;

          this.tabDasilva = [];
          this.cdtnDsv = false;
          this.loadingFile = false;
          this.setSearchFilters({
            keyword: "",
            filter: this.professionState,
            archive: this.tabState,
            url: "/commandePro",
            pagination: this.currentPage,
          });
        });
    },
    getInfosCommandesLength() {
      axios
        .post(`${process.env.VUE_APP_API}/commandesClient/getbyowner/count`, {
          plateform: this.user.proprietaire,
          order_type_list: this.order_type_list,
          order_source_list: this.order_source_list,
          pro: this.professionState,
        })
        .then((response) => {
          this.infosCommandesLength = response.data;
        });
    },
    // By status
    getByStatus(skipValue, limitValue, tabState, status, pro) {
      this.loadingFile = true;
      this.tabState = tabState;
      this.currentPage = skipValue;
      this.currentStatus = status;
      this.professionState = pro;
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getbystatus/getPage`,
          {
            plateform: this.user.proprietaire,
            status: status,
            order_type_list: this.order_type_list,
            order_source_list: this.order_source_list,
            skip: (skipValue - 1) * 20,
            limit: limitValue,
            pro: pro,
          }
        )
        .then((response) => {
          this.getByStatusLength(status);
          this.infosCommandes = response.data;
          this.tempInfosCommandes = response.data;
          this.tabDasilva = [];
          this.cdtnDsv = false;
          this.loadingFile = false;
          this.setSearchFilters({
            keyword: "",
            filter: this.professionState,
            archive: this.tabState,
            url: "/commandePro",
            pagination: this.currentPage,
          });
        });
    },
    getByStatusLength(status) {
      axios
        .post(`${process.env.VUE_APP_API}/commandesClient/getbystatus/count`, {
          plateform: this.user.proprietaire,
          status: status,
          order_type_list: this.order_type_list,
          order_source_list: this.order_source_list,
          pro: this.professionState,
        })
        .then((response) => {
          this.byStatusLength = response.data;
        });
    },
    // archive
    getArchived(skipValue, limitValue, pro) {
      this.loadingFile = true;
      this.tabState = "archived";
      this.currentPage = skipValue;
      this.professionState = pro;
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getallarchived/getPage`,
          {
            plateform: this.user.proprietaire,
            order_type_list: this.order_type_list,
            order_source_list: this.order_source_list,
            skip: (skipValue - 1) * 20,
            limit: limitValue,
            pro: pro,
          }
        )
        .then((response) => {
          this.getArchivedLength("archived");
          this.infosCommandes = response.data;
          this.tempInfosCommandes = response.data;
          this.tabDasilva = [];
          this.cdtnDsv = false;
          this.loadingFile = false;
          this.setSearchFilters({
            keyword: "",
            filter: this.professionState,
            archive: this.tabState,
            url: "/commandePro",
            pagination: this.currentPage,
          });
        });
    },
    getArchivedLength(status) {
      axios
        .post(
          `${process.env.VUE_APP_API}/commandesClient/getallarchived/count`,
          {
            plateform: this.user.proprietaire,
            order_type_list: this.order_type_list,
            order_source_list: this.order_source_list,
            status: status,
            pro: this.professionState,
          }
        )
        .then((response) => {
          this.archivedLength = response.data;
        });
    },
    openModalCancel(u) {
      this.openCancelCmd = true;
      this.openDesarchivedCmd = false;
      this.dataToChangeStatus = u;
      this.cancel_reason = "";
    },
    openModalDesarchived(u) {
      this.openDesarchivedCmd = true;
      this.openCancelCmd = false;
      this.dataToChangeStatus = u;
    },
    async validateCancel() {
      if (this.cancel_reason == "") {
        this.toast.warning("Veuillez indiquer la raison de l'annulation.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      } else {
        this.loadingArchiver = true;
        await this.changeStatusCommande(
          this.dataToChangeStatus,
          true,
          "CANCELLED",
          this.dataToChangeStatus[ORDER_KEYS.STATUS_PERCENTAGE],
          this.cancel_reason
        );
        this.loadingArchiver = false;

        await cancelCommand(
          this.user.proprietaire,
          this.dataToChangeStatus[ORDER_KEYS.CLIENT_DETAILS][0]._id,
          this.dataToChangeStatus[ORDER_KEYS.BC_ID]
        );
      }
    },
    async validateResume() {
      await this.changeStatusCommande(
        this.dataToChangeStatus,
        false,
        this.dataToChangeStatus[ORDER_KEYS.STATUS],
        this.dataToChangeStatus[ORDER_KEYS.STATUS_PERCENTAGE],
        "Commande activée !",
        ""
      );

      let BCnum;
      if (this.dataToChangeStatus[ORDER_KEYS.BL_ID]) {
        BCnum = this.dataToChangeStatus[ORDER_KEYS.BL_ID].toString();
      }

      await resumeCommand(
        this.user.proprietaire,
        this.dataToChangeStatus[ORDER_KEYS.CLIENT_DETAILS][0]._id,
        this.user.username,
        {
          type: this.dataToChangeStatus[ORDER_KEYS.BL_ID]
            ? "Bon de livraison"
            : "Bon de commande",
          created_here: "préparation de commande",
          BCnumber: this.dataToChangeStatus[ORDER_KEYS.BC_ID].toString(),
          BCdate: this.dataToChangeStatus[COMMON_KEYS.CREATED_AT],
          BCuser: this.dataToChangeStatus[ORDER_KEYS.CLIENT_NAME],
          BCtotalPrice: this.dataToChangeStatus[ORDER_KEYS.TOTAL_PRICE_HT],
          BLnumber: BCnum,
          BLdate: this.dataToChangeStatus[ORDER_KEYS.BL_ID]
            ? this.dataToChangeStatus[COMMON_KEYS.CREATED_AT]
            : "",
          BLuser: this.dataToChangeStatus[ORDER_KEYS.BL_ID]
            ? this.user.username
            : "",
          BLtotalPrice: this.dataToChangeStatus[ORDER_KEYS.BL_ID]
            ? this.dataToChangeStatus[ORDER_KEYS.TOTAL_PRICE_HT]
            : 0,
        }
      );
    },
    async changeStatusCommande(
      u,
      archived,
      status,
      percentage,
      reasonCancellation
    ) {
      var statusFinal;
      if (archived == false) {
        if (this.dataToChangeStatus[ORDER_KEYS.STATUS_PERCENTAGE] == 10) {
          statusFinal = "CREATED";
        } else if (
          this.dataToChangeStatus[ORDER_KEYS.STATUS_PERCENTAGE] == 25
        ) {
          statusFinal = "IN_PROGRESS";
        } else if (
          this.dataToChangeStatus[ORDER_KEYS.STATUS_PERCENTAGE] == 75
        ) {
          statusFinal = "PREPARED";
        } else if (
          this.dataToChangeStatus[ORDER_KEYS.STATUS_PERCENTAGE] == 100
        ) {
          statusFinal = "COMPLETED";
        }
      } else {
        statusFinal = status;
      }

      //TODO change the status history to add the reason of the cancellation
      if (this.dataToChangeStatus[ORDER_KEYS.STATUS_HISTORY] == undefined) {
        this.dataToChangeStatus[ORDER_KEYS.STATUS_HISTORY] = [
          {
            status: "CANCELLED",
            timestamp: Date.now(),
            updated_by: this.user.username,
            duration_in_status: null,
            reason: this.cancel_reason,
            steps: [],
          },
        ];
      } else {
        this.dataToChangeStatus[ORDER_KEYS.STATUS_HISTORY].push({
          status: "CANCELLED",
          timestamp: Date.now(),
          updated_by: this.user.username,
          duration_in_status: null,
          reason: this.cancel_reason,
          steps: [],
        });
      }

      await axios.put(`${process.env.VUE_APP_API}/commandesClient/${u._id}`, {
        [ORDER_KEYS.IS_ARCHIVED]: archived,
        [ORDER_KEYS.STATUS]: statusFinal,
        [ORDER_KEYS.STATUS_PERCENTAGE]: percentage,
        [ORDER_KEYS.STATUS_HISTORY]:
          this.dataToChangeStatus[ORDER_KEYS.STATUS_HISTORY],
      });

      await this.increDecreStock(u, archived);
      this.openCancelCmd = false;
      this.openDesarchivedCmd = false;
      this.getInfosCommandes(this.currentPage, 20, this.professionState);
      this.toast.success("Commande annulé !", {
        position: "bottom-right",
        timeout: 1000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: false,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    async increDecreStock(data, incre) {
      if (incre) {
        for (const [index, element] of data[ORDER_KEYS.ARTICLES].entries()) {
          if (element.Prefixe_tarif) {
            await cancelOrder(
              this.user._id,
              this.user.proprietaire,
              element._id,
              element.Ref_fournisseur,
              element.Code_marque,
              element.Code_EAN,
              0,
              0,
              20,
              "",
              this.user.username,
              "BC n°" +
                data[ORDER_KEYS.BC_ID] +
                " annulée par " +
                this.user.username,
              element.Prefixe_tarif,
              data[ORDER_KEYS.CLIENT_NAME],
              data[ORDER_KEYS.BC_ID],
              "BC",
              "préparation de commande",
              element.quantity
            );
          }
        }
      } else {
        for (const [index, element] of data[ORDER_KEYS.ARTICLES].entries()) {
          if (element.Prefixe_tarif) {
            await resumeOrder(
              this.user._id,
              this.user.proprietaire,
              element._id,
              element.Ref_fournisseur,
              element.Code_marque,
              element.Code_EAN,
              0,
              0,
              20,
              "",
              this.user.username,
              "BC n°" +
                data[ORDER_KEYS.BC_ID] +
                " reprise par " +
                this.user.username,
              element.Prefixe_tarif,
              this.user.proprietaire,
              data[ORDER_KEYS.BC_ID],
              "BC",
              "préparation de commande",
              element.quantity
            );
          }
        }
      }
    },
    getDetailCmd(object) {
      this.detailCommande = object;
      this.infoInCmd = true;
    },
    reloadPage() {
      window.location.reload();
    },
    setTempCommande(u) {
      this.getCmdPreparation(u);
      this.$router.push("/preparation");
      this.tempNBArticles = parseInt(
        this.cmdPreparation[ORDER_KEYS.ARTICLES].length
      );
    },
    // archiver infosCommandes
    archiverTouteCommande(tab) {
      axios
        .put(`${process.env.VUE_APP_API}/commandesClient/${tab._id}`, {
          [ORDER_KEYS.IS_ARCHIVED]: true,
        })
        .then(() => {
          if (this.tabState == "actif") {
            this.getInfosCommandes(this.currentPage, 20, this.professionState);
          }
          if (this.tabState == "valide") {
            this.getValide();
          }
          this.toast.success("Commande archivée !", {
            position: "bottom-right",
            timeout: 1000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: false,
            closeButton: "button",
            icon: true,
            rtl: false,
          });
        });
    },
    openBC(tab) {
      if (tab[ORDER_KEYS.BC_ID]) {
        axios
          .post(`${process.env.VUE_APP_API}/commandes/getone`, {
            uid: {
              BCNumber: tab[ORDER_KEYS.BC_ID],
              BC_id: tab[ORDER_KEYS.BL_ID],
              plateform: this.user.proprietaire,
            },
          })
          .then((response) => {
            this.createPDFcommande(response.data[0]);
          });
      } else {
        this.toast.warning("Pas de BC pour cette commande.", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      }
    },
    openBL(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab[ORDER_KEYS.BL_ID],
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDF(response.data[0]);
        });
    },
    openBT(tab) {
      axios
        .post(`${process.env.VUE_APP_API}/bdl/getone`, {
          uid: {
            BLNumber: tab[ORDER_KEYS.BL_ID],
            plateform: this.user.proprietaire,
          },
        })
        .then((response) => {
          this.createPDFtransport(response.data[0]);
        });
    },
    createPDFcommande(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet[COMMON_KEYS.CREATED_AT]);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createcommande(objet, false);
      } else {
        createcommande(objet, true);
      }
    },
    createPDF(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet[COMMON_KEYS.CREATED_AT]);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createlivraison(objet, false, false);
      } else {
        createlivraison(objet, true, false);
      }
    },
    createPDFtransport(objet) {
      var today = new Date();
      var da = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      var dateedition = getFrDateSansH(objet[COMMON_KEYS.CREATED_AT]);
      var datepdf = da + "/" + mm + "/" + yyyy;
      if (dateedition == datepdf) {
        createtransport(objet, false);
      } else {
        createtransport(objet, true);
      }
    },
    pageSwitch() {
      this.pageTab = new Array(10);
      this.pageTab = Array.from(this.pageTab);
      var i = 1;
      this.pageTab.forEach((element, index) => {
        this.pageTab[index] = { pageNumber: i };
        i = i + 1;
      });
    },
    search(skipValue, limitValue, archive, searchTerm) {
      this.searchCatchError = false;
      this.professionState = null;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getInfosCommandes(skipValue, limitValue, this.professionState);
        this.pageSwitch();
      } else {
        if (archive == false) {
          this.tabState = "actif";
        } else {
          this.tabState = "archived";
        }
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandesClient/searchInPlateform`,
            {
              [COMMON_KEYS.PLATFORM]: this.user.proprietaire,
              [ORDER_KEYS.IS_ARCHIVED]: archive,
              order_type_list: this.order_type_list,
              order_source_list: this.order_source_list,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 20,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.infosCommandes = response.data.results;
            this.searchLength = response.data.count;
            this.setSearchFilters({
              keyword: this.searchTerm,
              filter: null,
              archive: this.tabState,
              url: "/commandePro",
              pagination: this.currentPage,
            });
          })
          .catch((error) => {
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    searchByStatus(skipValue, limitValue, tabState, currentStatus, searchTerm) {
      this.searchCatchError = false;
      if (searchTerm == "") {
        this.searchActive = false;
        this.getByStatus(
          skipValue,
          limitValue,
          tabState,
          currentStatus,
          this.professionState
        );
        this.pageSwitch();
      } else {
        // if (archive == false) {
        //   this.tabState = "actif";
        // } else {
        //   this.tabState = "archived";
        // }
        this.searchActive = true;
        this.searchInProgress = true;
        this.searchTerm = searchTerm;
        this.currentPage = skipValue;
        axios
          .post(
            `${process.env.VUE_APP_API}/commandesClient/searchInPlateformByStatus`,
            {
              [COMMON_KEYS.PLATFORM]: this.user.proprietaire,
              [ORDER_KEYS.STATUS]: currentStatus,
              order_type_list: this.order_type_list,
              order_source_list: this.order_source_list,
              searchTerm: searchTerm,
              skip: (skipValue - 1) * 20,
              limit: limitValue,
            }
          )
          .then((response) => {
            this.searchInProgress = false;
            this.infosCommandes = response.data.results;
            this.searchLength = response.data.count;
          })
          .catch((error) => {
            this.searchInProgress = false;
            this.searchCatchError = true;
          });
      }
    },
    translateStatus(status) {
      return this.statusTranslations[status] || "Statut inconnu";
    },
  },
  mounted() {
    if (this.searchFilters.url === "/commandePro") {
      this.searchTerm = this.searchFilters.keyword;
      this.currentPage = this.searchFilters.pagination;
      this.professionState = this.searchFilters.filter;
      this.tabState = this.searchFilters.archive;
    }

    if (this.searchTerm !== "") {
      if (this.tabState === "actif") {
        this.search(this.currentPage, 20, false, this.searchTerm);
      } else {
        this.search(this.currentPage, 20, true, this.searchTerm);
      }
    } else if (this.tabState === "actif") {
      this.getInfosCommandes(this.currentPage, 20, this.professionState);
    } else if (this.tabState === "archived") {
      this.getArchived(this.currentPage, 20, this.professionState);
    } else if (
      [
        "preparation",
        "commandée",
        "prete",
        "valide",
        "attente",
        "annule",
      ].includes(this.tabState)
    ) {
      this.getByStatus(
        this.currentPage,
        20,
        this.tabState,
        this.tabState == "preparation"
          ? "IN_PREPARATION"
          : this.tabState == "commandée"
          ? "SUBMITTED_SUPPLIER_ORDER"
          : this.tabState == "prete"
          ? "PREPARED"
          : this.tabState == "valide"
          ? "COMPLETED"
          : this.tabState == "attente"
          ? "WAITING"
          : "CANCELLED",
        this.professionState
      );
    }

    this.pageSwitch();
  },
};
</script>
